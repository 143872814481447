import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pteadmin',
  templateUrl: './pteadmin.component.html',
  styleUrls: ['./pteadmin.component.scss']
})
export class PTEAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
