import { Component, Input, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { MessagingService } from 'src/app/Services/messagingService/messaging.service';
@Component({
  selector: 'app-whatsappqr',
  templateUrl: './whatsappqr.component.html',
  styleUrls: ['./whatsappqr.component.scss']
})
export class WhatsappqrComponent implements OnInit {
  title = 'angular10qrcodegeneration';
  
  // We can have Canvas/Img/Url as elementType
  elementType = NgxQrcodeElementTypes.URL;
  
  @Input() qrCodeToGenerate: string;
  // We can have High/Low/Medium/Quartile
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  
  // Need to specify the valid website address
  value:any ="";

  constructor(private wApi : MessagingService) { }

  ngOnInit(): void {
    // this.wApi.getQr().subscribe(
    //   data => {
    //     console.log(data)
    //     this.value = data.qr;
    //   }
    // )
  }

  // getNewQr(){
  //   this.wApi.resetQr().subscribe(
  //     data =>{
  //       this.wApi.getQr().subscribe(
  //         data => {
  //           console.log(data)
  //           this.value = data.qr;
  //         }
  //       )
  //     }
  //   )
  // }
}
