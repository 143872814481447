<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<div class="container-fluid px-4 pt-4 rounded">
    <div class="sticky-top">
        <div class="card shadow" style="border-radius: 10px;">
            <div class="card-header border-0">
                <div class="row">
                    <div class="col">
                        <h5 class="d-inline-block align-middle my-0 mr-3 ">
                            Accounts
                        </h5>
                    </div>

                    <div class="col-auto">
                        <div class="form-row">
                            <div class="p-auto">
                                <!-- <div class="btn-group btn-group-sm btn-group-sm" role="group">
                                    <div class="input-group input-group-sm btnFilter">
                                        <input type="search" class="form-control input-sm pr-4" placeholder="Search..."
                                            maxlength="50" autocomplete="no" name="First" [(ngModel)]="First"
                                            (ngModelChange)="Search()">
                                    </div>
                                </div> -->
                                <button class="btn btn-sm btn-outline-primary btnFilter" type="button"
                                    data-toggle="modal" data-target="#launchAccountModal" data-backdrop="static"
                                    data-keyboard="false">
                                    <i class="fas fa-plus mr-2"></i>Account
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px;">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Account ID</th>
                    <th scope="col">FirmName</th>
                    <th scope="col">Address</th>
                    <th scope="col">Branches</th>
                    <th scope="col">Users</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of accounts.allAccounts">
                    <td>{{account.id}}</td>
                    <td>{{account.firmName}}</td>
                    <td>{{account.address}}</td>
                    <td>{{account.branchesAlloted}}</td>
                    <td>{{account.usersAlloted}}</td>
                    <td>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#createBranchModal" data-backdrop="static"
                        data-keyboard="false" (click)="currentAccountId(account.id)">
                            <i class="fas fa-plus mr-2"></i>Branch
                        </button>
                        <button class="btn btn-primary" data-toggle="modal" data-target="#createUserModal" data-backdrop="static"
                        data-keyboard="false" (click)="currentAccountId(account.id)" (click)="currentBranchId(account.branch[0].id)">
                            <i class="fas fa-plus mr-2"></i>User
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
    <!-- User post Modal started -->
    <div class="modal fade" id="launchAccountModal" style="padding-right: 5px; " role="dialog"
        aria-labelledby="launchOfferModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-side modal-top-right" role="navigation">
            <div id="print-section" class="modal-content">
                <div class="sticky-top">
                    <div class="modal-header">
                        <h5>New Account details</h5>
                        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form #submitAccount="ngForm" (submit)="createAccount(submitAccount)">
                            <div class="form-row row">
                                <div class="form-group col-md-5">
                                    <label for="firmName">Firm Name</label>
                                    <input autocomplete="off" type="text" name="firmName"
                                        class="form-control" ngModel required>
                                </div>
                                <div class="form-group col-md-7">
                                    <label for="address">Address</label>
                                    <input autocomplete="off" type="text" name="address"
                                        class="form-control" id="lastName"
                                        ngModel required>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="address">Branches Alloted</label>
                                    <input autocomplete="off" type="number" name="branchesAlloted"
                                        class="form-control" id="lastName"
                                        ngModel required>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="address">Users Alloted</label>
                                    <input autocomplete="off" type="number" name="usersAlloted"
                                        class="form-control" id="lastName"
                                        ngModel required>
                                </div>
                                
                            </div>
                            <div class="form-row">
                                <button class="btn btn-primary ml-auto mr-auto" type="submit">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</html>

<!-- Create Branch Modal started -->
<div class="modal fade" id="createBranchModal" style="padding-right: 5px; " role="dialog"
aria-labelledby="launchOfferModalTitle" aria-hidden="true">
<div class="modal-dialog modal-xl modal-side modal-top-right" role="navigation">
    <div id="print-section" class="modal-content">
        <div class="sticky-top">
            <div class="modal-header">
                <h5>Create admin branch</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #submitBranch="ngForm" (submit)="createBranch(submitBranch)">
                    <div class="form-row row">
                      <div class="form-group col-md-6">
                        <label for="branchName">Branch Name</label>
                        <input
                          autocomplete="off"
                          type="text"
                          name="branchName"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="address">Firm Name</label>
                        <input
                          autocomplete="off"
                          type="text"
                          name="firmName"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="form-group col-md-6">
                        <label for="branchName">GST No.</label>
                        <input
                          autocomplete="off"
                          type="text"
                          name="gstNo"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
      
                      <div class="form-group col-md-6">
                        <label for="address">Address</label>
                        <input
                          autocomplete="off"
                          type="text"
                          name="address"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="form-group col-md-6">
                        <label for="usersAlloted">Allot Users</label>
                        <input
                          autocomplete="off"
                          type="number"
                          name="usersAlloted"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="usersAlloted">Commission %</label>
                        <input
                          autocomplete="off"
                          type="number"
                          name="commission"
                          class="form-control"
                          ngModel
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <button class="btn btn-primary ml-auto mr-auto" type="submit">
                        Create
                      </button>
                    </div>
                  </form>
            </div>
        </div>
    </div>
</div>
</div>
<!-- User post Modal started -->
<div
  class="modal fade"
  id="createUserModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>New user details</h5>
          <button
            type="button"
            class="close"
            id="closeUserAddModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitUser="ngForm" (submit)="createUser(submitUser)">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="Name">Full Name</label>
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Name"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="abc@gmail.com"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="password">Password</label>
                <input
                  type="text"
                  name="password"
                  class="form-control"
                  ngModel
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="roleId">Role</label>
                <select
                  class="form-control"
                  name="roleId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option selected>
                    --- NoRoleAssigned ---
                  </option>
                  <option *ngFor="let role of roles" [value]="role?.id">
                    --- {{ role?.roleName }} ---
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="mariStatus">Branch</label>
                <select
                  class="form-control"
                  name="branchId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option
                    *ngFor="let branch of branches"
                    [value]="branch?.id"
                    selected="selected"
                  >
                    --- {{ branch?.branchName }} ---
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary ml-auto mr-auto" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- User post Modal end -->
