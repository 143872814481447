<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<div class="container-fluid px-4 pt-4 rounded">
    <div class="row">
        <div class="col-6 mx-auto">
            <div class="card infoCard shadow" style="border-radius: 10px;">
                <div class="card-header">
                    <h5 class="d-inline-block align-middle my-0 mr-3 ">
                        Account Info
                    </h5>
                </div>
                <div class="card-body">
                    <table class="table table-hover">
                        <tbody>
                            <tr>
                                <td>Branches Alloted</td>
                                <td>{{accountInfo?.branchesAlloted}}</td>
                            </tr>
                            <tr>
                                <td>Users Alloted</td>
                                <td>{{accountInfo?.usersAlloted}}</td>
                            </tr>
                            <tr>
                                <td>Firm Name</td>
                                <td>{{accountInfo?.firmName}}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{accountInfo?.address}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</html>