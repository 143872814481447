<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container-fluid px-4 pt-4 rounded">
      <div class="sticky-top">
        <div class="card shadow">
          <div class="card-header border-0">
            <div class="row">
              <div class="col-auto mx-auto">
                <h4>{{ collegeName }}</h4>
              </div>
              <div class="col-auto mx-auto">
                <button
                  class="btn btn-outline-primary btn-sm"
                  type="button"
                  data-toggle="modal"
                  data-target="#launchIntakeModal2"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <i class="fas fa-plus mr-2"></i>Add New Intake
                </button>
              </div>
              <div class="col-auto mx-auto">
                <button
                  class="btn btn-outline-primary btn-sm"
                  type="button"
                  data-toggle="modal"
                  data-target="#launchOfferModal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <i class="fas fa-plus mr-2"></i>Add Program
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin: 0px">
      <div class="card shadow mx-auto clgCard" *ngFor="let college of Colleges">
        <div class="card-header">
          <div class="row">
            <div class="col-auto mx-auto">
              <img src="../../../../../assets/img/pp.jpg" class="clgImage" />
            </div>
          </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <h5 class="colgName">{{ college.college.name }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <p>{{ college.courseName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <p class="details">
                Offer Fees: <br />
                Level Of Education: <br />
                Duration: <br />
              </p>
            </div>
            <div class="col-1"></div>
            <div class="col-4">
              <p class="details2">
                {{ college.offerFee | currency : "USD" }} <br />
                {{ college.levelOfEducationId }} <br />
                {{ college.courseDuration }}<br />
                </p>
            </div>
          </div>

          <!-- <div class="row">
            <p class="mx-auto">Intakes - 2023</p>
            <div class="mx-auto">
              <p class="intakesP">
                <span [ngClass]="{ intakeB: college.Intakes.January }"
                  >JANUARY</span
                >
                |
                <span [ngClass]="{ intakeB: college.Intakes.May }">MAY</span> |
                <span [ngClass]="{ intakeB: college.Intakes.September }"
                  >SEPTEMBER</span
                >
              </p>
            </div>
          </div> -->
          <div class="row">
            <div class="col-auto mx-auto">
              <button
                class="btn btn-outline-primary btn-sm cardBtn"
                type="button"
                data-toggle="modal"
                data-target="#launchIntakeModal"
                data-backdrop="static"
                data-keyboard="false"
                (click)="getIntakesOfCourse(college.id)"
              >
                Open For Intake
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader2" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
    <div
      class="modal fade"
      id="launchIntakeModal"
      style="padding-right: 5px"
      role="dialog"
      aria-labelledby="launchOfferModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="navigation">
        <div id="print-section" class="modal-content">
          <div class="sticky-top">
            <div class="modal-header">
              <h5>Intakes Available</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-row">
                <div
                  class="form-group form-check mx-auto"
                  *ngFor="let intake of intakes"
                >
                  <input
                    type="checkbox"
                    (change)="
                      clickedCheckBox(intake.id, intake.courseToCourseIntakeId)
                    "
                    class="form-check-input"
                    [name]="intake.id"
                    [checked]="intake.ifActive == 'true'"
                  />
                  <label class="form-check-label"
                    >{{ intake.intakeMonth }}-{{ intake.intakeYear }}</label
                  >
                </div>
              </div>
              <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoaderMain" class="spinner-border positionSpinner"></div> -->
    <div
      class="modal fade"
      id="launchOfferModal"
      style="padding-right: 5px"
      role="dialog"
      aria-labelledby="launchOfferModalTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl modal-side modal-top-right"
        role="navigation"
      >
        <div id="print-section" class="modal-content">
          <div class="sticky-top">
            <div class="modal-header">
              <h5>Course Details</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #programForm="ngForm" (submit)="createProgram(programForm)">
                <div class="form-row">
                  <div class="form-group col-md-6 mx-auto">
                    <label for="Name">College Name</label>
                    <select name="cName" ngModel class="form-control">
                      <option value="default" selected disabled>
                        Select Type
                      </option>
                      <option value="1">College</option>
                      <option value="2">University</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6 mx-auto">
                    <label for="email">Course Name</label>
                    <input
                      type="text"
                      name="coName"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6 mx-auto">
                    <label for="password">Course Duration</label>
                    <input
                      type="text"
                      name="description"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                  <div class="form-group col-md-6 mx-auto">
                    <label for="password">Stream</label>
                    <input
                      type="text"
                      name="stream"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6 mx-auto">
                    <label for="Name">Level Of Education</label>
                    <input
                      autocomplete="off"
                      type="text"
                      name="lOEd"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                  <div class="form-group col-md-6 mx-auto">
                    <label for="Name">Offer Fees</label>
                    <input
                      autocomplete="off"
                      type="text"
                      name="fees"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <button
                    class="btn btn-primary ml-auto mr-auto"
                    type="submit"
                    [disabled]="!viewLoader"
                  >
                    Create
                  </button>
                </div>
              </form>
              <!-- <div
                [hidden]="viewLoader"
                class="spinner-border positionSpinner"
              ></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="launchIntakeModal2"
      style="padding-right: 5px"
      role="dialog"
      aria-labelledby="launchOfferModalTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl modal-side modal-top-right"
        role="navigation"
      >
        <div id="print-section" class="modal-content">
          <div class="sticky-top">
            <div class="modal-header">
              <h5>Intake Details</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #intakeForm="ngForm" (submit)="createIntake(intakeForm)">
                <div class="form-row">
                  <div class="form-group col-md-6 mx-auto">
                    <label for="Month">Month</label>
                    <input
                      type="text"
                      name="intakeMonth"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                  <div class="form-group col-md-6 mx-auto">
                    <label for="email">Year</label>
                    <input
                      type="text"
                      name="intakeYear"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6 mx-auto">
                    <label for="Month">Status</label>
                    <input
                      type="text"
                      name="status"
                      class="form-control"
                      ngModel
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <button
                    class="btn btn-primary ml-auto mr-auto"
                    type="submit"
                    [disabled]="!viewLoader"
                  >
                    Create
                  </button>
                </div>
              </form>
              <!-- <div
                [hidden]="viewLoader"
                class="spinner-border positionSpinner"
              ></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>