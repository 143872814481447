import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/Services/adminService/admin.service';

@Component({
  selector: 'app-permission-for-role',
  templateUrl: './permission-for-role.component.html',
  styleUrls: ['./permission-for-role.component.scss']
})
export class PermissionForRoleComponent implements OnInit {

permissions:any;
constructor(private _adminService : AdminService) { }

ngOnInit(): void {
   this.getPermission()
 }

ngOnChanges(): void{
   
 }

createPermission(form : NgForm){
  this._adminService.createPermission({permission: form.value.permission}).subscribe(
    (data)=>{
      console.log(data)
      document.getElementById("permissionClose").click();
      this.getPermission()
    }
  )
 }

getPermission(){
  this._adminService.getPermission().subscribe(
    (data )=>{
      this.permissions = data
      console.log(data)
    }
  )
 }
}
