import { Component, OnInit, ViewChild } from "@angular/core";
import { Form, NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { IGetServices } from "src/app/Interface/IGetService";
import { IService } from "src/app/Interface/IService";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  services: any;
  showHide: boolean = true;
  refSName = "";
  RefsCharge = "";
  leadId = 0;
  milestoneValue: string = "";
  milestonesAdded = [];

  serviceName: any;
  serviceCharges: any;
  sortedList: any[];

  constructor(
    private _crmLeadService: CRMLeadServiceService,
    private _adminService: AdminService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getServices();
  }

  array = [10, 20, 30, 40];

  getServices() {
    this._adminService.getAllServicesWithMilestones().subscribe(
      (responseStatus) => {
        this.services = responseStatus;
        console.log(this.services);
      },
      (responseError) => {
        confirm(
          "Some error occured please Refresh or connect to Administrator. Thanks"
        );
      },
      () => {
        console.log(this.services);
      }
    );
  }

  edit(service: IService, i: number) {
    document.getElementById("addService").click();
  }

  submitform() {
    let obj = [];
    for (let i = 0; i < this.milestonesAdded.length; i++) {
      obj.push({
        milestoneSequenceNumber: i + 1,
        milestoneName: this.milestonesAdded[i],
      });
    }

    if (this.milestoneValue != "") {
      this.toastService.error("Please either save the milestone written in Service Milestone Field or empty it.");
    } else {
      this._adminService
        .createServiceWithMilestones({
          serviceName: this.serviceName,
          serviceCharges: this.serviceCharges,
          serviceMilestones: obj,
        })
        .subscribe(
          (data) => {
            this.milestonesAdded = [];
            this.milestoneValue = "";
            document.getElementById("serviceModalClose").click();
            this.toastService.success("Service created successfully");
            this.getServices();
          },
          (err) => {
            console.log("error occured");
          },
          () => {
            console.log("Service adding fuction executed");
          }
        );
    }
  }

  addMilestoneToServiceArray() {
    this.milestonesAdded.push(this.milestoneValue);
    this.milestoneValue = "";
    console.log(this.milestonesAdded);
  }

  changedMilestone(event, id) {
    console.log(event + " " + id);
    this.milestonesAdded[id] = event;
  }

  emptyServiceForm() {
    this.milestoneValue = "";
    this.milestonesAdded = [];
    this.serviceCharges = "";
    this.serviceName = "";
  }

  selected = null


  dragOver(e) {
    if (this.isBefore(this.selected, e.target)) {
      e.target.parentNode.insertBefore(this.selected, e.target)
    } else {
      e.target.parentNode.insertBefore(this.selected, e.target.nextSibling)
    }
  }
  
  dragEnd(e) {
    this.sortedList = Array.from(e.target.parentNode.children).map((element: HTMLElement) => {
      return element.innerHTML;
    });;
    console.log(this.sortedList)
    this.selected = null
  }

  dragStart(e) {
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/plain', null)
    this.selected = e.target
  }

  isBefore(el1, el2) {
    let cur
    if (el2.parentNode === el1.parentNode) {
      for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
        if (cur === el2) return true
      }
    }
    return false;
  }
}
