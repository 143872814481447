import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree-component',
  templateUrl: './tree-component.component.html',
  styleUrls: ['./tree-component.component.scss']
})
export class TreeComponentComponent implements OnInit {
  
  @Input() node: any;
  @Output() nodeClicked = new EventEmitter<any>();
  @Output() eyeBranchClicked = new EventEmitter<any>();
  
  selectedEye = "Node 1";
  
  constructor() { }

  ngOnInit(): void {
  }

  toggleCollapse(event: Event): void {
    event.stopPropagation();
    this.node.expanded = !this.node.expanded;
    this.nodeClicked.emit(this.node);
  }

  handleNodeClicked(clickedNode: any): void {
    if (this.node !== clickedNode && this.node.expanded) {
      // Collapse only if the clicked node is not the current node and the current node is expanded
      this.node.expanded = false;
    }
  }


  printBranchName(branchId){
    this.selectedEye = branchId
    this.eyeBranchClicked.emit(branchId);
  }
}
