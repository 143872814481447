import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Chart } from "chart.js";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import * as XLSX from "xlsx";
@Component({
  selector: "app-generate-reports",
  templateUrl: "./generate-reports.component.html",
  styleUrls: ["./generate-reports.component.scss"],
})
export class GenerateReportsComponent implements OnInit, OnChanges {
  chart: any;
  progressRegistered: number = 0; // Input property for progress percentage
  progressOpen: number = 0; // Input property for progress percentage
  progressClose: number = 0; // Input property for progress percentage
  progressRejected: number = 100; // Input property for progress percentage
  services: any;
  branches: any;
  leads: any;
  branchId = localStorage.getItem("branchId");
  registeredCount = 0;
  openCount: any = 0;
  closedCount: any = 0;
  dataRegistered: any = [];
  dataOpen: any = [];
  dataClose: any = [];
  graphLeads: any = [];
  graph: any;
  canViewAllBranch = localStorage.getItem("canViewAllBranch");
  quesNeedMilestones: boolean = false;
  viewMileStone: any = false;
  milestonesOfService: any;
  today = new Date();
  currentYear = this.today.toLocaleString("default", { year: "2-digit" });

  get strokeDashArrayRegistered(): string {
    const circumference = 2 * Math.PI * 60;
    const visibleLength = (circumference * this.progressRegistered) / 100;
    return `${visibleLength} ${circumference}`;
  }

  get strokeDashArrayOpen(): string {
    const circumference = 2 * Math.PI * 60;
    const visibleLength = (circumference * this.progressOpen) / 100;
    return `${visibleLength} ${circumference}`;
  }

  get strokeDashArrayClose(): string {
    const circumference = 2 * Math.PI * 60;
    const visibleLength = (circumference * this.progressClose) / 100;
    return `${visibleLength} ${circumference}`;
  }

  get strokeDashArrayRejected(): string {
    const circumference = 2 * Math.PI * 60;
    const visibleLength = (circumference * this.progressRejected) / 100;
    return `${visibleLength} ${circumference}`;
  }
  ngOnChanges(changes: SimpleChanges): void {}
  constructor(
    private _adminService: AdminService,
    private _crmLeadService: CRMLeadServiceService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getBranches();
    this.getServices();
    this.getLeads(this.branchId);
    this.graph = new Chart("myChart", {
      type: "line",
      data: {
        labels: [
          "Jan 24",
          "Feb 24",
          "Mar 24",
          "Apr 24",
          "May 24",
          "Jun 24",
          "Jul 24",
          "Aug 24",
          "Sep 24",
          "Oct 24",
          "Nov 24",
          "Dec 24",
        ],
        datasets: [
          {
            label: "Registered",
            data: this.dataRegistered,
            backgroundColor: ["transparent"],
            borderColor: ["#3A6394"],
            borderWidth: 3,
          },
          {
            label: "Open",
            data: this.dataOpen,
            backgroundColor: ["transparent"],
            borderColor: ["#00AAC1"],
            borderWidth: 3,
          },
          {
            label: "Close",
            data: this.dataClose,
            backgroundColor: ["transparent"],
            borderColor: ["#ADFD02"],
            borderWidth: 3,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  getLabelsForCalendar(year) {
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let returnMonth = [];
    month.forEach((mon) => {
      returnMonth.push(mon + " " + year);
    });
    console.log(returnMonth);
    this.graph.data.labels = returnMonth;
    this.graph.update();
    this.currentYear = year
    this.getGraphValues(this.leads);
  }

  getServices() {
    this._adminService.getServicesWithMilestones().subscribe(
      (responseStatus) => {
        this.services = responseStatus;
        console.log(this.services);
        ////console.log(this.services)
      },
      (responseError) => {
        alert(
          "Some error occured please Refresh or connect to Administrator. Thanks"
        );
      },
      () => {
        //console.log(this.services);
      }
    );
  }

  getBranches() {
    this._crmLeadService
      .getBranches(localStorage.getItem("accountId"))
      .subscribe(
        (data) => {
          this.branches = data.branch;
        },
        (err) => {
          console.log(err);
        },
        () => console.log("Brnaches fetches successfully")
      );
  }

  getLeads(branchId) {
    this._crmLeadService
      .getLeadsForAdminOnBranch(branchId)
      .subscribe((data) => {
        this.leads = data.lead[0];
        this.graphLeads = data.lead[0];
        //console.log(this.leads);
        this.applyStatusFilters();
        this.getGraphValues(this.leads);
      });
  }

  applyFilters(filtersForm) {
    debugger;
    this._crmLeadService
      .getLeadsForAdminOnBranch(filtersForm.value.branchId)
      .subscribe((data) => {
        this.leads = data.lead[0];
        if (filtersForm.value.serviceName != "all") {
          this.leads = this.leads.filter((lead) => {
            return (
              lead.leadToService[0].Service.serviceName ==
              filtersForm.value.serviceName
            );
          });
        }

        if (this.viewMileStone) {
          this.leads = this.leads.filter((lead) => {
            return (
              lead.leadToService[0].milestoneSequenceNumber ==
              filtersForm.value.serviceMilestone
            );
          });
        }
        this.leads = this.leads.filter((lead) => {
          return (
            lead.createdDate >= filtersForm.value.startDate &&
            lead.createdDate <= filtersForm.value.endDate
          );
        });
        this.applyStatusFilters();
      });
  }

  applyStatusFilters() {
    let totalCount = this.leads.length;
    let tempLeads = this.leads.filter((lead) => {
      return lead.status == "2";
    });
    this.registeredCount = tempLeads.length;
    tempLeads = this.leads.filter((lead) => {
      return lead.status == "1";
    });
    this.openCount = tempLeads.length;
    tempLeads = this.leads.filter((lead) => {
      return lead.status == "3";
    });
    this.closedCount = tempLeads.length;

    this.progressRegistered =
      (this.registeredCount / (totalCount == 0 ? 1 : totalCount)) * 100;
    this.progressOpen =
      (this.openCount / (totalCount == 0 ? 1 : totalCount)) * 100;
    this.progressClose =
      (this.closedCount / (totalCount == 0 ? 1 : totalCount)) * 100;
    this.getGraphValues(this.leads);
  }

  getGraphValues(leads) {
    this.dataClose = [];
    this.dataRegistered = [];
    this.dataOpen = [];
    const years = ["23", "24"];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    monthNames.forEach((month) => {
      let registeredLeads = leads.filter((lead) => {
        const date = new Date(lead.createdDate);
        const monthN = date.toLocaleString("default", { month: "long" });
        const yearN = date.toLocaleString("default", { year: "2-digit" });
        return (
          monthN == month && lead.status == "2" && yearN == this.currentYear
        );
      });
      //console.log(registeredLeads)
      this.dataRegistered.push(registeredLeads.length);
    });
    monthNames.forEach((month) => {
      let registeredLeads = leads.filter((lead) => {
        const date = new Date(lead.createdDate);
        const monthN = date.toLocaleString("default", { month: "long" });
        const yearN = date.toLocaleString("default", { year: "2-digit" });
        return monthN == month && lead.status == "1" && yearN == this.currentYear
      });
      //console.log(registeredLeads)
      this.dataOpen.push(registeredLeads.length);
    });
    monthNames.forEach((month) => {
      let registeredLeads = leads.filter((lead) => {
        const date = new Date(lead.createdDate);
        const monthN = date.toLocaleString("default", { month: "long" });
        const yearN = date.toLocaleString("default", { year: "2-digit" });
        return monthN == month && lead.status == "3" && yearN == this.currentYear
      });
      //console.log(registeredLeads)
      this.dataClose.push(registeredLeads.length);
    });

    //console.log(this.dataRegistered)
    //console.log(this.dataClose)
    //console.log(this.dataOpen)

    this.graph.data.datasets[0].data = this.dataRegistered;
    this.graph.data.datasets[1].data = this.dataOpen;
    this.graph.data.datasets[2].data = this.dataClose;
    this.graph.update();
  }

  exportExcel() {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    let leadData = [];
    this.leads.forEach((lead) => {
      leadData.push({
        leadId: lead?.id,
        prefix: lead?.prefix,
        emailId: lead?.emailId,
        firstName: lead?.firstName,
        lastName: lead?.lastName,
        dob: lead?.dob,
        notes: lead?.notes,
        status:
          lead?.status == "1"
            ? "Open"
            : lead?.status == "2"
            ? "Registered"
            : "Closed",
        marriageStatus: lead?.marriageStatus,
        phoneNumber: lead?.phoneNumber,
        rating: lead?.rating,
        leadAddress:
          lead?.leadAddresses[0]?.addressLine1 +
          ", " +
          lead?.leadAddresses[0]?.city +
          ", " +
          lead?.leadAddresses[0]?.state +
          ", " +
          lead?.leadAddresses[0]?.pinCode,
        leadService: lead.leadToService[0].Service.serviceName,
        leadServiceMilestone:
          lead.leadToService[0].Service.serviceMilestone.find(
            (x) =>
              x.milestoneSequenceNumber ==
              lead.leadToService[0].milestoneSequenceNumber
          ).milestoneName,
      });
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(leadData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, "leads.xlsx");
    this.toastService.success("Report successfully generated");
  }

  serviceChanged(serviceValue) {
    this.quesNeedMilestones = serviceValue != "all";
    if (this.quesNeedMilestones) {
      this.milestonesOfService = this.services.find(
        (x) => x.serviceName == serviceValue
      ).serviceMilestone;
    }
  }

  milestoneSwitch() {
    this.viewMileStone = !this.viewMileStone;
    this.viewMileStone = false;
  }
}
