<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>

  <body>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">
                User roles available
              </h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#addRoleModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Role
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Role</th>
                <th scope="col">Add</th>
              </tr>
            </thead>
            <tbody>
              <tr  data-toggle="modal"
              data-target="#roleToAPIModal"
              data-backdrop="static"
              data-keyboard="false" *ngFor="let role of roles; let i = index" 
              (click)="setRoleForModal(role.id)">
                <td>{{ role.id }}</td>
                <td>{{ role.roleName }}</td>
                <td
                >
                  <i
                    class="fas fa-chevron-circle-right fa-xl ml-3"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- User post Modal end -->
  </body>
</html>
<!-- User post Modal started -->
<div
  class="modal fade"
  id="roleToAPIModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>Role selected: {{ selectedRole }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-header">Apis available</div>
            <div class="card-body">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">ApiName</th>
                    <th scope="col">ApiPath</th>
                    <th scope="col">ApiType</th>
                    <th scope="col">ApiStatus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let api of apis">
                    <td>{{ api.id }}</td>
                    <td>{{ api.apiName }}</td>
                    <td>{{ api.apiPath }}</td>
                    <td>{{ api.apiType }}</td>
                    <td>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCh"
                          [checked]="api.isAddedToRole"
                          (click)="
                            addApiToRole(api.id, api.isAddedToRole)
                          "
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="card-header">Permissions available</div>
            <div class="card-body">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Permission</th>
                    <th scope="col">Perm status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let perm of permissions">
                    <td>{{ perm.id }}</td>
                    <td>{{ perm.permission }}</td>
                    <td>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                          [checked]="perm.isAddedToRole"
                          (change)="
                            addPermissionToRole(perm.id, perm.isAddedToRole)
                          "
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="addRoleModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>Role Details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="createRoleModalClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitUser="ngForm" (submit)="createRole(submitUser)">
            <div class="form-row row">
              <div class="col-1">
                <label for="Name">Role</label>
              </div>
              <div class="col-3">
                <input
                  autocomplete="off"
                  type="text"
                  name="roleName"
                  class="form-control"
                  placeholder="permission"
                  ngModel
                  required
                />
              </div>
              <div class="col-1">
                <label for="Name">Description</label>
              </div>
              <div class="col-4">
                <input
                  autocomplete="off"
                  type="text"
                  name="roleDescription"
                  class="form-control"
                  placeholder="description"
                  ngModel
                  required
                />
              </div>
              <div class="col-1">
                <label class="form-check-label" for="exampleCheck1"
                  >is universal</label
                >
              </div>
              <div class="col-1 px-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  ngModel
                  name="isUniversal"
                />
              </div>
              <div class="col-1">
                <button class="btn btn-primary ml-auto mr-auto" type="submit">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
