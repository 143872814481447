import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {

  controls: any = [];
  controlSelected: string;
  milestonesAdded: any = [];
  milestoneValue = "";


  constructor() { }

  ngOnInit(): void {
  }

  addControl(control){
    this.controlSelected = control
  }

  addControlValues(valuesForm: NgForm){
    let obj: any = {
      controlLabel: valuesForm.value.label,
      controlWidth: 'col-'+valuesForm.value.width,
      controlType: this.controlSelected
    }

    if(this.controlSelected == 'select'){
      obj = {
        controlLabel: valuesForm.value.label,
        controlWidth: 'col-'+valuesForm.value.width,
        controlType: this.controlSelected,
        milestonesAdded: this.milestonesAdded
      }
      this.emptySelectForm()
    }

    this.controls.push(obj)
    console.log(this.controls)
    
    document.getElementById("closeButtonInvoice").click();
    valuesForm.reset();
  }

    addMilestoneToServiceArray() {
    this.milestonesAdded.push(this.milestoneValue);
    this.milestoneValue = "";
    console.log(this.milestonesAdded);
  }

  changedMilestone(event, id) {
    console.log(event + " " + id);
    this.milestonesAdded[id] = event;
  }

  emptySelectForm() {
    this.milestoneValue = "";
    this.milestonesAdded = [];
  }

}
