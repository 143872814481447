import { Component, OnInit } from '@angular/core';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { SocketService } from 'src/app/Services/socketService/socket.service';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from 'src/app/Services/messagingService/messaging.service';

@Component({
  selector: 'app-starter-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  notifications:any;
  notificationCount : number;
  role = localStorage.getItem('role')
  canResetWhatsapp = localStorage.getItem('canResetWhatsapp')
  constructor(private leadApi : CRMLeadServiceService, 
    private socketService:SocketService,
    private toastService:ToastrService,
    private messageSerivce:MessagingService) { }

  ngOnInit() {
    document.getElementById("NeedToClick").click();
    this.socketService.socket.on('connected', (res) =>{
      console.log("From nav compoent"+this.socketService.socket.id)
    })
    
    this.socketService.socket.on('msgNotification',(res)=>{
      this.toastService.info('new notification');
      console.log(res)
    })

    this.getNotification();
  }

  announce() {
    let mission:any; 
    this.leadApi.getNotification().subscribe(
      data => {
        mission = data
        console.log(data)
        this.socketService.announceMission(mission);
      },
      err => console.log(err),
      () => console.log("Notification")
    )
  }

  clearLocalStorage() {
    localStorage.clear();
    location.reload();
    }

    resetWhatsappSession(){
      let con = confirm("Please make sure session is not active in mobile application. Deleted?")
      if(con){
        this.messageSerivce.resetWhatsappSession().subscribe(
          data =>{
            alert(data.message)
          }
        )
      }
    }

    getNotification(){
      this.leadApi.getNotification().subscribe(
        data => {
          this.notifications = data
          this.notificationCount = this.notifications.length
        },
        err => console.log(err),
        () => console.log("Notification")
      )
    }
}
