import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-starter-main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.scss']
})
export class MainSideBarComponent implements OnInit {

  selectedItem : string = '1';
  canViewAdminPanel : string = localStorage.getItem('canViewAdminPanel')
  canViewSuperAdmin : string = localStorage.getItem('canViewSuperAdmin')
  isOsyncs: boolean = false;

  constructor(private router : Router) {
    const currentDomain: string = window.location.href;
    this.isOsyncs = currentDomain.includes('osyncs') || currentDomain.includes('Osyncs')
   }

  role: string;
  ngOnInit() {
    this.role = localStorage.getItem("role")
    let currentURL = this.router.url; 
    if(currentURL == "/user"){
      this.selectedOption('1')
    }
    else if(this.router.url == "/user/courseListLanding"){
      this.selectedOption('2')
      
    }
    else if(this.router.url == "/user/collegeListLanding"){
      this.selectedOption('3')

    }
    else if(this.router.url == "/user/users"){
      this.selectedOption('4')

    }
    else if(this.router.url == "/user/services"){
      this.selectedOption('5')

    }
    else if(this.router.url == "/user/branch"){
      this.selectedOption('6')

    }
    else if(this.router.url == "/user/teams"){
      this.selectedOption('8')
    }
    else if(this.router.url == "/user/dashboard"){
      this.selectedOption('9')
    }
    else if(this.router.url == "/user/downloads"){
      this.selectedOption('10')
    }
    else if(this.router.url == "/user/api"){
      this.selectedOption('11')
    }
    else if(this.router.url == "/user/permission"){
      this.selectedOption('12')
    }
    else if(this.router.url == "/user/taskboard"){
      this.selectedOption('13')
    }
    else if(this.router.url == "/user/accountInfo"){
      this.selectedOption('14')
    }
    else if(this.router.url == "/user/forms"){
      this.selectedOption('16')
    }
    else if(this.router.url == "/user/appointments"){
      this.selectedOption('17')
    }
    else if(this.router.url == "/user/applicationsReceived"){
      this.selectedOption('18')
    }
    else if(this.router.url == "/user/commissionDashboard"){
      this.selectedOption('19')
    }
  }

  user = localStorage.getItem('user');

  selectedOption(id : string){
    this.selectedItem = id;
  }
}
