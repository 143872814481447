import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/Services/adminService/admin.service';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  branches: any;
  roles: any;

  branchesAndRolesToUser: any = [];
  selectedUserId: string;
  constructor(private leadApi : CRMLeadServiceService, private _adminService : AdminService, private toastService: ToastrService) { }

  users : any;
  password:string = "";

  ngOnInit(): void {
    this.getRole();
    this.getUsers(localStorage.getItem('branchId'));
    this.getBranches();
  }

  createUser(form : NgForm){
    let obj={
      branchId:  form.value.branchId,
      userEmail : form.value.email,
      firstName : form.value.name,
      password : form.value.password,
      lastName : "" ,
      roleToAccountUser: [
        {
          roleId : form.value.roleId
        }
      ]
    }
    this.leadApi.createUser(obj, localStorage.getItem('accountId'), localStorage.getItem('branchId')).subscribe(
      data => {
        console.log(data)
        form.reset();
        this.toastService.success('User created');;
        document.getElementById("closeUserAddModal")?.click();
        this.getUsers(form.value.branchId);
      },
      err => console.log(err),
      () => console.log("add user executed successfully")
    )
  }

  getUsers(branchId){
    this.leadApi.getUserByUser(branchId).subscribe(
      data => {
        this.users = data;
        console.log(data)
        this.branchesAndRolesToUser = []
        this.selectedUserId = null
      }
    )
  }

  modalClicked(){
    this.getRole();
  }

  getBranches(){
    this.leadApi.getFlattenSubBranches().subscribe(
      res =>{
        this.branches = res.branch
        console.log(res)
      }
    )
  }

  getRole(){
    this._adminService.getRole().subscribe(
      (data : any)=>{
        this.roles = data;
        console.log(data)
      }
    )
  }

  updateUser(userId, roleId){
    let obj = {
        id: userId,
            roleId : roleId.target.value
    }

    this._adminService.updateUserRole(obj).subscribe(
      data =>{
        this.toastService.success('Role updated successfully');
        this.getUsers(localStorage.getItem('branchId'));   
        console.log("Role update successful")
      },
      err =>{
        this.toastService.error('Some error occured');
      }
    )
  }

  changePassword(userId){
    let obj = {
      id: userId,
      password: this.password
  }

  this._adminService.updateUserCommon(obj).subscribe(
    data =>{
      this.toastService.success('Password changed successfully');
      this.getUsers(localStorage.getItem('branchId'));
      console.log("Password update successfull")
    },
    err =>{
      this.toastService.error('Some error occured');
    }
  )
  }

  getPasswordValue(password){
    this.password = password.target.value
  }

  getUsersOnBranch(branchId){
    this.leadApi.getUserByUser(branchId.target.value).subscribe(
      data => {
        this.users = data;
        console.log(data)
      }
    )
  }

  populateBranchesAssignedAndRoles(branchesAndRolesToUser, userId){
    this.branchesAndRolesToUser = branchesAndRolesToUser;
    this.selectedUserId = userId
  }
}
