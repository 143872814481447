import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CRMLeadServiceService } from '../../../../../Services/leadAPI/crmlead-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-teams-landing',
  templateUrl: './teams-landing.component.html',
  styleUrls: ['./teams-landing.component.scss']
})
export class TeamsLandingComponent implements OnInit {

  constructor(private _router : Router, private leadApi: CRMLeadServiceService, private toastService: ToastrService) { }

  teams : any;
  First:any;

  users : any;
  services : any;

  usersToAdd : string[] = []; 
  selectedTeamId : string = "";
  serviceViewInModal : boolean = false;

  teamUsers:any;
  teamServices:any;
  branches: any;

  ngOnInit(): void {
    this.getTeams();
    //this.getUsers();
    //this.getServices();
    this.getBranches();
  }

  getBranches(){
    this.leadApi.getFlattenSubBranches().subscribe(
      res =>{
        this.branches = res.branch
      }
    )
  }

  Search(){
  }

  createTeam(form:NgForm){
    this.leadApi.postTeam(form.value.teamName, form.value.branchId).subscribe(
      data => {
        form.reset();
        this.toastService.success('Team created');
        document.getElementById('closeModal').click();
        this.getTeams();
      },
      err => {
        this.toastService.error('Some error occured')
      },
      () => {
        console.log("team created successfully")
      }
    )
  }

  getTeams(){
    this.leadApi.getTeams(localStorage.getItem('branchId')).subscribe(
      data => {
        console.log(data);
        this.teams = data;
      }
    )
  }

  // getUsers(){
  //   this.leadApi.getUser().subscribe(
  //     data => {
  //       this.users = data;
  //       console.log(data)
  //     }
  //   )
  // }

  getServices(){
    this.leadApi.getServices().subscribe(
      responseStatus =>{
        this.services = responseStatus
      },
      responseError =>{
        confirm("Some error occured please Refresh or connect to Administrator. Thanks")
      },
      ()=>{
        console.log(this.services)
      }
    )
  }

  getTeamIdForSelectedTeam(teamId : string){
    this.selectedTeamId = teamId;
    this.getAddedAndUnaddedUsersOfTeam(this.selectedTeamId);
    this.getAddableServicesOfTeam(this.selectedTeamId)
  }

  addUserToTeam(id : string, isAddedToRole: boolean){
    let obj;
    if(isAddedToRole){
      obj = {
        "accountUserToBranchId" : id,
        "teamId": this.selectedTeamId
      }
      this.leadApi.deleteUserFromTeam(obj).subscribe(
        data => {
          console.log(data)
          this.toastService.error('User removed from team');
          this.getUsersOfTeam(this.selectedTeamId);
          this.getAddedAndUnaddedUsersOfTeam(this.selectedTeamId)
        }
      )
    }else{
      obj = {
        "accountUserToBranchId" : id,
        "teamId": this.selectedTeamId
      }
      this.leadApi.addUserToTeam(obj).subscribe(
        data => {
          console.log(data)
          this.toastService.success('User added to team');
        this.getUsersOfTeam(this.selectedTeamId);
        this.getAddedAndUnaddedUsersOfTeam(this.selectedTeamId)
        }
      )
    }
  }

  addServiceToTeam(id : string, isAddedToRole: boolean){
    let obj;
    if(isAddedToRole){
      obj = {
        "serviceId":id,
        "teamId":this.selectedTeamId
      }
      this.leadApi.deleteServiceFromTeam(obj).subscribe(
        data => {
          console.log(data)
          this.toastService.error('Service removed from team');
          this.getServicesOfTeam(this.selectedTeamId)
          this.getAddableServicesOfTeam(this.selectedTeamId)

        }
      )
    }else{
      obj = {
        "serviceId":id,
        "teamId":this.selectedTeamId
      }
      this.leadApi.addServiceToTeam(obj).subscribe(
        data => {
          console.log(data)
          this.toastService.success('Service added to team');
          this.getServicesOfTeam(this.selectedTeamId)
          this.getAddableServicesOfTeam(this.selectedTeamId)
        }
      )
    }
    
  }

  getUsersOfTeam(teamId:string){
    this.leadApi.getUsersToTeam(teamId).subscribe(
      res => {
        this.teamUsers = res;
        console.log(res)},
      err=> console.log(err),
      ()=> console.log("Get user executed successfully")
    )
  }

  getServicesOfTeam(teamId:string){
    this.leadApi.getServicesToTeam(teamId).subscribe(
      res => {
        this.teamServices = res;
        console.log(res)},
      err=> console.log(err),
      ()=> console.log("Get user executed successfully")
    )
  }



  getAddedAndUnaddedUsersOfTeam(teamId:string){
    this.leadApi.getAddedAndUnaddedUsersOfTeam(teamId).subscribe(
      (data)=> this.teamUsers = data,
      (err) => console.log(err),
      () => console.log("executed")
    )
  }

  getAddableServicesOfTeam(teamId:string){
    this.leadApi.getAddableServicesOfTeam(teamId).subscribe(
      data => this.teamServices = data
    )
  }

  getTeamsOnBranch(branchId){
    this.leadApi.getTeams(branchId.target.value).subscribe(
      data => {
        console.log(data);
        this.teams = data;
      }
    )
  }
}
