<div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px;">
        <div class="row">
            <div class="col col-md-3">ID: {{id}}</div>
            <div class="col col-md-3">NAME: {{name}}</div>
            <div class="col col-md-3">SERVICE: Study Visa</div>
            <div class="col col-md-3">
                <button class="btn btn-sm btn-outline-primary" type="button" (click)="addService2(89)">
                    <i class="fas fa-plus mr-2"></i>Service
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px;">
        <form #addServi="ngForm" (ngSubmit)="addService(addServi)">
            <div class="form-row">
                <label class="col-4" for="service">Select service</label>
                <select class="col-4" id="service" name="service" ngModel>
                    <option value="aka">aka</option>
                </select>
                <span class="col-1"></span>
                <button type="submit" class="col-1 btn btn-sm btn-outline-primary">Save</button>
                <span class="col-1"></span>
            </div>
        </form>

    </div>
</div>


<div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px;">
        <table class="table table-hover">
            <thead>
                <th scope="col">Sr.No.</th>
                <th scope="col">Services Availed</th>
                <th scope="col">Progress</th>
            </thead>
            <tbody>
                <tr *ngFor="let service of servicesAvailed, let sr = index">
                    <td>{{sr+1}}</td>
                    <td>{{service.serviceName}}</td>
                    <td class="row">
                        start..............
                        <div *ngFor="let a of demo, let id = index">
                            <a [ngClass]="{'acheived': id <= mileStoneId, 'notAcheived': id > mileStoneId}"
                                data-toggle="dropdown">{{a}}..............</a>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="dropdown dropdown-menu dropdown-menu-lg dropdown-menu-right sendEmailForm"
            aria-labelledby="dropdownMenuButton">
            <form #sendEmailForm="ngForm">
                <input type="text" name="email" hidden>
                <label for="Subject">Subject</label>
                <br />
                <input class="sendEmailDialog" type="text" id="Subject" name="subject" ngModel>
                <br />
                <label for="text">Mail</label>
                <br />
                <textarea class="sendEmailDialog" id="text" name="text" ngModel rows="7"></textarea>
                <br />
                <br />
                <button class="btn btn-primary" type="submit">Send <i
                        class="fab fa-telegram-plane fa-xl ml-3"></i></button>
            </form>
        </div>
    </div>
</div>