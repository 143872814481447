<aside
  class="main-sidebar sidebar-dark-primary elevation-4"
  style="position: fixed"
>
  <!-- Brand Logo -->
  <a class="brand-link">
    <img
      *ngIf="!isOsyncs"
      src="../../../../assets/img/Landing/aplifast.png"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <img
      *ngIf="isOsyncs"
      src="../../../../assets/img/OLogo.png"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <span *ngIf="!isOsyncs" class="brand-text font-weight-light"
      >Welcome To Aplifast</span
    >
    <span *ngIf="isOsyncs" class="brand-text font-weight-light"
      >Welcome To Osyncs</span
    >
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="assets/img/user2-160x160.jpg"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="info">
        <a class="d-block">{{ user }}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview menu-open">
          <ul class="nav nav-treeview">
            <li class="nav-item has-treeview menu-open"></li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '9',
                'nav-item': selectedItem != '9'
              }"
              (click)="selectedOption('9')"
            >
              <a routerLink="/user/dashboard" class="nav-link">
                <i class="fa-solid fa-chart-line nav-icon"></i>
                <p>Dashboard</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '1',
                'nav-item': selectedItem != '1'
              }"
              (click)="selectedOption('1')"
            >
              <a routerLink="/user" class="nav-link">
                <i class="fas fa-user nav-icon"></i>
                <p>Leads</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '18',
                'nav-item': selectedItem != '18'
              }"
              (click)="selectedOption('18')"
            >
              <a routerLink="/user/applicationsReceived" class="nav-link">
                <i class="fa-solid fa-tag nav-icon"></i>
                <p>Applications</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '2',
                'nav-item': selectedItem != '2'
              }"
              (click)="selectedOption('2')"
            >
              <a routerLink="/user/courseListLanding" class="nav-link">
                <i class="fas fa-graduation-cap nav-icon"></i>
                <p>Find Programs</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '3',
                'nav-item': selectedItem != '3'
              }"
              (click)="selectedOption('3')"
            >
              <a routerLink="/user/collegeListLanding" class="nav-link">
                <i class="fas fa-university nav-icon"></i>
                <p>Find Institutes</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '13',
                'nav-item': selectedItem != '13'
              }"
              (click)="selectedOption('13')"
            >
              <a routerLink="/user/taskboard" class="nav-link">
                <i class="fas fa-tasks nav-icon"></i>
                <p>Task Board</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '17',
                'nav-item': selectedItem != '17'
              }"
              (click)="selectedOption('17')"
            >
              <a routerLink="/user/appointments" class="nav-link">
                <i class="fa-solid fa-calendar-days nav-icon"></i>
                <p>Appointments</p>
              </a>
            </li>
            

            <li class="nav-item">
              <a routerLink="/user/email" class="nav-link">
                <i class="far fa-envelope nav-icon"></i>
                <p>Email</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="/user/courses" class="nav-link">
                <i class="far fa-envelope nav-icon"></i>
                <p>Courses</p>
              </a>
            </li> -->
            
            
            
            <!--  <li [ngClass]="{ 'nav-item selected': selectedItem == '10', 'nav-item' : selectedItem != '10'}" (click)="selectedOption('10')">
              <a routerLink="/user/downloads" class="nav-link">
                <i class="fas fa-cloud-download-alt nav-icon"></i>
                <p>Downloads</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li
          class="nav-item has-treeview menu-open"
          *ngIf="canViewAdminPanel == 'true'"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-user-secret"></i>
            <p>
              Admin Center
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '19',
                'nav-item': selectedItem != '19'
              }"
              (click)="selectedOption('19')"
            >
              <a routerLink="/user/commissionDashboard" class="nav-link">
                <i class="fa-solid fa-money-check-dollar  nav-icon"></i>
                <p>Commissions</p>
              </a>
            </li>
            
            
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '4',
                'nav-item': selectedItem != '4'
              }"
              (click)="selectedOption('4')"
            >
              <a routerLink="/user/users" class="nav-link">
                <i class="fa fa-users nav-icon"></i>
                <p>Users</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '5',
                'nav-item': selectedItem != '5'
              }"
              (click)="selectedOption('5')"
            >
              <a routerLink="/user/services" class="nav-link">
                <i class="fas fa-clipboard-list nav-icon"></i>
                <p>Services</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '6',
                'nav-item': selectedItem != '6'
              }"
              (click)="selectedOption('6')"
            >
              <a routerLink="/user/branch" class="nav-link">
                <i class="fas fa-monument nav-icon"></i>
                <p>Branches</p>
              </a>
            </li>

            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '8',
                'nav-item': selectedItem != '8'
              }"
              (click)="selectedOption('8')"
            >
              <a routerLink="/user/teams" class="nav-link">
                <i class="fas fa-lock-open nav-icon"></i>
                <p>Teams</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '14',
                'nav-item': selectedItem != '14'
              }"
              (click)="selectedOption('14')"
            >
              <a routerLink="/user/accountInfo" class="nav-link">
                <i class="fas fa-id-card nav-icon"></i>
                <p>Account Info</p>
              </a>
            </li>
            <!-- <li [ngClass]="{ 'nav-item selected': selectedItem == '8', 'nav-item' : selectedItem != '8'}" (click)="selectedOption('8')">
              <a routerLink="/user/pteadmin" class="nav-link">
                <i class="fas fa-lock-open nav-icon"></i>
                <p>PTE Admin</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li
          class="nav-item has-treeview menu-open"
          *ngIf="canViewSuperAdmin == 'true'"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-user-secret"></i>
            <p>
              Super Admin Center
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '9',
                'nav-item': selectedItem != '9'
              }"
              (click)="selectedOption('9')"
            >
              <a routerLink="/user/role" class="nav-link">
                <i class="fas fa-skull-crossbones nav-icon"></i>
                <p>Role</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '11',
                'nav-item': selectedItem != '11'
              }"
              (click)="selectedOption('11')"
            >
              <a routerLink="/user/api" class="nav-link">
                <i class="fas fa-skull-crossbones nav-icon"></i>
                <p>API</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '12',
                'nav-item': selectedItem != '12'
              }"
              (click)="selectedOption('12')"
            >
              <a routerLink="/user/permission" class="nav-link">
                <i class="fas fa-skull-crossbones nav-icon"></i>
                <p>Permissions</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '7',
                'nav-item': selectedItem != '7'
              }"
              (click)="selectedOption('7')"
            >
              <a routerLink="/user/accounts" class="nav-link">
                <i class="far fa-envelope nav-icon"></i>
                <p>Accounts</p>
              </a>
            </li>
            <li
              [ngClass]="{
                'nav-item selected': selectedItem == '16',
                'nav-item': selectedItem != '16'
              }"
              (click)="selectedOption('16')"
            >
              <a routerLink="/user/forms" class="nav-link">
                <i class="fas fa-skull-crossbones nav-icon"></i>
                <p>Forms</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</aside>
