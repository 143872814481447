import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/Services/adminService/admin.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  // Indicator to get all the roles availble i.e. access levels
  roles:any;
  selectedRole: string;
  roleId:number;
  apis:any;
  permissions:any;
  constructor(private _adminService : AdminService) { }

  ngOnInit(): void {
    this.getRole();
  }

  getRole(){
    this._adminService.getRole().subscribe(
      (data : any)=>{
        this.roles = data;
        console.log(data)
      }
    )
  }

  setRoleForModal(i :number){
    this.roleId = i;
    this.selectedRole = this.roles.find(x => x.id == i).roleName;
    this.getApisWithRole();
    this.getPermissionsWithRole();
  }

  getApisWithRole(){
    this._adminService.getApisWithRole(this.roleId).subscribe(
      (data : any)=>{
        this.apis = data;
        console.log(data)
      }
    )
  }

  getPermissionsWithRole(){
    this._adminService.getPermissionsWithRole(this.roleId).subscribe(
      (data)=>{
        this.permissions = data
        console.log(data)
      }
    )
  }

  createRole(form:NgForm){
    this._adminService.createRole(form.value).subscribe(
      (data )=>{
        document.getElementById("createRoleModalClose").click();
        this.getRole();
      },
      (err)=>{
        alert("some error")
      }
    )
  }

  addApiToRole(id:number, isAddedToRole : boolean){
    let obj =[];
    if(isAddedToRole){
      obj.push(
      {
        apiId:id,
        roleId: this.roleId,
        isAddedToRole: !isAddedToRole,
        isSoftDelete : true
      })
    } else{
      obj.push(
        {
          apiId:id,
          roleId: this.roleId,
          isAddedToRole: !isAddedToRole
        })
    }
    this._adminService.addApiToRole(obj).subscribe(
      (data : any) => {
        console.log(data)
        this.getApisWithRole();
      }
    )
  }

  addPermissionToRole(id:number, isAddedToRole : boolean){
    let obj =[];
    if(isAddedToRole){
      let obj ={
        roleId: this.roleId,
        permissionId: id,
        isAddedToRole: !isAddedToRole,
        isSoftDelete : true
      }
      this._adminService.deletePermissionToRole(obj, this.roleId).subscribe(
        (data : any) => {
          console.log(data)
          this.getPermissionsWithRole()
        }
        )
    } else{
      obj.push(
        {
          roleId: this.roleId,
          permissionId: id,
          isAddedToRole: !isAddedToRole
        })
        this._adminService.addPermissionToRole(obj).subscribe(
      (data : any) => {
        console.log(data)
        this.getPermissionsWithRole()
      }
      )
    }
  }
}
