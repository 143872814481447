<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>

  <body>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">
                Permissions
              </h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#permForRoleModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Permission
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Permission</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let perm of permissions">
                    <td>{{ perm.id }}</td>
                    <td>{{ perm.permission }}</td>
                    <!-- <td>{{ api.apiPath }}</td>
                    <td>{{ api.apiType }}</td>
                    <td>
                    <div class="form-check">
                        <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        [checked]="api.isAddedToRole"
                        (change)="addApiToRole(api.id,api.api.id,api.isAddedToRole)"
                        />
                    </div>
                    </td> -->
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </body>
</html>

<!-- User post Modal started -->
<div
  class="modal fade"
  id="permForRoleModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>Permission Details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="permissionClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitUser="ngForm" (submit)="createPermission(submitUser)">
            <div class="form-row row">
                <div class="col-1">
                    <label for="Name">Permission</label>
                </div>
                <div class="col-10">
                    <input
                      autocomplete="off"
                      type="text"
                      name="permission"
                      class="form-control"
                      placeholder="permission"
                      ngModel
                      required
                    />
                </div>
                <div class="col-1">
                    <button class="btn btn-primary ml-auto mr-auto" type="submit">
                      Create
                    </button>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- User post Modal end -->
