<div class="container-fluid">
  <div class="card shadow container-fluid">
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Previous
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            Today
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Next
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
      </div>
      <div class="col-md-3">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Month
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Week
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
          >
            Day
          </div>
        </div>
      </div>
      <div class="col-md-1">
        <button class="btn btn-primary"
         data-toggle="modal"
         data-target="#launchAppointmentModal"
         data-backdrop="static"
         data-keyboard="false">Create</button>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="launchAppointmentModal"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-dialog-centered"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>New Appointment details</h5>
          <button
            type="button"
            class="close"
            id="closeTaskAddModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitTask="ngForm" (submit)="createEvent(submitTask)">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Name">Title</label>
                <input
                  autocomplete="off"
                  type="text"
                  name="title"
                  class="form-control"
                  placeholder="Task header"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="email">Start Time</label>
                <input
                  autocomplete="off"
                  type="datetime-local"
                  name="title"
                  class="form-control"
                  placeholder="Task header"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="email">End Time</label>
                <input
                  autocomplete="off"
                  type="datetime-local"
                  name="title"
                  class="form-control"
                  placeholder="Task header"
                  ngModel
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary ml-auto mr-auto" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
