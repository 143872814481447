<!-- <div class="row mx-auto">
    <div class="card rounded mx-auto my-2">
      <div class="row">
        <div class="col-2">Lead Id:</div>
        <div class="col-2">Name:</div>
        <div class="col-2">email:</div>
        <div class="col-2">Mobile No:</div>
      </div>  
    </div>
</div>

<div class="row mx-auto">
    <div class="card rounded serviceCard mx-auto my-2">
      <div class="row">
        <div class="col-4">
            <h4>Service Availed: </h4>
        </div>
        <div class="col-4"></div>
        <div class="col-4">
            <h4>Service Charges:  </h4>
        </div>
    </div>

    <form #discountForm="ngForm" (submit)="discountApply(discountForm)">
    <div class="row my-2">
        <div class="col-3">
            <label for="sCharge">Service Charges</label>
            <input name="sCharge" type="text"  disabled ngModel>
        </div>
        <div class="col-1"></div>
        <div class="col-3">
            <label for="discount">Discount</label><br>
            <input type="text" name="discount"  ngModel>
        </div>
        <div class="col-1"></div>
        <div class="col-3">
            <label for="net">Net payable</label><br>
            <input name="net" type="text" disabled ngModel>
        </div>
        <div class="col-1">
            <button class="btn btn-primary" type="submit">OK</button>
        </div>
    </div>
</form>
    <div class="row">

        <div class="card mx-auto">
            <div class="card-header">
                <div class="row">
                    <form #installmentForm="ngForm" (submit)="addInstallment(installmentForm)">
                        <label for="isnt">Installment Amount</label>
                        <input type="text" name="isnt" ngModel>
                        <button class="btn btn-primary" type="submit">Add Installment</button>
                    </form>
                </div>
        </div>
        <div class="card-body">

            <table class="table table-bordered">
                <thead>
                  <tr>
                      <th scope="col">Sr.</th>
                      <th scope="col">Installment Amount</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Received date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let inst of Installment; let i = index">
                        <th scope="row"></th>
                        <td></td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>@mdo</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>
</div> -->

<div class="container-fluid card">
  <div class="card-header">Add installment/ discount {{invoiceDetails?.leadToServiceId}}</div>
  <div class="card-body">
    <form #discountForm="ngForm" (submit)="postDiscount(discountForm)">
      <div class="row">
        <div class="col-1.5">
          <label for="">Net Amount</label>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Gross charges</span
              >
            </div>
            <input
              disabled
              [value]="grossAmount"
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Discount</span
              >
            </div>
            <input
              [value]="discount"
              type="number"
              max="100"
              min="0"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              (input)="addDiscount($event)"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Net charges</span
              >
            </div>
            <input
              disabled
              [value]="netAmount"
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
        </div>
        <div class="col-1">
          <button class="btn-sm btn-primary" type="submit">Apply</button>
        </div>
      </div>
    </form>
    <form #installmentForm="ngForm" (submit)="addInstallment(installmentForm)">
      <div class="row">
        <div class="col-1.5">
          <label for="">Installment</label>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Pending amount</span
              >
            </div>
            <input
              disabled
              [value]="pendingAmount"
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Installment</span
              >
            </div>
            <input
              type="number"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              name="installment"
              #installment="ngModel"
              ngModel
            />
          </div>
          <p class="error" *ngIf="pendingAmount < installment.value">
            Installment can't be greater than pending amount.
          </p>
        </div>
        <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">
                <select name="received" id="" ngModel>
                  <option value="received">Received on</option>
                  <option value="due">Due on</option>
                </select>
              </span>
            </div>
            <input
              type="date"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              name="installmentDate"
              ngModel
            />
          </div>
        </div>
        <div class="col-1">
          <button
            class="btn-sm btn-primary"
            type="submit"
            [disabled]="pendingAmount <= 0 || pendingAmount < installment.value"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="container-fluid card">
  <div class="card-header">
    Pending installments
    <div class="close">Pending amount: {{ pendingInstallmentsAmount }}</div>
  </div>
  <div class="card-body">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Added on</th>
          <th scope="col">Added By</th>
          <th scope="col">Amount</th>
          <th scope="col">Due date</th>
          <th scope="col">Receive</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let installment of pendingInstallments; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ installment.addedOn | date : "mediumDate" }}</td>
          <td>{{ installment.addedBy }}</td>
          <td>{{ installment.amount }}</td>
          <td>{{ installment.dueOn | date : "mediumDate" }}</td>
          <td>
            <button
              class="btn btn-sm btn-primary"
              (click)="receivePendingInstallment(installment, i)"
            >
              Receive
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid card">
  <div class="card-header">
    Received installments
    <div class="close">Received amount: {{ receivedInstallmentsAmount }}</div>
  </div>
  <div class="card-bdoy">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Added on</th>
          <th scope="col">Received by</th>
          <th scope="col">Amount</th>
          <th scope="col">Received on</th>
          <th scope="col">Invoice</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let installment of receivedInstallments; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ installment.addedOn | date : "mediumDate" }}</td>
          <td>{{ installment.receivedBy }}</td>
          <td>{{ installment.amount }}</td>
          <td>{{ installment.receivedOn | date : "mediumDate" }}</td>
          <td><button class="btn btn-sm btn-primary" (click)="viewInvoices()">View</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    <div class="card container-fluid" *ngIf="viewInvoice">
        <div class="card-header">
        Print invoice
    </div>
    <div class="card-body">
        <app-invoice></app-invoice>
    </div>
</div>