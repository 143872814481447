
    <div id="print-section" class="mx-auto">
        <div class="invoice">
            <div class="row">
                <div class="col-4">
            <img src="../../../../../assets/img/Osyncs logo.png" alt="logo" class="logo">
                </div>
                <div class="col-4" style="text-align: center;"><h4>Tax Invoice</h4></div>
                <div class="col-4">
                   <b>Invoice No.</b> 
                <br/>
                    <b>Invoice Date</b> 
                    <br/>
                    <b>GST</b> 
                 </div>
                 
            </div>
            <hr>
            <div class="row">
                <div class="col-1">
                    <b>To:</b><br/>
                </div>
                <div class="col-5">
                    <br>
                    <p class="toAddress">Near Govt. high school <br/> Khatti Bazaar<br/>Rampura Phul, Bathinda<br/>151103</p>
                </div>
                <div class="col-1">
                    <b>From:</b><br/>
                </div>
                <div class="col-5">
                    <br>
                    <p class="toAddress">Phul Road, Near Truck Union, Rampura Phul,
                        <br/>Bathinda,Punjab-151103
                        <br/>85620-00014
                        <br/><b>GSTIN</b></p>
                </div>
            </div>
            <table class="table table-bordered"> 
                <thead>
                  <tr>
                    <th scope="col">Sr.</th>
                    <th scope="col">Service</th>
                    <th scope="col">Qty/ months</th>
                    <th scope="col">Gross Amount</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Taxable Amount</th>
                    <th scope="col">GST</th>
                    <th scope="col">Net Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                </tbody>
              </table>
            <div class="row">
                <div class="col-8"></div>
                <div class="col-2">Invoice Total</div>
                <div class="col-2">{{ 11700 | currency : 'INR'}}</div>
            </div>
            <br><br>

            <hr>
            
            <p class="dashP">Contact Helpline : 9876543210, Email : errors@themiigrators.com</p>
            <hr>
            <p class="footer">*This is a computer generated invoice no signature required.<br>*Error and omissions in this invoice shall be subject to the Jurisdiction of the Phul-Bathinda.
            <br>* Any alteration to the invoice should be bring to the notice 
            within 5 Days of the purchase
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-auto mx-auto">
            <button  [useExistingCss]="true" class="btn btn-primary mx-auto" printSectionId="print-section" ngxPrint>Print</button>
        </div>
    </div>

