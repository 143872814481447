  <li *ngIf="node" style="white-space: nowrap;">
    <span [ngClass]="{ 'highlight': node.expanded }" class="caret" (click)="toggleCollapse($event)"> {{ node.branchName }} &nbsp;&nbsp;&nbsp;</span>
    <span [ngClass]="{ 'highlight': selectedEye === node.id, 'highlightBlack': selectedEye !== node.id}" (click)="printBranchName(node.id)" style="cursor: pointer;"><i class="fas fa-eye"></i></span>
    <ul *ngIf="node.children && node.children.length" [ngClass]="{'collapse': !node.expanded}">
      <app-tree-component
        *ngFor="let child of node.children"
        [node]="child"
        (eyeBranchClicked)="printBranchName($event)"
      ></app-tree-component>
    </ul>
  </li>