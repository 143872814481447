<div class="container-fluid">
  <div class="card shadow rounded parentRow">
    <div class="row">
      <div class="col-1">
        <div class="row my-1">
          <button
            class="btn btn-primary"
            (click)="addControl('text')"
            data-toggle="modal"
            data-target="#invoiceLaunch"
            data-backdrop="static"
            data-keyboard="false"
          >
            Input box
          </button>
        </div>
        <div class="row my-1">
          <button
            class="btn btn-primary"
            (click)="addControl('date')"
            data-toggle="modal"
            data-target="#invoiceLaunch"
            data-backdrop="static"
            data-keyboard="false"
          >
            Date
          </button>
        </div>
        <div class="row my-1">
          <button
            class="btn btn-primary"
            (click)="addControl('select')"
            data-toggle="modal"
            data-target="#invoiceLaunch"
            data-backdrop="static"
            data-keyboard="false"
          >
            Select
          </button>
        </div>
        <div class="row my-1">
          <button
            class="btn btn-primary"
            (click)="addControl('checkbox')"
            data-toggle="modal"
            data-target="#invoiceLaunch"
            data-backdrop="static"
            data-keyboard="false"
          >
            Checkbox
          </button>
        </div>
        <div class="row my-1">
          <button class="btn btn-primary">New row</button>
        </div>
      </div>
      <div class="col-8 mx-auto">
        <div class="row">
          <ng-container *ngFor="let control of controls">
            <div
              class="form-group"
              [ngClass]="control.controlWidth"
              *ngIf="control.controlType == 'text'"
            >
              <label for="exampleInputPassword1">{{
                control.controlLabel
              }}</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>
            <div
              class="form-group"
              [ngClass]="control.controlWidth"
              *ngIf="control.controlType == 'date'"
            >
              <label for="exampleInputPassword1">{{
                control.controlLabel
              }}</label>
              <input
                type="date"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>
            <div
              class="form-group my-auto"
              [ngClass]="control.controlWidth"
              *ngIf="control.controlType == 'checkbox'"
            >
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
                  name="milestone"
                />
                <label class="custom-control-label" for="customSwitch1">{{
                  control.controlLabel
                }}</label>
              </div>
            </div>
            <div
              class="form-group"
              [ngClass]="control.controlWidth"
              *ngIf="control.controlType == 'select'"
            >
              <label for="exampleFormControlSelect1">{{
                control.controlLabel
              }}</label>
              <select class="form-control" id="exampleFormControlSelect1">
                <option *ngFor="let milestone of control.milestonesAdded">{{milestone}}</option>
              </select>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal"
  id="invoiceLaunch"
  role="dialog"
  aria-labelledby="invoiceModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog" role="navigation">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ controlSelected }}
        </h5>
        <button
          type="button"
          id="closeButtonInvoice"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #valuesForm="ngForm" (submit)="addControlValues(valuesForm)">
          <div class="form-group">
            <label for="exampleInputPassword1">Label value</label>
            <input type="text" class="form-control" name="label" ngModel />
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Control width</label>
            <select class="form-control" name="width" ngModel>
              <option>3</option>
              <option>4</option>
              <option>6</option>
              <option>12</option>
            </select>
          </div>
          <ng-container *ngIf="controlSelected == 'select'">
              <div class="form-group">
                <label for="exampleInputPassword1">Options</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Option</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    name="mileStone"
                    [(ngModel)]="milestoneValue"
                    type="text"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" style="padding: 0px">
                      <button
                        [disabled]="milestoneValue == ''"
                        class="btn btn-primary btn-sm"
                        (click)="addMilestoneToServiceArray()"
                        type="button"
                        style="height: 100%"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="form-group"
                *ngFor="let mStone of milestonesAdded; let i = index"
              >
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Option {{ i + 1 }}</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    [name]="'mileStone' + i + 1"
                    #mileStoneVlaue
                    [ngModel]="milestonesAdded[i]"
                    type="text"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" style="padding: 0px">
                      <button
                        class="btn btn-primary btn-sm"
                        (click)="changedMilestone(mileStoneVlaue.value, i)"
                        type="button"
                        style="height: 100%"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
          </ng-container>
          <div class="row">
            <div class="col-auto mx-auto">
              <button class="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
