<script src="https://use.fontawesome.com/d3ca660215.js"></script>

<!-- Lead Filters start -->
<div>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px">
      <div class="card-header border-0">
        <div class="row">
          <div class="col">
            <h5 class="d-inline-block align-middle my-0 mr-3">Lead List</h5>
          </div>
          <div class="col-auto">
            <div class="form-row">
              <!-- <div class="col-auto">
              <select class="custom-select
              " #statusdrop style="width:220px;" (change)="searchLeadByStatus(statusdrop.value)">
                <option value="A" selected="selected">--- View All Lead---</option>
                <option value="O">--- View Open Lead ---</option>
                <option value="R">--- View Registered Lead ---</option>
                <option value="RO">--- View Open + Registered Lead ---</option>
                <option value="D">--- View Deleted Lead ---</option>
                <option value="PC">--- View Process Completed ---</option>
                <option value="C">--- View Closed Lead---</option>
              </select>
            </div>
            <div class="col-auto">
              <select class="form-control inline-block" #servicedrop style="width:300px;"
                (change)="searchLeadByService(servicedrop.value)">
                <option value="0" selected="selected">--- All Services ---</option>
                <optgroup label="Australia">
                  <option value="stu">Study Visa</option>
                  <option value="Dep">Dependent</option>
                  <option value="tou">Tourist Visa</option>
                </optgroup>
                <optgroup label="Canada">
                  <option value="stu">Study Visa</option>
                  <option value="Dep">Dependent</option>
                  <option value="tou">Tourist Visa</option>
                </optgroup>
                <optgroup label="UK">
                  <option value="stu">Study Visa</option>
                  <option value="Dep">Dependent</option>
                </optgroup>
                <optgroup label="New Zealand" class="separator">
                  <option value="stu">Study Visa</option>
                <optgroup>
              </select>
            </div> -->

              <div class="p-auto">
                <div class="btn-group btn-group-sm btn-group-sm" role="group">
                  <!-- <button type="button" class="btn btn-outline-secondary">
                    Lead
                  </button> -->
                  <!-- <button
                    type="button"
                    data-widget="control-sidebar"
                    data-slide="true"
                    class="btn btn-outline-secondary"
                  >
                    Online Enquiry
                  </button> -->
                </div>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  data-toggle="modal"
                  data-target="#submitForm"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <i class="fas fa-plus mr-2"></i>Lead
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0 font-size-9 leadFilters">
        <div class="p-3 border-top">
          <div class="row">
            <div class="col-4">
              <div class="form-row">
                <p class="mb-0 my-auto mx-1">Status Filters </p>
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  id="div_ticketactivityperiod"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    [ngClass]="{'selectedClass': statusValue == '4'}"
                    id="btnticketall"
                    (click)="commonFilter('status',4)"
                  >
                    All
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    [ngClass]="{'selectedClass': statusValue == '1'}"
                    id="btnticketoverdue"
                    (click)="commonFilter('status','1')"
                  >
                    Open
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    [ngClass]="{'selectedClass': statusValue == '2'}"
                    id="btnticketweek"
                    (click)="commonFilter('status','2')"
                    autocomplete="nope"
                  >
                    Registered
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    [ngClass]="{'selectedClass': statusValue == '3'}"
                    id="btnticketmonth"
                    (click)="commonFilter('status','3')"
                    autocomplete="nope"
                  >
                    Closed
                  </button>
                  <!-- <button
                    type="button"
                    class="btn btn-outline-secondary"
                    [ngClass]="{'selectedClass': statusValue == '5'}"
                    id="btnticketmonth"
                    (click)="commonFilter('status',5)"
                    autocomplete="nope"
                  >
                    Deleted
                  </button> -->
            </div>
              </div>
          </div>
          <div class="col-3">
            <div class="input-group input-group-sm searchInput">
              <input
                type="search"
                class="form-control input-sm pr-4 searchInput"
                placeholder="Search..."
                (input)="searchLeadOnNameMobileLeadId(productName.value)"
                #productName
                
                autocomplete="nope"
              />
              <!-- &nbsp;
              <i class="fas fa-bell nav-icon my-auto"></i> -->
            </div>
          </div>
          
          <div class="col-5">
            <div
              class="form-row align-items-center my-auto"
            >
              
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  id="div_ticketactivityperiod"
                >
                  <h6 class="mb-0 my-auto" 
                  *ngIf="canViewAllBranch == 'true'">Branch</h6>
                  <select
                  *ngIf="canViewAllBranch == 'true'"
                    (change)="getLeadsOnBranchForAdmin(branch.value)"
                    class="searchSelect my-auto"
                    #branch
                    name="branch"
                  >
                    <option
                      *ngFor="let branch of branches"
                      value="{{ branch.id }}"
                      [selected]="branchValue == branch.id"
                    >
                      {{ branch.branchName }}
                    </option>
                  </select>
                  <h6 class="mb-0 my-auto">Services</h6>
                  <select
                    class="searchSelect my-auto"
                    name="country"
                    (change)="commonFilter('country',$event.target.value)"
                  >
                  <option value="6" [selected]="countryValue == '6'">All Services</option>
                  <option
                  *ngFor="let service of services"
                  [value]="service.id"
                  [selected]="countryValue == service.id"
                >
                  ---
                  {{ service.serviceName }}
                  ---
                </option>
                    
                  </select>
                </div>

              <!-- <div class="col-auto">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <h6 class="mb-0">Lead Filters :</h6>
                  </div>

                    <div class="form-row">
                      <div class="col">
                        <div class="input-group input-group-sm">
                          <input
                            type="search"
                            class="form-control input-sm pr-4"
                            placeholder="Search..."
                            (input)="searchLead(productName.value)"
                            #productName
                            maxlength="50"
                            autocomplete="nope"
                          />
                          <button
                            class="btn btn-sm btn-outline-secondary"
                            type="button"
                            ng-click="SearchLeadData()"
                            autocomplete="nope"
                          >
                            <i> search </i>
                          </button>
                        </div>
                      </div>
                      
                      <div class="col-auto">
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          type="button"
                          data-toggle="tooltip"
                          title=""
                          ng-click="openAdvanceFilter()"
                          autocomplete="nope"
                          data-original-title="Filter"
                        >
                          <i class="fas fa-filter"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-secondary active"
                          data-toggle="tooltip"
                          id="gridview"
                          name="gridview"
                          title=""
                          ng-click="viewleadcard('grid')"
                          autocomplete="nope"
                          data-original-title="Grid View"
                        >
                          <i class="fas fa-th-list"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          data-toggle="tooltip"
                          id="cardview"
                          name="cardview"
                          title=""
                          ng-click="viewleadcard('list')"
                          autocomplete="nope"
                          data-original-title="Card View"
                        >
                          <i class="fas fa-th-large"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          data-toggle="tooltip"
                          title=""
                          ng-click="getColumns()"
                          autocomplete="nope"
                          data-original-title="Edit Columns"
                        >
                          <i class="fas fa-table"></i>
                        </button>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div> -->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Lead Filters end -->

<!-- Add Lead Modal -->
<div
  class="modal fade"
  id="submitForm"
  style="padding-right: 5px"
  data-modal-width="99vw"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-side modal-top-right" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Basic Details</h5>
        <button
          type="button"
          id="closeButtonSubmitForm"
          class="close"
          (click)="EntryForm.resetForm()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <form
        #EntryForm="ngForm"
        autoComplete="off"
        (submit)="submitform(EntryForm)"
      >
          <div>
            <div class="container-fluid">
              <div class="card">
                <div class="card-header">
                  <h3 class="m-0 text-dark">User Details</h3>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="firstName">First Name</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="firstName"
                        #firstNameRef="ngModel"
                        class="form-control"
                        id="firstName"
                        placeholder="FirstName"
                        ngModel
                        required
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="lastName">Last Name</label>
                      <input
                        autocomplete="off"
                        autocomplete="off"
                        type="text"
                        name="lastName"
                        #lastNameRef="ngModel"
                        class="form-control"
                        id="lastName"
                        placeholder="LastName"
                        ngModel
                        required
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="gender">Gender</label>
                      <select
                        class="form-control"
                        id="gender"
                        name="gender"
                        #genderRef="ngModel"
                        aria-hidden="true"
                        ngModel
                      >
                        <option value="Male">--- Male ---</option>
                        <option value="Female">--- Female ---</option>
                        <option value="Other">--- Other ---</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="dob">Date of Birth</label>
                      <input
                        type="Date"
                        name="dob"
                        #dobRef="ngModel"
                        class="form-control"
                        id="dob"
                        ngModel
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="mariStatus">Marital Status</label>
                      <select
                        class="form-control"
                        id="mariStatus"
                        name="mariStatus"
                        #mariStatusRef="ngModel"
                        aria-hidden="true"
                        ngModel
                      >
                        <option value="Single" selected="selected">
                          --- Single ---
                        </option>
                        <option value="Married">--- Married ---</option>
                        <option value="Divorced">--- Divorced ---</option>
                        <option value="Other">--- Other ---</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="service">Service </label>
                      <select
                        class="form-control"
                        id="service"
                        name="service"
                        #serviceRef="ngModel"
                        aria-hidden="true"
                        ngModel
                      >
                          <option
                            *ngFor="let service of services"
                            [value]="service.id"
                          >
                            ---
                            {{ service.serviceName }}
                            ---
                          </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="countryOfCitizenship">Country Of Citizenship</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="countryOfCitizenship"
                        #countryOfCitizenshipRef="ngModel"
                        class="form-control"
                        id="countryOfCitizenship"
                        placeholder="Country of citizenship"
                        ngModel
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="passportNumber">Passport Number</label>
                      <input
                        autocomplete="off"
                        autocomplete="off"
                        type="text"
                        name="passportNumber"
                        #passportNumberRef="ngModel"
                        class="form-control"
                        id="passportNumber"
                        placeholder="Passport Number"
                        ngModel
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="m-0 text-dark">Contact Details</h3>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="email">Email</label>
                      <input
                        autocomplete="off"
                        type="email"
                        name="email"
                        #emailRef="ngModel"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        ngModel
                        required
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="phoneNumber">Mobile</label><br />
                      <input
                        autocomplete="off"
                        ng2TelInput
                        [ng2TelInputOptions]="{ initialCountry: 'au' }"
                        type="text"
                        name="phoneNumber"
                        (ng2TelOutput)="getNumber($event)"
                        #phoneRef="ngModel"
                        ngModel
                        class="form-control"
                        id="phoneNumber"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h3 class="m-0 text-dark">Address Details</h3>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="address">Street/ Landmark</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="address"
                        #addressRef="ngModel"
                        ngModel
                        class="form-control"
                        id="address"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-row">                 
                    <div class="form-group col-md-3">
                      <label for="city">Suburb/ City</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="city"
                        #cityRef="ngModel"
                        ngModel
                        class="form-control"
                        id="city"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="state">State</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="state"
                        #stateRef="ngModel"
                        ngModel
                        class="form-control"
                        id="state"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="city">Country</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="countryLead"
                        #countryRef="ngModel"
                        ngModel
                        class="form-control"
                        id="countryLead"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="pincode">Pincode</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="pincode"
                        #pincodeRef="ngModel"
                        ngModel
                        class="form-control"
                        id="pincode"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header inline">
                  <h3 class="m-0 text-dark d-sm-inline-block">Notes</h3>
                  <button
                    type="button"
                    class="btn float-right rounded-circle"
                    (click)="voiceSearch()"
                  >
                    <i class="fas fa-microphone-alt fa-xl" [ngClass]="{'fa-microphone-alt-animate' : isListening }"></i>
                  </button>
                </div>
                <div class="card-body">
                  <div class="form-group purple-border">
                    <textarea
                      autocomplete="off"
                      class="form-control"
                      #notes
                      id="notes"
                      name="notes"
                      #notesRef="ngModel"
                      rows="3"
                      ngModel
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <button class="btn btn-primary ml-auto mr-auto" type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Add Lead Modal End -->

<!-- Leads Table -->
<div class="container-fluid px-4 rounded">
  <div class="card shadow" style="border-radius: 10px">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Lead Code</th>
          <th scope="col">Lead Details</th>
          <th scope="col">Address</th>
          <th scope="col">Contact Details</th>
          <th scope="col">Service</th>
          <!-- <th scope="col">Entry Details</th> -->
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody id="accordion" style="background-color: white;">
        <!-- Drafted Lead -->
        <tr *ngIf="draftedLead != null">
            <!-- data-toggle="collapse" [attr.data-target]="'#' + 'in' + ind" -->
            <td>
              Drafted Lead
            </td>
            <td>
              {{ draftedLead.firstName }} {{ draftedLead.lastName }}<br />
            </td>
            <td>
              {{ draftedLead.leadAddresses[0]?.addressLine1 | slice :0 :25}}<br />
              {{ draftedLead.leadAddresses[0]?.city | slice :0 :40}}<br />
              {{ draftedLead.leadAddresses[0]?.state | slice :0 :40}}<br />
              {{ draftedLead.leadAddresses[0]?.pincode }}
            </td>
            <td>
              Mob:<span> {{ draftedLead.phoneNumber }}</span
              ><br />
              EMail:<span> {{ draftedLead.emailId }}</span
              ><br />
              <input
                class="datetimepicker input-sm"
                value="{{ draftedLead.dob }}"
                type="date"
                disabled
              />
            </td>

            <td>
              {{ draftedLeadServiceName }}
            </td>
            <td>
              <ul class="MiniHorzNav" style="margin-left: -50px">
                <li class="mt-1 cursorPointer" (click)="createDraftedLead()">
                  <i class="fas fa-plus-circle fa-xl"></i> Create
                    <!-- (click)="setLeadOfferModalValues(lead)" -->
                    <!-- <span>Launch</span> -->
                  
                </li>
                <br/>
                <li class="mt-1 cursorPointer" (click)="deleteDraftedLead()">
                  <i class="fas fa-minus-circle fa-xl"></i> Delete
                    <!-- (click)="setLeadOfferModalValues(lead)" -->
                    <!-- <span>Launch</span> -->
                  
                </li>
              </ul>
            </td>
            <br />
          </tr>
        <ng-container *ngFor="let lead of leads; let ind = index">
          <!-- [ngClass]="{
            blueBack: lead.status == 1,
            greenBack: lead.status == 2,
            redBack: lead.status == 3
          }" -->
          <tr
            >
            <!-- data-toggle="collapse" [attr.data-target]="'#' + 'in' + ind" -->
            <td>
              <!-- &nbsp; &nbsp; &nbsp; <i class="fas fa-bell changingColor"></i>  -->
              {{ lead.id }} <br />
              <span class="text-xs">
                On : {{ lead.createdDate | date: "mediumDate" }} <br />
                By: {{ lead?.userAssigned?.userEmail | slice : 0 : 23}}
              </span>
              <br />
              <span 
                ><i
                  (click)="starClicked(lead, 1)"
                  class="star-rating-blank"
                  [ngClass]="{
                    'star-rating-filled': lead.rating >= 1,
                    'star-rating-blank': lead.rating < 1
                  }"
                  class="fa fa-star"
                  aria-label="hidden"
                ></i
              ></span>
              <span
                ><i
                  (click)="starClicked(lead, 2)"
                  class="star-rating-blank"
                  [ngClass]="{
                    'star-rating-filled': lead.rating >= 2,
                    'star-rating-blank': lead.rating < 2
                  }"
                  class="fa fa-star"
                  aria-label="hidden"
                ></i
              ></span>
              <span
                ><i
                  (click)="starClicked(lead, 3)"
                  class="starrating--blank"
                  [ngClass]="{
                    'star-rating-filled': lead.rating >= 3,
                    'star-rating-blank': lead.rating < 3
                  }"
                  class="fa fa-star"
                  aria-label="hidden"
                ></i
              ></span>
              <span
                ><i
                  (click)="starClicked(lead, 4)"
                  class="star-rating-blank"
                  [ngClass]="{
                    'star-rating-filled': lead.rating >= 4,
                    'star-rating-blank': lead.rating < 4
                  }"
                  class="fa fa-star"
                  aria-label="hidden"
                ></i
              ></span>
              <span
                ><i
                  (click)="starClicked(lead, 5)"
                  class="star-rating-blank"
                  [ngClass]="{
                    'star-rating-filled': lead.rating >= 5,
                    'star-rating-blank': lead.rating < 5
                  }"
                  class="fa fa-star"
                  aria-label="hidden"
                ></i
              ></span>
            </td>
            <td>
              {{ lead.firstName }} {{ lead.lastName }}<br />
              DOB: {{ lead.dob | date :"mediumDate"}} <br>
              <!-- <span class="border border-success">Registered</span><br /> -->
              <span>
                <select
                  default="Open"
                  [ngClass]="{
                    blueBack: lead.status == 1,
                    greenBack: lead.status == 2,
                    redBack: lead.status == 3
                  }"
                  class="border"
                  (change)="leadStatusUpdate(lead.id, $event, lead.firstName)"
                >
                  <option [selected]="lead.status == '1'" value="1">
                    Open
                  </option>
                  <option [selected]="lead.status == '2'" value="2">
                    Registered
                  </option>
                  <option [selected]="lead.status == '3'" value="3">
                    Closed
                  </option>
                </select> </span
              >
            </td>
            <td>
              {{ lead.leadAddresses[0]?.addressLine1 | slice :0 :25}}<br />
              {{ lead.leadAddresses[0]?.city | slice :0 :40}}<br />
              {{ lead.leadAddresses[0]?.state | slice :0 :40}}<br />
              {{ lead.leadAddresses[0]?.pincode }}
            </td>
            <td>
              Mob:<span> {{ lead.phoneNumber }}</span
              ><br />
              EMail:<span> {{ lead.emailId }}</span
              ><br>
              <div class="dropdown" *ngIf="lead.phoneNumber">
                <a class="border border-info" data-toggle="dropdown" style="cursor: pointer;" (click)="getWatsappMsgOfContact(lead.phoneNumber)"><i class="fa-brands fa-whatsapp"></i>
                   Whatsapp<p class="badge badge-danger wasBadge" *ngIf="lead?.unreadCount != 0">{{lead?.unreadCount}}</p></a>

                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right sendEmailForm"
                  aria-labelledby="dropdownMenuButton">
                    <div class="card qrCard" *ngIf="showWhatsappQrTemplate">
                      <app-whatsappqr [qrCodeToGenerate]="qrCodeToGenerate"></app-whatsappqr>
                    </div>
                    <div class="card whatsappCard" *ngIf="!showWhatsappQrTemplate && !showRequestAgainButton">
                      <div class="card-header whatsappHeader">
                          <p class="whatsappHeading">Whatsapp</p>
                          <p class="chatName">{{lead.firstName}}</p>
                      </div>
                      <div class="card-body whatsappMessageSection">
                        <ng-container *ngFor="let whatsappMessages of whatsappMessages">
                          <div class="messageReceived card shadow" *ngIf="!whatsappMessages.fromMe">{{whatsappMessages.body}}</div>
                          <div class="messageSent card shadow" *ngIf="whatsappMessages.fromMe">{{whatsappMessages.body}}</div>
                        </ng-container>
                        </div>
                        <div class="card-footer footerWhatsapp">
                          <textarea id="comment" [(ngModel)]="message" class="form-control watsappInput" (keyup.Enter)="sendWatsappMessage(lead.phoneNumber, message )" #comment>
                            </textarea>
                        </div>
                    </div>
                    <div class="card" *ngIf="showRequestAgainButton">
                      <div class="card-header">
                        Request taking too long !
                      </div>
                      <button class="btn btn-primary" (click)="getWatsappMsgOfContact(lead.phoneNumber)">Request again</button>
                    </div>
                </div>
              </div>
            </td>

            <td>
              {{ lead.leadToService[0].Service.serviceName }}
              <!-- <select>
              <option value="a">abc</option>
              <option value="a">abc</option>
              <option value="a">abc</option>
              <option value="a">abc</option>
            </select> -->
              <br />
              <span>
                <select
                  default="Open"
                  [disabled]="userRole == 'user'"
                  class="border border-success"
                  (change)="
                    leadMilestoneUpdate(lead.leadToService[0].id, $event, lead.firstName, lead.phoneNumber)
                  "
                >
                  <option
                    *ngFor="
                      let milestone of lead.leadToService[0].Service
                        .serviceMilestone
                    "
                    [selected]="
                      milestone.milestoneSequenceNumber ==
                      lead.leadToService[0].milestoneSequenceNumber
                    "
                    value="{{ milestone.milestoneSequenceNumber }} {{milestone.id}} {{milestone.milestoneName}} "
                  >
                    {{ milestone.milestoneName }}
                  </option>
                </select>
              </span>
            </td>
            <!-- <td>On : {{followUpDate | date : 'fullDate'}} <br />
            By: Ankita
          </td> -->
            <td class="actionsTd">
              <ul class="MiniHorzNav" style="margin-left: -50px">
                <!-- <li>
                
                <i data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#exampleModalLong"
                  class="fa fa-edit fa-xl"></i>
                <span>Edit</span>
              </li> -->
                <!-- <li>
                <a data-toggle="dropdown"><i class="fa fa-comments fa-xl ml-3"></i>
                  <span>Comment</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <a class="dropdown-item">
                                        <h3 class="dropdown-item-title">
                      Comments:
                    </h3>
                    <div *ngFor="let comment of comments">
                      <div class="dropdown-divider"></div>
                      <p class="text-sm text-dark">{{comment}}</p>
                    </div>

                    <div class="dropdown-divider"></div>
                    <div class="p-2">
                      <form #newCommentForm="ngForm" autocomplete="off" (ngSubmit)="saveComment(newCommentForm)"
                        class="form-inline">
                        <input type="text" #newComment autofocus class="input-sm" name="newComment"
                          #newCommentRef="ngModel" ngModel (keyup.enter)="saveComment(newCommentForm)">
                        <button type="submit" class="btn btn-primary btn-sm ml-2">Save</button>
                      </form>
                    </div>
                    
                  </a>
                </div>
              </li> -->
                <!-- <li>
                <a (click)="delete()"><i class="fas fa-user-minus fa-xl ml-3"></i>
                  <span>Delete</span>
                </a>
              </li> -->
                <!-- <br /> -->
                <!-- <li class="mt-1">
                  <a
                    href="https://api.whatsapp.com/send?phone={{
                      lead.phoneNumber
                    }}"
                    target="Blank"
                    style="color: black"
                    ><i class="fas fa-comment-dots fa-xl"></i> Whatsapp
                    <span>Whatsapp</span>
                  </a>
                </li>
                <br /> -->
                <li class="mt-1">
                  <a
                    role="button"
                    data-toggle="modal"
                    data-target="#invoiceLaunch"
                    data-backdrop="static"
                    data-keyboard="false"
                    class="invoiceButton"
                    (click)="setInvoiceData(lead)"
                    ><i class="fas fa-file-pdf"></i> Invoice
                  </a>
                </li>  
                <br />
                <li class="mt-1">
                  <a
                    role="button"
                    routerLink="/user/leadOfferService/lead/{{ lead.id }}"
                    target="_parent"
                    ><i class="fas fa-chevron-circle-right"></i> Details
                    <!-- (click)="setLeadOfferModalValues(lead)" -->
                    <!-- <span>Launch</span> -->
                  </a>
                </li>
                <br/>
                <li class="mt-1 cursorPointer" *ngIf="canDeleteLead == 'true'" (click)="deleteLead(lead.id)">
                  <i class="fas fa-minus-circle"></i> Delete
                    <!-- (click)="setLeadOfferModalValues(lead)" -->
                    <!-- <span>Launch</span> -->
                  
                </li>
              </ul>
            </td>
            <br />
          </tr>
          <!-- <tr  [id]="'in' + ind" class="testing collapse cell-1 row-child"  data-parent="#accordion">
            <td colspan="6">
            <div>
                              abcggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg
            
              </div>  
            </td>
            </tr> -->
          <!-- Lead service detail expand area -->
          <!-- <tr [hidden]="rowExpand">
            <td colspan="6">
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <table class="table table-hover">
                    <thead>
                      <th scope="col">Sr.No.</th>
                      <th scope="col">Services Availed</th>
                      <th scope="col">Progress</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let service of servicesAvailed; let sr = index"
                      >
                        <td>{{ sr + 1 }}</td>
                        <td>{{ service.serviceName }}</td>
                        <td class="row">
                          start..............
                          <div *ngFor="let a of demo; let id = index">
                            <a
                              [ngClass]="{
                                acheived: id <= mileStoneId,
                                notAcheived: id > mileStoneId
                              }"
                              data-toggle="dropdown"
                              >{{ a }}..............</a
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr> -->
          <!-- Lead service detail expand area end -->
          <!-- <div aria-colspan="12" [id]="'in' + ind" class="collapse cell-1 row-child"  data-parent="#accordion">
             abcggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg
          </div> -->
        </ng-container>
      </tbody>
    </table>
    <div class="row pageRow">
      <div class="col-5"></div>
      <div class="col-auto mx-auto">
        <i class="fas fa-chevron-circle-left mr-2 pageIcon"></i>
      </div>
      <div class="col-auto mx-auto">
        <i class="fas fa-chevron-circle-right mr-2 pageIcon"></i>
      </div>
      <div class="col-5"></div>
    </div>
  </div>
</div>

<!-- Leads Table End -->

<!-- Invoice launch area -->
<div
  class="modal fade"
  id="invoiceLaunch"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="invoiceModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
  <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Invoices for TestUser</h5>
        <button
          type="button"
          id="closeButtonInvoice"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-invoice-service-charge *ngIf="showInvoiceDetails" [invoiceDetails]="invoiceDetails" [callMethod]="'abc'"></app-invoice-service-charge>
      </div>
    </div>
  </div>
</div>

  <!-- <div class="sendEmailForm"
    aria-labelledby="dropdownMenuButton">
      <div class="card qrCard" *ngIf="showWhatsappQrTemplate">
        <app-whatsappqr [qrCodeToGenerate]="qrCodeToGenerate"></app-whatsappqr>
      </div>
      <div class="card whatsappCard" *ngIf="!showWhatsappQrTemplate && !showRequestAgainButton">
        <div class="card-header whatsappHeader">
            <p class="whatsappHeading">Whatsapp</p>
            <p class="chatName">lead.firstName</p>
        </div>
        <div class="card-body whatsappMessageSection">
          <ng-container *ngFor="let whatsappMessages of whatsappMessages">
            <div class="messageReceived card shadow" *ngIf="!whatsappMessages.fromMe">{{whatsappMessages.body}}</div>
            <div class="messageSent card shadow" *ngIf="whatsappMessages.fromMe">{{whatsappMessages.body}}</div>
          </ng-container>
          </div>
          <div class="card-footer footerWhatsapp">
            <textarea id="comment" [(ngModel)]="message" class="form-control watsappInput" #comment>
              </textarea>
          </div>
      </div>
      <div class="card" *ngIf="showRequestAgainButton">
        <div class="card-header">
          Request taking too long !
        </div>
        <button class="btn btn-primary" >Request again</button>
      </div>
  </div> -->

            