export const messageConstants = {
    common : "Dear",

    //Canada
    leadRegistered2 : ", Welcome to The Miigrators family and we express our gratitude for entrusting us.",
    VerificationCompleted2 : ", This is to inform you that your application process has been initiated and documentation verification has been completed.",
    OfferLetterApplied2 : ", This is to inform you that your offer letter  has been applied successfully.",
    OfferLetterReceived2 : ", Congratulations!!!! your offer letter has been received.",
    TuitionFeePaid2 : ",  This is to inform you that your tuition fee has been Paid.",
    MedicalExaminationAttended2 : ", This is to inform you that your  medical examination has been successfully Completed.",
    GICPaid2 : ", This is to inform you that your Guaranteed Investment Certificate (GIC) has been paid.",
    VisaSubmitted2 : ", This is to inform you that your Visa application has been submitted to IRCC, Canada.",
    BiometricAttended2 : ", This is to inform you that information about your Biometric attendance has been updated in records.",
    VisaOutcomeRecieved2  : ", IRCC has Finalised your visa application. We appreciate your patience throughout the process.",

    //Australia
    VerificationCompleted3 : ", This is to inform you that your application process has been initiated and documentation verification has been completed.",
    OfferLetterApplied3 : ", This is to inform you that your offer letter  has been applied successfully.",
    OfferLetterReceived3 : ", Congratulations!!!! your offer letter has been received.",
    TuitionFeePaid3 : ", This is to inform you that your tuition fee has been Paid.",
    MedicalExaminationAttended3 : ", This is to inform you that your  medical examination has been successfully Completed.",
    GTEDocumentSubmitted3 : ", This is to  inform you that your documents have been successfully submitted for GTE assessment.",
    GTEApproved3 : ", Congratulations!!!! Your GTE assessment has been approved.",
    TutionFeePaid3 : ", This is to inform you that your tuition fee has been Paid.",
    OSHCPaid3 : ", This is to inform you that your Overseas Student Health Cover (OSHC) has been successfully paid.",
    COEReceived3 : ", This is to inform you that your Confirmation of Enrollement (COE) has been received.",
    VisaSubmitted3 : ", This is to inform you that your visa application has been submitted to the Department of Home Affairs, Australia.",
    VisaOutcomeRecieved3  : ", Department of Home Affairs, Australia has finalised your visa application. We appreciate your patience throughout the process.",
    

    companyName : "The Miigrators"
}