<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>

  <body>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">
                Apis available
              </h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#apiForModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Api
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">ApiName</th>
                <th scope="col">ApiPath</th>
                <th scope="col">ApiType</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let api of apis">
                <td>{{ api.id }}</td>
                <td>{{ api.apiName }}</td>
                <td>{{ api.apiPath }}</td>
                <td>{{ api.apiType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </body>
</html>

<!-- User post Modal started -->
<div
  class="modal fade"
  id="apiForModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>API Details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="permissionClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitApi="ngForm" (submit)="createAPI(submitApi)">
            <div class="form-row row">
                <div class="col-2">
                    <label for="Name">API Name</label>
                </div>
                <div class="col-10">
                    <input
                      autocomplete="off"
                      type="text"
                      name="apiName"
                      class="form-control"
                      ngModel
                      required
                    />
                </div>                
            </div>
            <div class="form-row row">
              <div class="col-2">
                  <label for="Name">API Path</label>
              </div>
              <div class="col-10">
                  <input
                    autocomplete="off"
                    type="text"
                    name="apiPath"
                    class="form-control"
                    ngModel
                    required
                  />
              </div>                
          </div>
          <div class="form-row row">
            <div class="col-2">
                <label for="Name">API Type</label>
            </div>
            <div class="col-10">
                <input
                  autocomplete="off"
                  type="text"
                  name="apiType"
                  class="form-control"
                  ngModel
                  required
                />
            </div>                
        </div>
            <div class="row">
              <div class="col-1 mx-auto">
                <button class="btn btn-primary ml-auto mr-auto" type="submit">
                  Create
                </button>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- User post Modal end -->
