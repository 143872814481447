import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-admin-view-edit-course',
  templateUrl: './admin-view-edit-course.component.html',
  styleUrls: ['./admin-view-edit-course.component.scss']
})
export class AdminViewEditCourseComponent implements OnInit {

  viewLoader : boolean = true;
  viewLoader2:boolean = false;
  courseId:any;
  Colleges:any = [
    // {
    //   collegeName : "Norquest college",
    //   Address : "Alberta, Edmonton",
    //   courseName:"Hospitality",
    //   level:"Diploma",
    //   fee:"100",
    //   duration:"2years",
    //   Intakes :{
    //     January : false,
    //     May : true,
    //     September : false
    //   }
    // }
  ]
  collegeId : any;
  collegeName : any;
  intakes : any;
  constructor(private leadApi : CRMLeadServiceService, private route : ActivatedRoute) {
    this.collegeId =  this.route.snapshot.params['collegeId']
    this.collegeName =  this.route.snapshot.params['collegeName']
   }

  ngOnInit(): void {
    this.getCourseOfCollege();
    // this.getIntakes();
    //this.getIntakesOfCourse(1);
  }

  getCourseOfCollege(){
   // this.viewLoaderMain = false;
    this.viewLoader2 = false
    this.leadApi.getCourseOfCollege(200, 0,this.collegeId).subscribe(
      data =>{
        this.stopLoader();
        this.Colleges = data
        console.log(data)
        this.viewLoader2 = true;
      },
      err=>{
        console.log(err)
        this.viewLoader2 = true;
      },
      ()=>{
      }
    )
  }

  stopLoader(){
  }

  getIntakesOfCourse(courseId){
    this.leadApi.getIntakeOfCourse(courseId).subscribe(
      data => 
      {
        this.courseId = courseId
        this.intakes = data  
       console.log(data)
    }
    )
  }

  clickedCheckBox(intakeid, courseToCourseIntakeId){
    if(courseToCourseIntakeId == null){
      this.leadApi.addintakeOfCourse(intakeid, this.courseId).subscribe(
        data =>{
          this.getIntakesOfCourse(this.courseId)
        }
      )
    }
    else{
      this.leadApi.removeIntakeOfCourse(courseToCourseIntakeId).subscribe(
        data =>{
          this.getIntakesOfCourse(this.courseId)
        }
      )
    }
  }
  // getIntakes(){
  //   this.leadApi.getIntakes().subscribe(
  //     data =>{
  //       this.intakes = data
  //     }
  //   )
  // }

  createProgram(form: NgForm){
    this.viewLoader = false;
    let obj = {
      collegeId : this.collegeId,
      courseName : form.value.coName,
      courseDescription : form.value.description,
      streamId : form.value.stream,
      levelOfEducationId : form.value.lOEd,
      offerFee : form.value.fees,
      courseDuration : form.value.description
    }
    this.leadApi.postCourse(obj).subscribe(
      data => {
        alert("Course added successfully");
        console.log(data)
        this.viewLoader = true;
        document.getElementById("closeButton").click();
      },
      err =>{
        alert("some error occured!");
        this.viewLoader = true;
      },
      ()=>{
        console.log("Added course successfully");
      }
    )
  }

  createIntake(form : NgForm){
    this.viewLoader = false;
    this.leadApi.postIntake(form.value).subscribe(
      data => {
        alert("Intake added successfully.")
        this.viewLoader = true;
      },
      err =>{
        alert("Some error occured.")
        this.viewLoader = true;
      },
      () =>{
        console.log("Intake added successfully");
      }
    )
  }

}
