<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>

  <body>
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-9">
            <div class="row statusRow">

            <div class="card allStatusCard">
              <div class="card-header">
                All Applications Status
              </div>
              <div class="card-body transCard">
                <div class="row">

                <div class="card shadow progressCard mx-auto">
                  <div class="circular-progress-container">
                    <svg class="circular-progress">
                      <circle
                        class="progress-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="60"
                      />
                      <circle
                        class="progress-circle-registered"
                        cx="50%"
                        cy="50%"
                        r="60"
                        [style.stroke-dasharray]="strokeDashArrayRegistered"
                      />
                      <text x="50%" y="50%" dy=".3em" text-anchor="middle">
                        {{ progressRegistered | number : '1.0-1' }}%
                      </text>
                      <text x="50%" y="60%" dy=".3em" text-anchor="middle">
                        Registered
                      </text>
                    </svg>
                  </div>
                  <div class="bottomText">
                    <p>Registered Leads <br> {{registeredCount}}</p>
                  </div>
                </div>
                <div class="card shadow progressCard mx-auto">
                  <div class="circular-progress-container">
                    <svg class="circular-progress">
                      <circle
                        class="progress-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="60"
                      />
                      <circle
                        class="progress-circle-open"
                        cx="50%"
                        cy="50%"
                        r="60"
                        [style.stroke-dasharray]="strokeDashArrayOpen"
                      />
                      <text x="50%" y="50%" dy=".3em" text-anchor="middle">
                        {{ progressOpen | number : '1.0-1' }}%
                      </text>
                      <text x="50%" y="60%" dy=".3em" text-anchor="middle">
                        Open
                      </text>
                    </svg>
                  </div>
                  <div class="bottomText">
                    <p>Open Leads <br> {{openCount}}</p>
                  </div>
                </div>
                <div class="card shadow progressCard mx-auto">
                  <div class="circular-progress-container">
                    <svg class="circular-progress">
                      <circle
                        class="progress-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="60"
                      />
                      <circle
                        class="progress-circle-close"
                        cx="50%"
                        cy="50%"
                        r="60"
                        [style.stroke-dasharray]="strokeDashArrayClose"
                      />
                      <text x="50%" y="50%" dy=".3em" text-anchor="middle">
                        {{ progressClose | number : '1.0-1' }}%
                      </text>
                      <text x="50%" y="60%" dy=".3em" text-anchor="middle">
                        Close
                      </text>
                    </svg>
                  </div>
                  <div class="bottomText">
                    <p>Closed Leads <br> {{closedCount}}</p>
                  </div>
                </div>
                <div class="card shadow progressCard mx-auto">
                  <div class="circular-progress-container">
                    <svg class="circular-progress">
                      <circle
                        class="progress-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="60"
                      />
                      <circle
                        class="progress-circle-rejected"
                        cx="50%"
                        cy="50%"
                        r="60"
                        [style.stroke-dasharray]="strokeDashArrayRejected"
                      />
                      <text x="50%" y="50%" dy=".3em" text-anchor="middle">
                        {{ progressRejected }}%
                      </text>
                      <text x="50%" y="60%" dy=".3em" text-anchor="middle">
                        Rejected
                      </text>
                    </svg>
                  </div>
                  <div class="bottomText">
                    <p>Coming <br> Soon</p>
                  </div>
                </div>  
              </div>
            </div>
            
            </div>
              
              
            </div>
            <div class="row graphRow">
              <div class="card shadow graphCard ">
                <canvas id="myChart" style="height: 300px;">{{graph}}</canvas>
                <div class="row">
                  <div class="col-auto mx-auto"><div class="btn-group btn-group-toggle btn-group-sm" data-toggle="buttons">
                    <label class="btn btn-primary">
                      <input type="radio" name="options" id="option1" autocomplete="off" (click)="getLabelsForCalendar(23)"> 2023
                    </label>
                    <label class="btn btn-primary active">
                      <input type="radio" name="options" id="option2" autocomplete="off" checked (click)="getLabelsForCalendar(24)"> 2024
                    </label>
                  </div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card shadow filtersCard">
              <div class="card-header">
                Report Filters
              </div>
              <div class="card-body">
                <form #filtersForm="ngForm" (submit)="applyFilters(filtersForm)">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="mariStatus">Branch</label>
                    <select
                      class="form-control"
                      id="mariStatus"
                      name="branchId"
                      aria-hidden="true"
                      ngModel
                      #branchRef="ngModel"
                      required
                    >
                    <option value="" selected disabled>Select Branch</option>
                      <option *ngFor="let branch of branches" [value]="branch.id">
                        {{branch?.branchName}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="mariStatus">Service</label>
                    <select
                      class="form-control"
                      id="mariStatus"
                      name="serviceName"
                      aria-hidden="true"
                      ngModel
                      #serviceRef="ngModel"
                      required
                      (change)="serviceChanged(serviceRef.value)"
                    >
                    <option value="all">All</option>
                      <option *ngFor="let service of services" [value]="service.serviceName">
                        {{service?.serviceName}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row" *ngIf="quesNeedMilestones">
                  <div class="form-group col-md-12">
                    <div class="custom-control custom-switch">
                      <input [checked]="viewMileStone" type="checkbox" class="custom-control-input" id="customSwitch1" name="milestone" (change)="milestoneSwitch()">
                      <label class="custom-control-label" for="customSwitch1">Milestone filter required?</label>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="viewMileStone && quesNeedMilestones">
                  <div class="form-group col-md-12">
                    <label for="mariStatus">Service Milestone</label>
                    <select
                      class="form-control"
                      id="serMilestone"
                      name="serviceMilestone"
                      aria-hidden="true"
                      ngModel
                      #serviceMilestoneRef="ngModel"
                      required
                    >
                      <option *ngFor="let milestoneSer of milestonesOfService" [value]="milestoneSer.milestoneSequenceNumber">
                        {{milestoneSer?.milestoneName}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="mariStatus">Start Date</label>
                    <input
                        type="Date"
                        name="startDate"
                        class="form-control"
                        id="dob"
                        ngModel
                        #startDateRef="ngModel"
                        required
                      />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="mariStatus">End Date</label>
                    <input
                        type="Date"
                        name="endDate"
                        class="form-control"
                        id="dob"
                        ngModel
                        #endDateRef="ngModel"
                        required
                      />
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto mx-auto">
                    <button class="btn btn-danger" [disabled]="!filtersForm.valid" type="submit">Apply Filter</button>
                  </div>
                </div>
              </form>
                <div class="row">
                  <div class="col-auto mx-auto">
                    <button class="btn btn-primary" (click)="exportExcel()">Export to Excel</button>
                  </div>
                </div>
                <p class="intimationMilestones">
                  * All fields are required. <br>
                  * First apply filter to export excel with filtered results
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
