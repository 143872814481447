import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/Services/adminService/admin.service';

@Component({
  selector: 'app-commission-dashboard',
  templateUrl: './commission-dashboard.component.html',
  styleUrls: ['./commission-dashboard.component.scss']
})
export class CommissionDashboardComponent implements OnInit {
  tableStructure = [
    { key: "Id", value: "id" },
    { key: "Email", value: "lead.emailId" },
    { key: "Course", value: "courseToCourseIntake.course.courseName" },
    { key: "Start Date", value: "courseToCourseIntake.courseIntake.startDate" },
    { key: "Offer Fee", value: "courseToCourseIntake.course.offerFee" },
    { key: "Commission %", value: "commission" },
    { key: "Branch", value: "lead.branch.branchName" },
    { key: "Comission $", value: "computedCommission"}
  ];

  public branchesTreeData;
  branchFilters = [];
  applications: any = [];

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.getAllApplicationsForCollege(localStorage.getItem('branchId'));
    this.getAllSubBranches();
    document.querySelectorAll('.caret').forEach(function(caret) {
      caret.addEventListener('click', function() {
        // Collapse all other collapsible elements
        document.querySelectorAll('.caret').forEach(function(otherCaret) {
          if (otherCaret !== caret) {
            otherCaret.classList.remove('caret-down');
          }
        });
  
        this.classList.toggle('caret-down');
      });
    });
  }

  getAllApplicationsForCollege(branchId) {
    this.adminService.getFilteredApplications(branchId).subscribe((data) => {
      console.log(data);
      this.applications = data;
    });
  }

  getValueFromNestedObject(obj: any, path: string): any {
    const keys = path.split('.');
    return keys.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : ''), obj);
  }
  
  getAllSubBranches(){
    this.adminService.getAllSubBranches().subscribe(
      data => {
        this.branchesTreeData = data.children
        //this.branchFilters.push({key: "Select Branch Level 0", data: data?.children, selectedValue: -1});
        console.log(data.children);
      }
    )
  }

  addNewSubBranchToFilter(level, filter){
    this.branchFilters.splice(level);
    this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).selectedValue = filter;
      if(this.branchFilters.find(x => x.key == "Select Branch Level "+level)){
        this.branchFilters.find(x => x.key == "Select Branch Level "+level).data = this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).data.find(y=> y.id == filter).children;
      }
      else{
        let tempValue = this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).data.find(y=> y.id == filter).children;
        this.branchFilters.push({key: "Select Branch Level "+level, data: tempValue, selectedValue:-1});
        console.log(tempValue)
      }
    console.log( this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).selectedValue)
  }

  applyFilter(){
    this.adminService.getFilteredApplications(this.branchFilters[this.branchFilters.length - (this.branchFilters[this.branchFilters.length - 1].selectedValue == -1? 2: 1)].selectedValue).subscribe(
      data =>{
        this.applications = data
        console.log(data)
      }
    )
  }

  toggleCollapse(node: any): void {
    node.expanded = !node.expanded;
  }

  handleNodeClicked(clickedNode: any): void {
    // Handle collapse behavior here
    this.branchesTreeData.forEach(node => {
      if (node !== clickedNode) {
        node.expanded = false;
      }
    });
  }

  getBranchToFilter(branchId){
    this.getAllApplicationsForCollege(branchId)
  }
}

