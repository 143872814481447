
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid px-4 pt-4 rounded">
        <div class="sticky-top">
        <div class="card shadow" style="border-radius: 10px;">
            <div class="card-header border-0">
                <div class="row">
                    <div class="col-auto mx-auto">
                        <select name="countrySelect" class="form-control form-control-sm">
                            <option value="Select" selected>Country</option>
                            <option value="Canada">Canada</option>
                            <option value="Australia">Australia</option>
                            <option value="NewZealand">New Zealand</option>
                            <option value="UK">United Kingdom</option>
                        </select>
                    </div>
                    <div class="col-auto mx-auto">
                        <select name="State Province or Territory" class="form-control form-control-sm">
                            <option value="default" selected>State Province or Territory</option>
                            <option value="Alberta, Edmonton">Alberta, Edmonton</option>
                        </select>
                    </div>
                    <div class="col-auto mx-auto">
                        <select name="Field of study" class="form-control form-control-sm">
                            <option value="default">Intakes - 2023</option>
                            <option value="Arts">January</option>
                        </select>
                    </div>
                    <div class="col-auto mx-auto">
                        <button class="btn btn-outline-primary btn-sm"
                        type="button"
                  data-toggle="modal"
                  data-target="#launchOfferModal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                <i class="fas fa-plus mr-2"></i>Add College</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="card shadow mx-auto clgCard" *ngFor="let college of Colleges">
            <div class="card-header">
                <div class="row">
                    <div class="col-auto mx-auto">
                        <img src="../../../../../assets/img/pp.jpg" class="clgImage">
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto mx-auto">
                        <h5 class="colgName">{{college.name}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto mx-auto">
                        <p> {{college?.collegeCity}}, {{college?.collegeState}}, {{college?.collegeCountry}}</p>
                    </div>
                </div>
                <br>
                <div class="row">
                    <p class="mx-auto">Intakes - 2023</p>
                    <div class="mx-auto">
                        <p class="intakesP"><span >JANUARY</span> | <span >MAY</span> | <span >SEPTEMBER</span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto mx-auto bcd">
                        <div class="abc"></div><span style="position:absolute">Open</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto mx-auto">
                        <button class="btn btn-primary cardBtn">Apply</button>
                    </div>
                    <div class="col-auto mx-auto">
                       <a routerLink="/user/courses/{{ college.id }}/{{college.name}}" target="_parent"> <button class="btn btn-primary cardBtn">View</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="modal fade" id="launchOfferModal" style="padding-right: 5px; " role="dialog"
aria-labelledby="launchOfferModalTitle" aria-hidden="true">
<div class="modal-dialog modal-xl modal-side modal-top-right" role="navigation">
    <div id="print-section" class="modal-content">
        <div class="sticky-top">
            <div class="modal-header">
                <h5>College Details</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close" id="closeButton">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #collegeForm="ngForm" (submit)="createCollege(collegeForm)">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="Name">College Name</label>
                                    <input autocomplete="off" type="text" name="name"
                                        class="form-control" ngModel required>
                                    </div>
                                <div class="form-group col-md-4">
                                    <label for="email">College Type</label>
                                    <select name="type" ngModel  class="form-control">
                                        <option value="default" selected disabled>Select Type</option>
                                        <option value="1">College</option>
                                        <option value="2">University</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="password">Description</label>
                                    <input type="text" name="description"
                                        class="form-control" 
                                        ngModel required>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4 mx-auto">
                                    <label for="Name">Country</label>
                                    <select autocomplete="off" type="text" name="country"
                                    class="form-control" #country ngModel required (change)="getAllState(country.value)">
                                        <option *ngFor="let country of allCountries" [value]="country.isoCode + ',' + country.name">{{country.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4 mx-auto">
                                    <label for="state">State</label>
                                    <select autocomplete="off" type="text" name="state"
                                    class="form-control" #state ngModel required (change)="getAllCity(state.value)">
                                        <option *ngFor="let state of allStates" [value]="state.isoCode + ',' + state.name">{{state.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4 mx-auto">
                                    <label for="state">City</label>
                                    <select autocomplete="off" type="text" name="city"
                                    class="form-control" #city ngModel require>
                                        <option *ngFor="let city of allCities" [value]="city.name">{{city.name}}</option>
                                    </select>
                                </div>
                            </div>
                    
                    <div class="form-row">
                        <button class="btn btn-primary ml-auto mr-auto" type="submit">Create</button>
                    </div>
                </form>
                <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
            </div>
        </div>
    </div>
</div>
</div>
<!-- <div [hidden]="viewLoader2" class="spinner-border positionSpinner"></div> -->
</body>
</html>