import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

   apiLink : string = environment.whatsappApi;
  // apiLink : string = "http://localhost:3000/api/";
  notificationLink : string = "https://d0e0-103-41-27-107.ngrok-free.app/";

  constructor(private http:HttpClient) { }

  // getQr():Observable<any>{
  //   return this.http.get<any>(`${this.apiLink}qr`).pipe(catchError(this.errorHandler));
  // }

  sendWMessage(phoneNumber, message){
    return this.http.post(`${this.apiLink}sendMessage/${phoneNumber}/${message}/${localStorage.getItem('accountId')}`,{}).pipe(catchError(this.errorHandler));
  }

  getWMessages(phoneNumber){
    return this.http.get<any>(`${this.apiLink}getMessages/${phoneNumber}/${localStorage.getItem('accountId')}`).pipe(catchError(this.errorHandler));
  }

  // resetQr(){
  //   return this.http.get(`${this.apiLink}newClient`).pipe(catchError(this.errorHandler));
  // }

  // getUnreadMessages(abc:any){
  //   let obj:any = {phoneNumbers:abc}
  //   return this.http.post<any>(`${this.apiLink}unRead`, obj).pipe(catchError(this.errorHandler))
  // }

  resetWhatsappSession(){
    return this.http.get<any>(`${this.apiLink}removeSessionCompletely/${localStorage.getItem('accountId')}`).pipe(catchError(this.errorHandler));
  }
  

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error.message || "Server Error");
  } 
  
}

