import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-view-lead-details',
  templateUrl: './view-lead-details.component.html',
  styleUrls: ['./view-lead-details.component.scss']
})
export class ViewLeadDetailsComponent implements OnInit {

  servicesAvailed = []
  demo = ["a", "b", "c", "d","e"]
  mileStoneId = 3
  id = 0
  name = ""
  constructor( private _route: ActivatedRoute, private _crmLeadService: CRMLeadServiceService) {
    this.getServicesPerUser(this.id)
   }

  ngOnInit(): void {
    this.getValuesFromContentComponent()
    this.getServicesPerUser(this.id)
  }

  getValuesFromContentComponent(){
    this.id = this._route.snapshot.params['id']
    this.name = this._route.snapshot.params.name
  }

  getServicesPerUser(id: number){
    this._crmLeadService.getLeadServices(id).subscribe(
      res => {
        for(var i=0; i < res.length; i++){
          console.log(res[i].Service)
          this.servicesAvailed.push(res[i].Service)
        }
      },
      err=>{
        console.log(err)
      },
      ()=>{
        console.log("services fetched successfully")
      }
    )
  }

  addService(form: NgForm){
    this._crmLeadService.addLeadServices(89).subscribe(
      res =>{
        console.log(res)
      },
      err =>{
        console.log(err)
      },
      ()=>{
        console.log("service added successfully")
      }
    )
  }

  addService2(id: number){
    this._crmLeadService.addLeadServices(89).subscribe(
      res =>{
        console.log(res)
      },
      err =>{
        console.log(err)
      },
      ()=>{
        this.servicesAvailed = []
        this.getServicesPerUser(this.id)
        console.log("service added successfully")
      }
    )
  }
}
