import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-branches",
  templateUrl: "./branches.component.html",
  styleUrls: ["./branches.component.scss"],
})
export class BranchesComponent implements OnInit {
  canCreateBranch = localStorage.getItem("canCreateBranch");
  constructor(
    private _router: Router,
    private _crmLeadService: CRMLeadServiceService,
    private toastService : ToastrService
  ) {
    this.getBranches();
  }

  First: any;
  searchedColleges = [];
  Colleges = ["Pu", "Qu", "Ru", "Su"];

  ngOnInit(): void {
    this.searchedColleges = this.Colleges;
  }

  Search() {
    if (this.First == "") {
      this.ngOnInit();
    } else {
      this.searchedColleges = this.Colleges.filter((res) => {
        return res.toLocaleLowerCase().match(this.First.toLocaleLowerCase());
      });
    }
  }

  getBranches() {
    this._crmLeadService
      .getSubBranches()
      .subscribe((res) => {
        this.searchedColleges = res.children;
        console.log(res);
      });
  }

  createBranch(form: NgForm) {
    let obj = {
      branchName: form.value.branchName,
      address: form.value.address,
      gstDetail: {
        gstNumber: form.value.gstNo,
        firmName: form.value.firmName,
        billNumber: 1,
      },
      usersAlloted: form.value.usersAlloted,
      commissionPercentage: form.value.percentage
    };

    this._crmLeadService.createBranch(obj).subscribe(
      (data) => {
        console.log(data);
        form.reset();
        this.toastService.success("Branch created successfully");
        document.getElementById("createBranchClose").click();
        this.getBranches();
      },
      (err) => console.log(err),
      () => console.log("create branch executed successfully")
    );
  }

  toBranch() {
    this._router.navigate(["/user/branch"]);
  }
}
