<form #collegeCourseAdd = "ngForm" >
    <label for="collegeUniversity">
        College/ university
    </label>
    <input type="text" id="collegeUniversity" name="collegeUniversity" ngModel>
    <label for="courseName">
        Course Name
    </label>
    <input type="text" id="courseName" name="courseName" ngModel>
    <label for="level">
        Level
    </label>
    <input type="text" id="level" name="level" ngModel>
    <label for="Discipline">
        Discipline
    </label>
    <input type="text" id="Discipline" name="Discipline" ngModel>
    <label for="Length">
        Length
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        Application Fee
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        Tuition Fee
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        Program desciption
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        work and study in ___
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        Start
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        End
    </label>
    <input type="text" id="Length" name="Length" ngModel>
    <label for="Length">
        Status
    </label>
    <input type="text" id="Length" name="Length" ngModel>

</form>

<p>
    <a class="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      Link with href
    </a>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      Button with data-target
    </button>
  </p>
  <div class="collapse" id="collapseExample">
    <div class="card card-body">
      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
    </div>
  </div>