import { ContentComponent } from './starter/content/content.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StarterComponent } from './starter/starter.component';
import { AuthGuard } from '../Services/AuthGuard/auth.guard';
import { ServicesComponent } from './starter/services/services.component';
import { AppComponent } from '../app.component';
import { EmailComponent } from './starter/email/email.component';
import { CourseSearchComponent } from './starter/course/course-search/course-search.component';
import { AccountsComponent } from './starter/Admin/accounts/accounts.component';
import { BranchesComponent } from './starter/Admin/branches/branches.component';
import { ViewLeadDetailsComponent } from './starter/view-lead-details/view-lead-details.component';
import { UsersComponent } from './starter/Admin/users/users.component';
import { CollegeDetailsComponent } from './starter/course/college-details/college-details.component';
import { AddCourseCollegeComponent } from './starter/Admin/add-course-college/add-course-college.component';
import { CourseListLandingComponent } from './starter/course/course-list-landing/course-list-landing.component';
import { AustraliaServiceMovementComponent } from './starter/LeadOffers/australia-service-movement/australia-service-movement.component';
import { CollegeListLandingComponent } from './starter/course/college-list-landing/college-list-landing.component';
import { InvoiceServiceChargeComponent } from './starter/Invoice/invoice-service-charge/invoice-service-charge.component';
import { AdminViewEditCourseComponent } from './starter/course/admin-view-edit-course/admin-view-edit-course.component';
import { TeamsLandingComponent } from './starter/Admin/teams/teams-landing/teams-landing.component';
import { WhatsappqrComponent } from './starter/whatsappqr/whatsappqr.component';
import { PTEAdminComponent } from './starter/pteadmin/pteadmin.component';
import { DownloadsComponent } from './starter/downloads/downloads.component';
import { RoleComponent } from './starter/Admin/role/role.component';
import { ApiForRoleComponent } from './starter/Admin/api-for-role/api-for-role.component';
import { PermissionForRoleComponent } from './starter/Admin/permission-for-role/permission-for-role.component';
import { GenerateReportsComponent } from './starter/Admin/generate-reports/generate-reports.component';
import { TaskSchedulerComponent } from './starter/task-scheduler/task-scheduler.component';
import { AccountInfoComponent } from './starter/admin/account-info/account-info.component';
import { CreateFormComponent } from './starter/Admin/create-form/create-form.component';
import { ApplicationsAppliedComponent } from './starter/course/applications-applied/applications-applied.component';
import { CommissionDashboardComponent } from './starter/course/commission-dashboard/commission-dashboard.component';
import { CalendarAppointmentComponent } from './starter/calendar-appointment/calendar-appointment.component';

const routes: Routes = [
  {
    path: '',
    component: StarterComponent,
    children: [
      {
        path: '',
        component: ContentComponent,
      },
      {
        path: 'services',
        component : ServicesComponent
      },
      {
        path: 'whatsappQr',
        component: WhatsappqrComponent
      },
      {
        path: 'email',
        component : EmailComponent
      },
      // {
      //   path: 'courses',
      //   component:CourseSearchComponent
      // },
      {
        path:'accounts',
        component : AccountsComponent
      },
      {
        path: 'branch',
        component: BranchesComponent
      },
      {
        path: 'details',
        component:ViewLeadDetailsComponent
      },
      {
          path: 'details/:id/:name',
          component:ViewLeadDetailsComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'collegeDetails',
        component: CollegeDetailsComponent
      },
      {
        path: 'addCollege',
        component: AddCourseCollegeComponent
      },
      {
        path: 'courseListLanding',
        component:CourseListLandingComponent
      },
      {
        path : 'collegeListLanding',
        component : CollegeListLandingComponent
      },
      {
        path: 'serviceCharges/:leadId',
        component : InvoiceServiceChargeComponent 
      },
      {
        path:'courses/:collegeId/:collegeName',
        component:AdminViewEditCourseComponent
      },
      {
        path:'teams',
        component:TeamsLandingComponent
      },
      { 
        path:'pteadmin',
        component: PTEAdminComponent
      },
      {
        path:'downloads',
        component: DownloadsComponent
      },
      {
        path:'role',
        component: RoleComponent
      },
      {
        path: 'api',
        component: ApiForRoleComponent
      },
      {
        path: 'permission',
        component: PermissionForRoleComponent
      },
      {
        path: 'dashboard',
        component: GenerateReportsComponent
      },
      {
        path: 'accountInfo',
        component: AccountInfoComponent
      },
      {
        path: 'taskboard',
        component: TaskSchedulerComponent
      },
      {
        path:'forms',
        component: CreateFormComponent
      },
      {
        path:'applicationsReceived',
        component: ApplicationsAppliedComponent
      },
      {
        path:'commissionDashboard',
        component: CommissionDashboardComponent
      },
      {
        path:'appointments',
        component: CalendarAppointmentComponent
      },
      {
        path: 'leadOfferService',
        loadChildren: () => import('./starter/LeadOffers/lead-offers.module').then(m => m.LeadOffersModule),
        
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StarterRoutingModule { }
