<div class="wrapper">

  <!-- Navbar -->
  <app-starter-nav-bar style="position: sticky;"></app-starter-nav-bar>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <app-starter-main-side-bar></app-starter-main-side-bar>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper" style="min-height: 100px;">
    <router-outlet></router-outlet>
    <!-- <div *ngIf="loadingRouteConfig" class="spinner-border text-primary"></div> -->
    <ng-container *ngIf="loadingRouteConfig">Loading route config...</ng-container>
  </div>
  <!-- /.content-wrapper -->

  <!-- Control Sidebar -->
  <app-starter-control-side-bar></app-starter-control-side-bar>
  <!-- /.control-sidebar -->

  <!-- Main Footer -->
  <!-- <app-starter-footer></app-starter-footer> -->
</div>
<!-- ./wrapper -->
