import { Route } from "@angular/compiler/src/core";
import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxPrintModule } from "ngx-print";
import { ToastrService } from "ngx-toastr";
import { email } from "src/app/Interface/email";
import { IGetLeads } from "src/app/Interface/IGetLeads";
import { IGetServices } from "src/app/Interface/IGetService";
import { ILead } from "src/app/Interface/ILead";
import { ILeadForm } from "src/app/Interface/ILeadForm";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import { MessagingService } from "src/app/Services/messagingService/messaging.service";
import { messageConstants } from "src/app/starter/starter/messages.constants";
import { InvoiceServiceChargeComponent } from "../Invoice/invoice-service-charge/invoice-service-charge.component";
declare var webkitSpeechRecognition: any;

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit {
  @ViewChild("notes") hiddenSearchHandler;
  @ViewChild("ChildComponent") childComponent: InvoiceServiceChargeComponent;
  viewLoader: boolean = false;
  viewLoaderAddLead: boolean = true;
  message: string = "";
  starId: number = 4;
  rating = 0;
  notes: string;

  //pagination variables
  take = 10;
  skip = -10;
  skipCounter = 0;
  //pagination variables end

  @ViewChild("EntryForm") EntryForm: NgForm;

  services: any;

  phoneNumber: string;

  post = new Date();

  comments: string[] = [
    "This is comment 1",
    "This is comment 2",
    "This is comment 3",
  ];

  leads: any[] = [];
  followUpDate: Date = new Date();

  //email object
  sendEmailObj: email;

  milestones: any;
  //variables for lead Detail for offer applied
  offerCurrentLeadName: string;
  OfferCurrentLeadDOB: string;
  offerCurrentLeadContact: string;
  OfferCurrentLeadEmail: string;
  offerCurrentLeadService: string;

  //Table row expand variables
  rowExpand = true;
  servicesAvailed = [];

  //branches
  branches: any;
  userRole: any;

  mileStoneId = "1";
  demo = [];
  tempLeads: any;
  commonFilterLeads: any;
  statusFilterLeads: any;
  countryFilterLeads: any;
  statusValue: string = "4";
  countryValue: string = "6";
  branchValue: string = localStorage.getItem('filterBranchValue')?localStorage.getItem('filterBranchValue'): localStorage.getItem('branchId');

  whatsappMessages:any;
  qrCodeToGenerate: any;
  showRequestAgainButton: boolean = false;
  showWhatsappQrTemplate: boolean = false;
  draftedLead: any;
  draftedLeadServiceName: string;
  canViewAllBranch: string = 'false';
  canDeleteLead: string = localStorage.getItem('canDeleteLead');

  invoiceDetails: any;
  showInvoiceDetails: boolean = false;

  isListening: boolean = false;
  vSearch:any;

  constructor(
    private _crmLeadService: CRMLeadServiceService,
    private _router: Router,
    private wApi : MessagingService,
    private _adminService : AdminService,
    private toastService: ToastrService,
    private el: ElementRef, 
    private renderer: Renderer2
  ) {
    //this.getLeads()
    this.nextPage();
  }

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.canViewAllBranch = localStorage.getItem("canViewAllBranch");
    if (this.canViewAllBranch == "true") {
      this.getBranches();
    }

    this._adminService.getServicesWithMilestones().subscribe(
      (response) => {
        this.services = response;
      },
      (error) => {
        localStorage.clear();
        this._router.navigate(["/"]);
      },
      () => {
        console.log("Services fetched successfully");
      }
    );
  }

  rowExpandFunc() {
    this.rowExpand = !this.rowExpand;
  }

  searchLeadByService(serviceSelected: string) {
    console.log("you selected" + serviceSelected);
  }

  searchLeadByStatus(statusSelected: string) {
    console.log("you selected" + statusSelected);
  }

  LeadStatus(ob) {
    console.log(ob.target.value);
  }

  followUp() {
    console.log("Follow up");
    let followupVap = confirm("Today follow has been taken ?");
    if (followupVap) {
      this.followUpDate = new Date();
    } else {
      console.log("false");
    }
  }

  delete() {
    console.log("Delete");
  }

  submitform(form: NgForm) {
    console.log("lead")
    this.viewLoaderAddLead = false;
    form.value.phoneNumber = this.phoneNumber;
    let newLead: any = {
      prefix: form.value.gender,
      emailId: form.value.email == null? "":form.value.email,
      firstName: form.value.firstName,
      lastName: form.value.lastName == null? "": form.value.lastName,
      dob: form.value.dob == null? "":form.value.dob,
      notes: form.value.notes == null? "":form.value.notes,
      chat: "New Lead",
      status: 1,
      marriageStatus: form.value.mariStatus,
      phoneNumber: form.value.phoneNumber == null? "": form.value.phoneNumber,
      rating: 2,
      bloodGroup: "o+",
      fatherName: "Not specified",
      motherName: "not specified",
      isSoftDelete: 0,
      leadAddresses: [
        {
          addressLine1: form.value.address == null? "": form.value.address,
          city: form.value.city == null? "": form.value.city, 
          state: form.value.state == null? "": form.value.state,
          pincode: form.value.pincode == null? "": form.value.pincode,
          addressType: 1,
          addressLine2: "not specified",
          isSoftDelete: false,
          country: form.value.countryLead,
          village: "not specified",
        },
      ],
      leadToService: [
        {
          serviceId: form.value.service,
          active: 1,
          isSoftDelete: false,
          serviceMilestoneId: this.services.find(x => x.id == form.value.service).serviceMilestone[0].id,
          milestoneSequenceNumber:1
        },
      ],
      passport: form.value.passportNumber,
      citizenship: form.value.countryOfCitizenship
    };
    this._crmLeadService.addLead(newLead).subscribe(
      (res) => {
        document.getElementById("closeButtonSubmitForm").click();
        this.toastService.success('Lead created successfully');
        this.nextPage()
      },
      (err) => {
        document.getElementById("closeButtonSubmitForm").click();
        if(err.status == 401 && err.statusText == "Unauthorized"){
          sessionStorage.setItem("leadDrafted",JSON.stringify(newLead))
          sessionStorage.setItem("serviceName", this.services.find(x => x.id == newLead.leadToService[0].serviceId).serviceName)
          alert("Please login again. Don't worry your lead will be drafted until this window remains open");
          this._router.navigate([''])
        }
        else{
          alert("Some issue occured please intimate to Admin")
        }
      },
      () => {
        
      }
    );
  }

  getNumber(obj) {
    this.phoneNumber = obj;
  }
  saveComment(form: NgForm) {
    this.comments.push(form.value.newComment);
    var commentField = document.getElementById("newComment") as HTMLElement;
    form.reset();
  }

  starClicked(lead: any, rating: number) {
    lead.rating = rating;
    this._crmLeadService.updateRating(lead.id, rating).subscribe(
      data =>{
        console.log(data)
      },
      err =>{
        console.log(err)
      },
      ()=>{
        console.log("Rating updated")
      }
    )
  }

  public voiceSearch() {
    if(!this.isListening){
      this.isListening = true;
      if ("webkitSpeechRecognition" in window) {
        this.toastService.info("Listening")
        this.vSearch = new webkitSpeechRecognition();
        this.vSearch.continuous = true;
        this.vSearch.interimresults = true;
        this.vSearch.lang = "en-US";
        this.vSearch.start();
        //const voiceSearchForm = this.formSearch.nativeElement;
        const voiceHandler = this.hiddenSearchHandler.nativeElement;
        //console.log(voiceSearchForm);
        this.vSearch.onresult = function (e) {
          // console.log(voiceSearchForm);
          for (var i = e.resultIndex; i < e.results.length; ++i) {
            if (e.results[i][0].transcript == "Stop here.") {
              this.vSearch.stop();
            } else {
              voiceHandler.value += " " + e.results[i][0].transcript;
            }
          }
          //vSearch.stop();
          console.log(voiceHandler);
          // voiceSearchForm.submit();
        };
  
        this.vSearch.onerror = function (e) {
          console.log(e);
          this.vSearch.stop();
        };
      } else {
        this.toastService.error("Unable to listen, some error occured")
      }
    }
    else{
      this.isListening = false;
      this.vSearch.stop();
      this.toastService.info("Listening stopped")
    }
  }

  sendEmailToUser(form: NgForm) {
    this.sendEmailObj = {
      from: "Themiigrators@gmail.com",
      to: "romitgoyal1998@gmail.com",
      subject: form.value.subject,
      text: form.value.text,
    };

    this._crmLeadService.sendEmailTOUser(this.sendEmailObj).subscribe(
      (res) => {
        alert(res);
        form.reset();
      },
      (err) => {
        alert(err);
      }
    );
  }

  setLeadOfferModalValues(lead: any) {
    this.OfferCurrentLeadDOB = lead.dob;
    this.offerCurrentLeadContact = lead.phoneNumber;
    this.offerCurrentLeadService = lead.leadToService[0].Service.serviceName;
    this.offerCurrentLeadName = lead.firstName + " " + lead.lastName;
    this.OfferCurrentLeadEmail = lead.emailId;
  }

  navigateToDetails(id: string, name: string) {
    this._router.navigate(["/user/details/" + id + "/" + name]);
  }

  nextPage() {
    this.draftedLead = JSON.parse(sessionStorage.getItem('leadDrafted'))
    this.draftedLeadServiceName = sessionStorage.getItem('serviceName')
    this.viewLoader = false;
    if (localStorage.getItem("filterBranchValue") != null) {
      this.getLeadsOnBranchForAdmin(
        localStorage.getItem("filterBranchValue")
      );
    }
    else{
      this._crmLeadService.getSingleLeadPagination(800, 0).subscribe(
        (response) => {
          this.leads = [];
          console.log(response)
          this.leads = response.lead[0];
          //this.getUnreadCount();
          this.leads.sort(function (a, b) {
            return b.id - a.id;
          });
          this.statusFilterLeads = this.leads;
          this.countryFilterLeads = this.leads;
          this.tempLeads = this.leads;
          this.commonFilterLeads = this.leads;
          if (localStorage.getItem("filterCountryValue") != null) {
            this.commonFilter(
              "country",
              localStorage.getItem("filterCountryValue")
            );
          }
          else{
            localStorage.setItem("filterCountryValue", "6")
          }
          if (localStorage.getItem("filterStatusValue") != null) {
            this.commonFilter(
              "status",
              localStorage.getItem("filterStatusValue")
            );
          }
          else{
            localStorage.setItem("filterStatusValue", "4")
          }
          console.log(this.leads);
          this.viewLoader = true;
        },
        (err) => {
          console.log(err);
          this.viewLoader = true;
        },
        () => {
          console.log("Successfull call ");
        }
      );
    }
  }

  //get services respective to the user ID show in the expand area
  getServicesPerUser(id: number) {
    this._crmLeadService.getLeadServices(id).subscribe(
      (res) => {
        this.servicesAvailed = [];
        for (var i = 0; i < res.length; i++) {
          console.log(res[i].Service);
          this.servicesAvailed.push(res[i].Service);
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        console.log("services fetched successfully");
      }
    );
  }
  //end services in expand area

  getBranches() {
    this._crmLeadService
      .getFlattenSubBranches()
      .subscribe(
        (data) => {
          console.log(data);
          this.branches = data.branch;
        },
        (err) => {
          console.log(err);
        },
        () => console.log("Brnaches fetches successfully")
      );
  }

  getLeadsOnBranchForAdmin(branchId: string) {
    localStorage.setItem("filterBranchValue", branchId);
    this.branchValue = localStorage.getItem('filterBranchValue');
    this._crmLeadService
      .getLeadsForAdminOnBranch(branchId)
      .subscribe((data) => {
        this.leads = data.lead[0];
        this.leads.sort(function (a, b) {
          return b.id - a.id;
        });
        this.statusFilterLeads = this.leads;
        this.countryFilterLeads = this.leads;
        this.tempLeads = this.leads;
        this.commonFilterLeads = this.leads;
        if (localStorage.getItem("filterCountryValue") != null) {
          this.commonFilter(
            "country",
            localStorage.getItem("filterCountryValue")
          );
        }
        else{
          localStorage.setItem("filterCountryValue", "6")
        }
        if (localStorage.getItem("filterStatusValue") != null) {
          this.commonFilter(
            "status",
            localStorage.getItem("filterStatusValue")
          );
        }
        else{
          localStorage.setItem("filterStatusValue", "4")
        }
      });
  }

  leadStatusUpdate(leadId: string, ob: any, userName: any) {
    this.viewLoader = false;
    try {
      this._crmLeadService.putLeadStatus(leadId, ob.target.value).subscribe(
        (data) => {
          this.toastService.success(`Lead status changed for ${userName}`);
          this.nextPage();
          this.viewLoader = true;
        },
        (err) => {
          console.log("Error occured");
          this.toastService.error("Some error occured");
          this.viewLoader = true;
        },
        () => {}
      );
    } catch (err) {
      console.log("some error occured!");
    }
  }

  leadMilestoneUpdate(
    leadToServiceId: string,
    milestoneSequenceNumber: any,
    userName: any,
    phoneNumber : any
  ) {
    this.viewLoader = false;
    var milestoneValue = milestoneSequenceNumber.target.value.split(" ");
    var msgId = "";
    var msg = messageConstants.common+" " + userName + " ";
    for(let i = 1; i < milestoneValue.length; i++){
      msgId += milestoneValue[i]
    }
    this._crmLeadService
      .putMilestones(leadToServiceId, milestoneValue[0],milestoneValue[1])
      .subscribe(
        (data) => {
          this.toastService.success(`Service status changed for ${userName}`);
        },
        (err) => {
          this.viewLoader = true;
          alert("Some error occured!");
        },
        () => {
          console.log("Update milestone successfull");
        }
      );
  }

  getMilestones(serviceId: string) {
    this._crmLeadService.getMilestones(serviceId).subscribe((data) => {
      console.log(data);
    });
  }

  commonFilter(filterName: string, filterValue) {
    if (filterName == "status") {
      localStorage.setItem("filterStatusValue", filterValue);
      this.statusValue = filterValue;
      this.countryFilterLeads = this.countryFilter(
        localStorage.getItem("filterCountryValue"),
        this.tempLeads
      );
      this.leads = this.statusFilter(this.statusValue, this.countryFilterLeads);
    } else {
      localStorage.setItem("filterCountryValue", filterValue);
      this.countryValue = filterValue;
      this.statusFilterLeads = this.statusFilter(
        localStorage.getItem("filterStatusValue"),
        this.tempLeads
      );
      this.leads = this.countryFilter(
        this.countryValue,
        this.statusFilterLeads
      );
    }
    this.commonFilterLeads = this.leads;
  }

  statusFilter(filterValue, filterLeads) {
    var tempLeadsValues;
    if (filterValue != 4) {
      tempLeadsValues = filterLeads.filter((lead) => {
        return lead.status == filterValue;
      });
      return tempLeadsValues;
    } else {
      return filterLeads;
    }
  }

  countryFilter(filterValue, filterLeads) {
    var tempLeadsValues;
    if (filterValue != 6) {
      tempLeadsValues = filterLeads.filter((lead) => {
        return lead.leadToService[0].Service.id == filterValue;
      });
      return tempLeadsValues;
    } else {
      return filterLeads;
    }
  }

  searchLeadOnNameMobileLeadId(leadsearch: string) {
    if (leadsearch.length > 0) {
      this.leads = this.commonFilterLeads.filter((lead) => {
        return (
          lead.firstName.toLowerCase().search(leadsearch.toLowerCase()) != -1 ||
          lead.phoneNumber.search(leadsearch) != -1 ||
          lead.id == leadsearch
        );
      });
    } else {
      this.leads = this.commonFilterLeads;
    }
  }

  sendWatsappMessage(number: string, msg: string) {
    let num = number.replace("+", "");
    this.wApi.sendWMessage(num, msg)
      .subscribe((data:any) => 
      {
        if(data?.message){
          this.message = "";
          this.getWatsappMsgOfContact(num);
        }
        else if(data?.qr){
          this.showWhatsappQrTemplate = true;
          this.showRequestAgainButton = false;
          this.qrCodeToGenerate = data.qr;
        }
        else if(data?.error){
          this.showRequestAgainButton = true;
          this.showWhatsappQrTemplate = false;
        }
      },
      err => {
        this.message = ""
      });
  }

  getWatsappMessage(text : string){
    switch (text){
      case "leadRegistered2":
        return messageConstants.leadRegistered2
        break
      case "VerificationCompleted2": 
        return messageConstants.VerificationCompleted2
      case "OfferLetterApplied2":
        return messageConstants.OfferLetterApplied2
      case "OfferLetterReceived2":
        return messageConstants.OfferLetterReceived2
      case "TuitionFeePaid2":
        return messageConstants.TuitionFeePaid2
      case "MedicalExaminationAttended2":
        return messageConstants.MedicalExaminationAttended2 
      case "GICPaid2":
        return messageConstants.GICPaid2
      case "VisaSubmitted2":
        return messageConstants.VisaSubmitted2
      case "BiometricAttended2":
        return messageConstants.BiometricAttended2
      case "VisaOutcomeRecieved2":
        return messageConstants.VisaOutcomeRecieved2
      case "VerificationCompleted3":
        return messageConstants.VerificationCompleted3
      case "OfferLetterApplied3":
        return messageConstants.OfferLetterApplied3
      case "OfferLetterReceived3":
        return messageConstants.OfferLetterReceived3
      case "TuitionFeePaid3":
        return messageConstants.TuitionFeePaid3
      case "MedicalExaminationAttended3": 
        return messageConstants.MedicalExaminationAttended3 
      case "GTEDocumentSubmitted3":
        return messageConstants.GTEDocumentSubmitted3
      case "GTEApproved3":
        return messageConstants.GTEApproved3
      case "TutionFeePaid3":
        return messageConstants.TuitionFeePaid3
      case "OSHCPaid3":
        return messageConstants.OSHCPaid3
      case "COEReceived3":
        return messageConstants.COEReceived3
      case "VisaSubmitted3":
        return messageConstants.VisaSubmitted3
      case "VisaOutcomeRecieved3":
        return messageConstants.VisaOutcomeRecieved3
    }
  }

  getWatsappMsgOfContact(phoneNumber){
    this.whatsappMessages = [];
    let num = phoneNumber.replace("+", "")
    this.wApi.getWMessages(num).subscribe(
      data => {
        if(data?.messages){
          this.showRequestAgainButton = false;
          this.showWhatsappQrTemplate = false;
          this.whatsappMessages = data.messages
        }
        else if(data?.qr){
          this.showWhatsappQrTemplate = true;
          this.showRequestAgainButton = false;
          this.qrCodeToGenerate = data.qr;
        }
        else if(data?.error){
          this.showRequestAgainButton = true;
          this.showWhatsappQrTemplate = false;
        }
      }
    )
  }

  // getUnreadCount(){
  //   let reqArr = []
  //   this.leads.forEach(x => {
  //     reqArr.push({phoneNumber : x.phoneNumber.replace("+", ""), leadId: x.id})
  //   })
  //   console.log(reqArr)
  //   this.wApi.getUnreadMessages(reqArr).subscribe(
  //     data =>{
  //       data.message.forEach(da => {
  //         this.leads.find(lead => lead.id == da.leadId).unreadCount = da.unread
  //       })
  //     }
  //   )
  // }

  deleteLead(leadId : string){
    let confirmRes = confirm("Are you sure you want to delete the lead?")
    if(confirmRes){
      this._adminService.commonUpdateLead(leadId, {
        isSoftDelete : 1
      }).subscribe(
        data => {
          this.toastService.error('Lead removed successfully');
          console.log(data)
          this.nextPage()
        },
        err => {
          console.log("some error occured")
        },
        () =>{
          console.log("Lead successfully deleted")
        }
      )
    }
    else{

    }
  }

  createDraftedLead(){
    let newLead :any = JSON.parse(sessionStorage.getItem('leadDrafted'))
    this._crmLeadService.addLead(newLead).subscribe(
      (res) => {
        //document.getElementById("closeButtonSubmitForm").click();
        sessionStorage.clear()
        this.draftedLead = null
        this.nextPage()
      },
      (err) => {
        console.log(err)
        if(err.status == 401 && err.statusText == "Unauthorized"){
          sessionStorage.setItem("leadDrafted",JSON.stringify(newLead))
          sessionStorage.setItem("serviceName", this.services.find(x => x.id == newLead.leadToService[0].serviceId).serviceName)
          alert("Please login again. Don't worry your lead will be drafted until this window remains open");
          this._router.navigate([''])
        }
        else{
          alert("Some other error occured please contact admin.")
        }
      },
      () => {
        
      }
    );
  }

  deleteDraftedLead(){
    sessionStorage.clear()
    this.draftedLead = null
  }

  isDragging = false;
  offsetX = 0;
  offsetY = 0;

  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.offsetX = event.clientX - this.el.nativeElement.getBoundingClientRect().left;
    this.offsetY = event.clientY - this.el.nativeElement.getBoundingClientRect().top;
  }

  onDrag(event: MouseEvent) {
    if (!this.isDragging) return;
    const x = event.clientX - this.offsetX;
    const y = event.clientY - this.offsetY;
    this.renderer.setStyle(this.el.nativeElement, 'left', x + 'px');
    this.renderer.setStyle(this.el.nativeElement, 'top', y + 'px');
  }

  onDragEnd() {
    this.isDragging = false;
  }

  setInvoiceData(lead){
    this.invoiceDetails = {
      leadToServiceId : lead.leadToService[0].id
    }
    this.showInvoiceDetails = true;
    this.childComponent?.handleChange(this.invoiceDetails);
  }
}