<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="sticky-top">
      <div class="card shadow" style="border-radius: 10px">
        <div class="card-header border-0">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">Teams</h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <div
                    class="btn-group btn-group-sm"
                    role="group"
                    id="div_ticketactivityperiod"
                  >
                    <h6 class="mb-0 my-auto">Branch</h6>
                    <select
                      (change)="getTeamsOnBranch($event)"
                      class="searchSelect my-auto"
                      #branch
                      name="branch"
                    >
                      <option
                        *ngFor="let branch of branches"
                        value="{{ branch.id }}"
                      >
                        {{ branch.branchName }}
                      </option>
                    </select>
                  </div>
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#launchAccountModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Team
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Team Name</th>
            <th scope="col">Branch Name</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody id="accordion">
          <ng-container *ngFor="let team of teams; let i = index">
            <tr
              (click)="getTeamIdForSelectedTeam(team.id)"
              data-toggle="collapse"
              [attr.data-target]="'#' + 'ab' + i"
            >
              <td scope="row">{{ i + 1 }}</td>
              <td>{{ team.teamName }}</td>
              <td>{{ team.branch.branchName }}</td>
              <td><i class="fas fa-angle-down mr-2"></i></td>
            </tr>
            <tr
              [id]="'ab' + i"
              class="collapse cell-1 row-child"
              data-parent="#accordion"
            >
              <td colspan="4">
                <div class="row">
                  <div class="card col-6">
                    <div class="card-header">Users</div>
                    <div class="card-body">
                      <div *ngFor="let user of teamUsers; let i = index">
                        <div class="custom-control custom-switch row">
                          <div class="col-12">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="user.isAddedToTeam"
                              [name]="i"
                              [id]="'user' + i"
                              (click)="
                                addUserToTeam(user.id, user.isAddedToTeam)
                              "
                            />
                            <label
                              class="custom-control-label"
                              [for]="'user' + i"
                              >{{  user.accountUser.userEmail }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card col-6">
                    <div class="card-header">Services</div>
                    <div class="card-body">
                      <div *ngFor="let service of teamServices; let j = index">
                        <div class="custom-control custom-switch row">
                          <div class="col-12">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [checked]="service.isAddedToTeam"
                              [name]="'service' + j"
                              [id]="'service' + j"
                              (click)="
                                addServiceToTeam(
                                  service.id,
                                  service.isAddedToTeam
                                )
                              "
                            />
                            <label
                              class="custom-control-label"
                              [for]="'service' + j"
                              >{{ service.serviceName }}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!-- User post Modal started -->
  <div
    class="modal fade"
    id="launchAccountModal"
    style="padding-right: 5px"
    role="dialog"
    aria-labelledby="launchOfferModalTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-side modal-top-right"
      role="navigation"
    >
      <div id="print-section" class="modal-content">
        <div class="modal-header">
          <h5>New Team details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitTeam="ngForm" (submit)="createTeam(submitTeam)">
            <div class="row">
              <div class="col-1">
                <label for="textName" class="mx-auto my-auto">Team name</label>
              </div>
              <div class="form-group col-5">
                <!-- <label for="teamName">Team Name</label> -->
                <input
                  autocomplete="off"
                  type="text"
                  name="teamName"
                  class="form-control input-sm"
                  ngModel
                  required
                />
              </div>
              <div class="col-1">
                <label for="textName" class="mx-auto my-auto"
                  >Branch Name</label
                >
              </div>
              <div class="col-5">
                <!-- <label for="teamName">Team Name</label> -->
                <div class="form-group">
                  <select class="form-control" name="branchId" #branch ngModel>
                    <option
                      *ngFor="let branch of branches"
                      value="{{ branch.id }}"
                    >
                      {{ branch.branchName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <button class="btn btn-primary ml-auto mr-auto" type="submit">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</html>
