import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private apiLink: string = environment.production;
  
  constructor(private http: HttpClient) { }

  getCalendarEvents(){
    return this.http.get(this.apiLink+'appointment?start=2023-10-30T10:42:42Z&end=2023-10-30T11:41:42Z').pipe(catchError(this.errorHandler));
  }

  
  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || "Server Error");
  } 
}
