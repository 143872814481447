<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="card col-7">
                <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia distinctio qui eum repudiandae, excepturi cumque, repellendus doloremque incidunt saepe, voluptatem quos quae quo numquam? Veniam et ipsa debitis nulla officiis.
                    Veritatis praesentium cumque fugiat, dolore, debitis numquam quae qui possimus consequuntur soluta dignissimos tempora libero incidunt ipsum cupiditate iste. Culpa voluptate optio placeat neque ab unde hic eius eos maiores.
                    Ex, similique molestiae fugit aperiam sed ratione distinctio! Eveniet, sed sequi? Sit illum, harum reiciendis aliquam dolore, similique maxime ad laborum cum dolorum voluptates necessitatibus doloribus deleniti officia aperiam eveniet!
                </div>
                <div class="card-footer">
                    <label for="Course">Course</label>
                    <br/>
                    <label for="collegeName">CollegeName</label>
                </div>
            </div>
            <!-- <span class="col-1"></span> -->
            <div class="col-4">
                <div class="card">
                   <div class="card-header">
                       About this program
                   </div>
                   <div class="card-body">
                       <label for="level">Level:</label>
                       <br/>
                       <label for="level">Discipline:</label>
                       <br/>
                       <label for="level">Length:</label>
                   </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <label for="level">Application Fee:</label>
                        <br/>
                        <label for="level">Tuition Fee:</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="card col-7">
                <div class="card-header">
                    Program Description
                </div>
                <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere voluptatum eum animi error aut esse cum quas laudantium inventore magni placeat tempora fugiat, repudiandae veritatis itaque natus nobis perferendis aliquid.
                </div>
            </div>
            <!-- <span class="col-1"></span> -->
            <div class="col-4">
                <div class="card">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum voluptatem iure laboriosam officia, sequi velit nostrum facilis rem a, obcaecati id nisi! Perferendis ipsum libero architecto quaerat maxime. Eligendi, obcaecati.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas fugit obcaecati aspernatur ratione qui, nesciunt alias. Obcaecati maxime numquam at aspernatur reprehenderit quam, facilis earum voluptatem blanditiis. Ullam, assumenda repellendus?
                </div>
            </div>
        </div>

        <div class="row">
            <div class="card col-7">
                <div class="card-header">
                    Study and work in _______
                </div>
                <div class="card-body">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex magnam ipsam, voluptates velit minus quos cupiditate quasi incidunt mollitia iure illo obcaecati eum, commodi nisi quam ullam dolorem possimus sit?
                    Iste quo impedit et natus provident! Accusamus, eligendi dolorem facilis ullam ea, impedit architecto aliquam dolor quo minus distinctio vitae illo. Consequuntur assumenda voluptas omnis cupiditate. Quas, neque! Labore, reprehenderit.
                </div>
            </div>
            <!-- <span class="col-1"></span> -->
        </div>
    </div>
</body>
</html>