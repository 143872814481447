<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container-fluid">
      <div class="card parentCard">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">Tasks</h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#launchOfferModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Task
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row rowCards">
            <div
              class="col-3 colBorder"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event, '1')"
            >
              <h2 class="headerAssigned sticky-top">Assigned Tasks</h2>
              <div
                draggable="true"
                class="card cardAssigned shadow taskCard"
                class="card cardAssigned shadow taskCard"
                *ngFor="let task of assignedTasks; let i = index"
                (dragstart)="onDragStart(task)"
                (click)="onDragStart(task)"
                data-toggle="modal"
                data-target="#taskModal"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="row">
                  <div class="col-11">
                    <p>{{ task.taskHeading | slice : 0 : 200 }}</p>
                  </div>
                  <div class="col-1">
                    <i class="fas fa-clock mr-2" data-toggle="tooltip" data-placement="top" [title]="task?.createdDate | date"></i>                   
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="assignedTo" class="cardLabel"
                      >Created By:</label
                    >
                    <p name="assignedTo" class="cardP">
                      {{ task.createdBy.firstName }}
                    </p>
                  </div>
                  <div class="col-6">
                    <label for="reportedTo" class="cardLabel"
                      >Reported To:</label
                    >
                    <p name="reportedTo" class="cardP">
                      {{ task.reporter.firstName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-3 colBorder"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event, '2')"
            >
              <h2 class="headerAssigned sticky-top">In Progress Tasks</h2>
              <div
                draggable="true"
                class="card cardAssigned shadow taskCard"
                *ngFor="let task of workInTasks; let i = index"
                (dragstart)="onDragStart(task)"
                (click)="onDragStart(task)"
                data-toggle="modal"
                data-target="#taskModal"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="row">
                  <div class="col-11">
                    <p>{{ task.taskHeading | slice : 0 : 200 }}</p>
                  </div>
                  <div class="col-1">
                    <i class="fas fa-clock mr-2" data-toggle="tooltip" data-placement="top" [title]="task?.createdDate | date"></i>                   
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="assignedTo" class="cardLabel"
                      >Created By:</label
                    >
                    <p name="assignedTo" class="cardP">
                      {{ task.createdBy.firstName }}
                    </p>
                  </div>
                  <div class="col-6">
                    <label for="reportedTo" class="cardLabel"
                      >Reported To:</label
                    >
                    <p name="reportedTo" class="cardP">
                      {{ task.reporter.firstName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-3 colBorder"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event, '3')"
            >
              <h2 class="headerAssigned sticky-top">Completed Tasks</h2>
              <div
                draggable="true"
                class="card cardAssigned shadow taskCard"
                *ngFor="let task of completedTasks; let i = index"
                (dragstart)="onDragStart(task)"
                (click)="onDragStart(task)"
                data-toggle="modal"
                data-target="#taskModal"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="row">
                  <div class="col-11">
                    <p>{{ task.taskHeading | slice : 0 : 200 }}</p>
                  </div>
                  <div class="col-1">
                    <i class="fas fa-clock mr-2" data-toggle="tooltip" data-placement="top" [title]="task?.createdDate | date"></i>                   
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="assignedTo" class="cardLabel"
                      >Created By:</label
                    >
                    <p name="assignedTo" class="cardP">
                      {{ task.createdBy.firstName }}
                    </p>
                  </div>
                  <div class="col-6">
                    <label for="reportedTo" class="cardLabel"
                      >Reported To:</label
                    >
                    <p name="reportedTo" class="cardP">
                      {{ task.reporter.firstName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-3"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event, '4')"
            >
              <h2 class="headerAssigned sticky-top">Accepted Tasks</h2>
              <div
                draggable="true"
                class="card cardAssigned shadow taskCard"
                *ngFor="let task of acceptedTasks; let i = index"
                (dragstart)="onDragStart(task)"
                (click)="onDragStart(task)"
                data-toggle="modal"
                data-target="#taskModal"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="row">
                  <div class="col-10">
                    <p>{{ task.taskHeading | slice : 0 : 200 }}</p>
                  </div>
                  <div class="col-1">
                      <i class="fas fa-clock mr-2" data-toggle="tooltip" data-placement="top" [title]="task?.createdDate | date"></i>                   
                  </div>
                  <div class="col-1">
                    <i class="fas fa-minus-circle mr-2" (click)="disappearTask($event, task.id)" data-toggle="tooltip" data-placement="top" title="close"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="assignedTo" class="cardLabel"
                      >Created By:</label
                    >
                    <p name="assignedTo" class="cardP">
                      {{ task.createdBy.firstName }}
                    </p>
                  </div>
                  <div class="col-6">
                    <label for="reportedTo" class="cardLabel"
                      >Reported To:</label
                    >
                    <p name="reportedTo" class="cardP">
                      {{ task.reporter.firstName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>

<div
  class="modal fade"
  id="launchOfferModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>New Task details</h5>
          <button
            type="button"
            class="close"
            id="closeTaskAddModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitTask="ngForm" (submit)="createTask(submitTask)">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Name">Header</label>
                <input
                  autocomplete="off"
                  type="text"
                  name="taskHeading"
                  class="form-control"
                  placeholder="Task header"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label for="email">User</label>
                <select
                  class="form-control"
                  name="userAssignedId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option
                    *ngFor="let user of users?.allAccountUsers"
                    [value]="user?.id"
                  >
                    --- {{ user?.accountUser.firstName }} ---
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="email">Reporter</label>
                <select
                  class="form-control"
                  name="reporterId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option
                    *ngFor="let user of users?.allAccountUsers"
                    [value]="user?.id"
                  >
                    --- {{ user?.accountUser.firstName }} ---
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="email">Co-Assign user</label>
                <select
                  class="form-control"
                  name="coAssignedUserId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option
                    *ngFor="let user of users?.allAccountUsers"
                    [value]="user?.id"
                  >
                    --- {{ user?.accountUser.firstName }} ---
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="Name">Start Date</label>
                <input
                  autocomplete="off"
                  type="date"
                  name="startDate"
                  class="form-control"
                  ngModel
                />
              </div>
              <div class="form-group col-md-3">
                <label for="email">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="endDate"
                  aria-hidden="true"
                  ngModel
                />
              </div>
              <div class="form-group col-md-3">
                <label for="email">Estimated Time</label>
                <input
                  type="number"
                  class="form-control"
                  name="estimatedTime"
                  aria-hidden="true"
                  ngModel
                />
              </div>

              <div class="form-group col-md-2">
                <label for="email">Status</label>
                <select
                  class="form-control"
                  name="statusId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option value="1">--- Assigned ---</option>
                  <option value="2">--- In Progress ---</option>
                  <option value="3">--- Completed ---</option>
                  <option value="4">--- Accepted ---</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="Name">Description</label>
                <textarea
                  class="form-control"
                  name="taskDescription"
                  rows="10"
                  ngModel
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary ml-auto mr-auto" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="taskModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="navigation">
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>Task details</h5>
          <button
            type="button"
            class="close"
            id="closeTaskAddModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="Name">Header</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.taskHeading"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="email">User</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.userAssigned?.firstName"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="email">Reporter</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.reporter?.firstName"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="email">Co-Assign user</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.coAssignedUser?.firstName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="email">Created Date</label>
              <input
                type="text"
                disabled
                [value]="currentTask?.createdDate | date"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="Name">Start Date</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.startDate | date"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="email">End Date</label>
              <input
                type="text"
                disabled
                [value]="currentTask?.endDate | date"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-2">
              <label for="email">Estimated Time</label>
              <input
                type="text"
                class="form-control"
                disabled
                [value]="currentTask?.estimatedTime"
              />
            </div>

            <div class="form-group col-md-2">
              <label for="email">Status</label>
              <input
                autocomplete="off"
                type="text"
                class="form-control"
                disabled
                value="{{
                  currentTask?.statusId == '1'
                    ? 'Assigned'
                    : currentTask?.statusId == '2'
                    ? 'In Progress'
                    : currentTask?.statusId == '3'
                    ? 'Completed'
                    : 'Accepted'
                }}"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="Name">Description</label>
              <textarea
                class="form-control"
                rows="10"
                [value]="currentTask?.taskDescription"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
