import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  setShowEmailFlag = false;
  setSendEmailFlag = false;

  from =""
  to = ""
  cC=""
  subject=""
  text=`
  Hello Romit,

  This is the test Email.

  Regards,
  Romit
  Hello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  RomitHello Romit,

  This is the test Email.

  Regards,
  Romit  `

  emails = [
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    },
    {
      from:"romitgoyal@gmail.com",
      subject :" enquiry"
    }
  ]

  constructor(private _crmLeadService:CRMLeadServiceService) { }

  ngOnInit(): void {
  }
  sendEmailToUser(form : NgForm){
    const sendEmailObj = {
      from : "Themiigrators@gmail.com",
      to : "romitgoyal1998@gmail.com",
      subject : form.value.subject,
      text : form.value.text
    }

    this._crmLeadService.sendEmailTOUser(sendEmailObj).subscribe(
      res =>{
        alert(res);
        form.reset();
      },
      err=>{
        alert(err);
      }
    )
  }

  showEmail(email : any){
    this.setShowEmailFlag = true
    this.setSendEmailFlag = false
    this.from = email.from
    this.subject = email.subject
  }


  newEmail(){
    this.setSendEmailFlag = true
    this.setShowEmailFlag = false
  }
}
