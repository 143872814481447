<!-- Start of KONPARE Widget script -->
<script
  id="konpare-snippet"
  src="https://konpare.online/konpare-widget/js/konpare_widget.js?key=1WEbZaG4B4k="
></script>
<!-- End of KONPARE Widget script -->

<script src="https://use.fontawesome.com/d3ca660215.js"></script>
<div class="container-fluid px-4 py-4 rounded">
  <div class="card shadow" style="border-radius: 10px">
    <div class="card-header border-0">
      <div class="row">
        <div class="col">
          <h5 class="d-inline-block align-middle my-0 mr-3">Services</h5>
        </div>
        <div class="col-auto">
          <div class="form-row">
            <!-- ngIf: currentstatus == 0 -->
            <div class="col-auto">
              <button
                class="btn btn-sm btn-outline-primary"
                type="button"
                data-toggle="modal"
                data-target="#sortingModal"
                id="addService"
                data-backdrop="static"
                data-keyboard="false"
              >Sort services
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-sm btn-outline-primary"
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLong"
                id="addService"
                data-backdrop="static"
                data-keyboard="false"
              >
                <i class="fas fa-plus mr-2"></i>Service
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row px-3">
        <div
          class="card bg-light card__one mx-auto"
          style="width: 14rem"
          *ngFor="let service of services; let i = index"
        >
          <div class="card-body">
            <h5 class="card-title">Service {{ i + 1 }}</h5>
            <br />
            <p class="card-text">
              <strong>Name :</strong> {{ service.serviceName }}
              <!-- <br /> <strong>Charges :</strong> {{service.charges}} -->
            </p>
            <!-- //id="MiniHorzNav" -->

            <ul>
              <li *ngFor="let milestone of service.serviceMilestone">
                {{ milestone.milestoneName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="exampleModalLong"
  style="padding-right: 5px"
  data-modal-width="99vw"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Service</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="EntryForm.resetForm()"
          aria-label="Close"
          (click)="emptyServiceForm()"
          id="serviceModalClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #EntryForm="ngForm" autocomplete="off">
          <div class="card shadow py-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col-3">
                  <label for="sName">Service Name :</label>
                </div>
                <div class="col-9" style="width: 100%">
                  <input
                    class="input-sm"
                    name="sName"
                    style="width: 100%"
                    id="sName"
                    [(ngModel)]="serviceName"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label for="sName">Service Charges :</label>
                </div>
                <div class="col-9" style="width: 100%">
                  <input
                    class="input-sm"
                    name="charges"
                    style="width: 100%"
                    id="sName"
                    [(ngModel)]="serviceCharges"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label for="sName">Service Milestone :</label>
                </div>
                <div class="col-8" style="width: 100%">
                  <input
                    class="input-sm"
                    name="mileStone"
                    style="width: 100%"
                    id="sName"
                    [(ngModel)]="milestoneValue"
                    type="text"
                  />
                  <p class="intimationMilestones">
                    One milestone is necessary, Please enter milestone and click
                    '+' icon
                  </p>
                </div>
                <div class="col-1">
                  <button
                    [disabled]="milestoneValue == ''"
                    class="btn btn-primary btn-sm"
                    (click)="addMilestoneToServiceArray()"
                    type="button"
                  >
                    <i class="fas fa-plus mr-2"></i>
                  </button>
                </div>
              </div>

              <br />
              <br />
              <div
                class="row"
                *ngFor="let mStone of milestonesAdded; let i = index"
              >
                <div class="col-3">
                  <label for="sName">Service Milestone {{ i + 1 }} :</label>
                </div>
                <div class="col-8" style="width: 100%">
                  <input
                    class="input-sm"
                    [name]="'mileStone' + i + 1"
                    style="width: 100%"
                    #mileStoneVlaue
                    [ngModel]="milestonesAdded[i]"
                    type="text"
                  />
                </div>
                <div class="col-1">
                  <button
                    (click)="changedMilestone(mileStoneVlaue.value, i)"
                    class="btn btn-primary btn-sm"
                    type="button"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <button
              [disabled]="this.milestonesAdded?.length == 0"
              type="submit"
              class="btn btn-primary text-center ml-auto mr-auto"
              (click)="submitform()"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="sortingModal"
  style="padding-right: 5px"
  data-modal-width="99vw"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Sort services</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="EntryForm.resetForm()"
          aria-label="Close"
          (click)="emptyServiceForm()"
          id="serviceModalClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li class="listItemDraggable card rounded" *ngFor="let service of services; let i = index" draggable="true" (dragend)="dragEnd($event)" (dragover)="dragOver($event)" (dragstart)="dragStart($event)">{{service.serviceName}}</li>
      </ul>
      <div class="row">
        <div class="col-auto mx-auto">
          <button class="btn btn-success">Save</button>
        </div>
      </div>
      </div>
</div>
</div>
</div>
