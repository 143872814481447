import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { Country, State, City }  from 'country-state-city';

@Component({
  selector: 'app-college-list-landing',
  templateUrl: './college-list-landing.component.html',
  styleUrls: ['./college-list-landing.component.scss']
})
export class CollegeListLandingComponent implements OnInit {

  viewLoader : boolean = true;
  viewLoader2 : boolean = true;
  First : any
  searchedColleges = []
  Colleges:any = [];
  allCountries = Country.getAllCountries();
  allStates: any = [];
  allCities: any = [];
  countryCode: string;
  city: string;
  state: string;
  country:string;

  ngOnInit(): void {
    this.searchedColleges = this.Colleges;
    this.getCollege()
  }


  constructor(private leadApi : CRMLeadServiceService) { 
    this.viewLoader = false
    console.log(this.allCountries)
  }

  getAllState(countryCode){
    let splited = countryCode.split(',')

    this.countryCode = splited[0];
    this.country = splited[1];
    this.allStates = State.getStatesOfCountry(this.countryCode)
  }

  getAllCity(stateCode){
    let splited = stateCode.split(',');
    this.state = splited[1];
    this.allCities = City.getCitiesOfState(this.countryCode,splited[0])
  }
  // Search(){
  //   if(this.First == ""){
  //     this.ngOnInit()
  //   }
  //   else{
  //     this.searchedColleges = this.Colleges.filter(
  //       res => {
  //         return res.toLocaleLowerCase().match(this.First.toLocaleLowerCase()); 
  //       }
  //     )

  //   }
  // }

  createUser(form : NgForm){
    console.log(form)
  }

  createCollege(form: NgForm){
    this.viewLoader = false;
    let obj = {
      name : form.value.name,
      type: parseInt(form.value.type),
      description : form.value.description,
      collegeState : this.state,
      collegeCity : form.value.city,
      collegeCountry : this.country
    }
    this.leadApi.postCollege(obj).subscribe(
      data => {
        console.log(data)
        alert("New college added successfully!");
        this.viewLoader = true;
        form.reset();
        document.getElementById("closeButton").click();
      },
      err => {
        alert(err)
        this.viewLoader = true;
      },
      () =>{
        console.log("College added successfully")
        this.getCollege()
      }
    )
  }

  getCollege(){
    this.viewLoader2 = false
    this.leadApi.getCollege().subscribe(
      data =>{
        this.Colleges = data
        console.log(data)
        this.viewLoader2 = true
      },
      err =>{
        //alert("Some error occured login again")
      },
      () =>{
        console.log("College fetched successfully")
      }
    )
  }
}
