import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/Services/adminService/admin.service';

@Component({
  selector: 'app-api-for-role',
  templateUrl: './api-for-role.component.html',
  styleUrls: ['./api-for-role.component.scss']
})
export class ApiForRoleComponent implements OnInit, OnChanges {

    // Indicator to get all the roles availble i.e. access levels
    apis:any;

    constructor(private _adminService : AdminService) { }
  
    ngOnInit(): void {
      this.getApis();
    }

    ngOnChanges(): void{
      this.getApis();
    }
  
    getApis(){
      this._adminService.getApis().subscribe(
        (data : any)=>{
          this.apis = data;
          console.log(data)
        }
      )
    }

    createAPI(form:NgForm){
      this._adminService.postApis(form.value).subscribe(
        data =>{
          console.log(data)
        }
      )
    }
}
