<!-- <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>


<section>
    <div class="container-fluid px-4 py-2 rounded">
        <div class="card shadow" style="border-radius: 10px;">
            <div class="card-header border-0">
                <div class="row">
                    <div class="col">
                        <h5 class="d-inline-block align-middle my-0 mr-3 ">
                            EMail
                        </h5>
                    </div>
                    <div class="col-auto">
                        <div class="form-row">
                           
                            <div class="col-auto">
                                <div class="controls">
                                    <button class="btn btn-outline-primary">Inbox</button>
                                    <button class="btn btn-outline-primary">Sent</button>
                                    <button (click)="newEmail()" class="btn btn-outline-primary">New <i class="fas fa-plus mr-2"></i></button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="row rowMain">
                    <div class="col-2 mailsArea">

                        <table class="table table-hover">
                            <tbody>
                                <tr *ngFor="let mail of emails" (click)="showEmail(mail)">
                                    <td>
                                        {{mail.from}}
                                        <br />
                                        {{mail.subject}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-10 mailOpenArea">
                        <form *ngIf="setSendEmailFlag" #sendEmailForm="ngForm" (ngSubmit)="sendEmailToUser(sendEmailForm)">
                            <div class="input-group mb-3 ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="">To</span>
                                </div>
                                <input type="text" class="form-control ml-auto" placeholder="" aria-label=""
                                    aria-describedby="basic-addon1">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="">Subject</span>
                                </div>
                                <input type="text" class="form-control ml-auto" placeholder="" aria-label=""
                                    aria-describedby="basic-addon1">
                            </div>
                                <textarea text-angular id="text" name="text"></textarea>
                            <button class="btn btn-primary" type="submit">Send <i
                                    class="fab fa-telegram-plane fa-xl ml-3"></i></button>
                        </form>

                        <p  *ngIf="setShowEmailFlag">
                            <strong>From: </strong> {{from}}
                            <br/>
                            <br/>
                            <strong>To: </strong>
                            <br/>
                            <br/>
                            <strong>CC: </strong>
                            <br/>
                            <br/>
                            <strong>Subject: </strong>{{subject}}
                            <br/>
                            <br/>
                            <textarea id="text" name="text" disabled value="{{text}}"></textarea>
                        </p>

                    </div>
                    
                </div>
            </div>
            
        </div>
    </div>
</section>




</html> -->

<iframe src="https://email.aplifast.com/" frameborder="0" height="100%" width="100%" ></iframe>