<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container-fluid px-4 pt-4 rounded">
      <div class="row">
        <div class="col-4 mx-auto">
          <div class="card shadow" style="border-radius: 10px">
            <div class="card-header border-0">
                Australia
            </div>
            <div class="card-body">
                <button class="btn btn-primary">
                    Inquiry Form
                    <i class="fas fa-cloud-download-alt mr-2"></i>
                </button>
                <button class="btn btn-primary">
                    Visa process
                    <i class="fas fa-cloud-download-alt mr-2"></i>
                </button>
                <button class="btn btn-primary">
                    Dependent visa checklist
                    <i class="fas fa-cloud-download-alt mr-2"></i>
                </button>
                <button class="btn btn-primary">
                    Student visa checklist
                    <i class="fas fa-cloud-download-alt mr-2"></i>
                </button>
                <button class="btn btn-primary">
                    Visitor visa checklist
                    <i class="fas fa-cloud-download-alt mr-2"></i>
                </button>
            </div>
          </div>
        </div>
        <div class="col-4 mx-auto">
            <div class="card shadow" style="border-radius: 10px">
              <div class="card-header border-0">
                  Canada
              </div>
              <div class="card-body">
                  <button class="btn btn-primary">
                      Inquiry Form
                      <i class="fas fa-cloud-download-alt mr-2"></i>
                  </button>
                  <button class="btn btn-primary">
                      Visa process
                      <i class="fas fa-cloud-download-alt mr-2"></i>
                  </button>
                  <button class="btn btn-primary">
                      Dependent visa checklist
                      <i class="fas fa-cloud-download-alt mr-2"></i>
                  </button>
                  <button class="btn btn-primary">
                      Student visa checklist
                      <i class="fas fa-cloud-download-alt mr-2"></i>
                  </button>
                  <button class="btn btn-primary">
                      Visitor visa checklist
                      <i class="fas fa-cloud-download-alt mr-2"></i>
                  </button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </body>
</html>
