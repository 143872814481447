<div class="row">
    <div class="col-auto mx-auto">
        <div class="carding">
            <ngx-qrcode
            [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel"
            [value]="qrCodeToGenerate"
            cssClass="qrcodeshadow"></ngx-qrcode>   
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-auto mx-auto">
        <button class="btn btn-primary" (click)="getNewQr()">Click to reset session</button>
    </div>
</div> -->
