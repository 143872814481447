import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { StarterRoutingModule } from "./starter-routing.module";
import { StarterComponent } from "./starter/starter.component";
import { ContentComponent } from "./starter/content/content.component";
import { ControlSideBarComponent } from "./starter/control-side-bar/control-side-bar.component";
import { MainSideBarComponent } from "./starter/main-side-bar/main-side-bar.component";
import { NavBarComponent } from "./starter/nav-bar/nav-bar.component";
import { FooterComponent } from "./starter/footer/footer.component";
import { FormsModule } from "@angular/forms";
import { ServicesComponent } from "./starter/services/services.component";
import { NgxPrintModule } from "ngx-print";
import { LoginComponent } from "./login/login.component";
import { Ng2TelInputModule } from "ng2-tel-input";
import { EmailComponent } from "./starter/email/email.component";
import { CourseSearchComponent } from "./starter/course/course-search/course-search.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { LeadOffersListComponent } from "./starter/LeadOffers/lead-offers-list/lead-offers-list.component";
import { AccountsComponent } from "./starter/Admin/accounts/accounts.component";
import { BranchesComponent } from "./starter/Admin/branches/branches.component";
import { ViewLeadDetailsComponent } from "./starter/view-lead-details/view-lead-details.component";
import { UsersComponent } from "./starter/Admin/users/users.component";
import { CollegeDetailsComponent } from "./starter/course/college-details/college-details.component";
import { AddCourseCollegeComponent } from "./starter/Admin/add-course-college/add-course-college.component";
import { CourseListLandingComponent } from "./starter/course/course-list-landing/course-list-landing.component";
import { CollegeListLandingComponent } from "./starter/course/college-list-landing/college-list-landing.component";
import { CampusListLandingComponent } from "./starter/course/campus-list-landing/campus-list-landing.component";
import { InvoiceServiceChargeComponent } from "./starter/Invoice/invoice-service-charge/invoice-service-charge.component";
import { AdminViewEditCourseComponent } from "./starter/course/admin-view-edit-course/admin-view-edit-course.component";
import { TeamsLandingComponent } from "./starter/Admin/teams/teams-landing/teams-landing.component";
import { RefundPolicyComponent } from "./Policies/refund-policy/refund-policy.component";
import { PrivacyPolicyComponent } from "./Policies/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./Policies/terms-and-conditions/terms-and-conditions.component";
import { WhatsappqrComponent } from "./starter/whatsappqr/whatsappqr.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { PTEAdminComponent } from "./starter/pteadmin/pteadmin.component";
import { DownloadsComponent } from "./starter/downloads/downloads.component";
import { RoleComponent } from "./starter/Admin/role/role.component";
import { ApiForRoleComponent } from "./starter/Admin/api-for-role/api-for-role.component";
import { PermissionForRoleComponent } from "./starter/Admin/permission-for-role/permission-for-role.component";
import { GenerateReportsComponent } from "./starter/Admin/generate-reports/generate-reports.component";
import { AccountInfoComponent } from "./starter/admin/account-info/account-info.component";
import { TaskSchedulerComponent } from "./starter/task-scheduler/task-scheduler.component";
import { LandingNewComponent } from "./landing-new/landing-new.component";
import { InvoiceComponent } from "./starter/Invoice/invoice/invoice.component";
import { CreateFormComponent } from './starter/Admin/create-form/create-form.component';
import { ApplicationsAppliedComponent } from './starter/course/applications-applied/applications-applied.component';
import { CommissionDashboardComponent } from './starter/course/commission-dashboard/commission-dashboard.component';
import { CalendarAppointmentComponent } from './starter/calendar-appointment/calendar-appointment.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TreeComponentComponent } from './starter/reusable-components/tree-component/tree-component.component';
@NgModule({
  declarations: [
    StarterComponent,
    ContentComponent,
    ControlSideBarComponent,
    MainSideBarComponent,
    NavBarComponent,
    FooterComponent,
    ServicesComponent,
    LoginComponent,
    EmailComponent,
    CourseSearchComponent,
    LeadOffersListComponent,
    AccountsComponent,
    BranchesComponent,
    ViewLeadDetailsComponent,
    UsersComponent,
    CollegeDetailsComponent,
    AddCourseCollegeComponent,
    CourseListLandingComponent,
    CollegeListLandingComponent,
    CampusListLandingComponent,
    InvoiceServiceChargeComponent,
    AdminViewEditCourseComponent,
    TeamsLandingComponent,
    RefundPolicyComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    WhatsappqrComponent,
    PTEAdminComponent,
    DownloadsComponent,
    RoleComponent,
    ApiForRoleComponent,
    PermissionForRoleComponent,
    GenerateReportsComponent,
    AccountInfoComponent,
    TaskSchedulerComponent,
    LandingNewComponent,
    InvoiceComponent,
    CreateFormComponent,
    ApplicationsAppliedComponent,
    CommissionDashboardComponent,
    CalendarAppointmentComponent,
    TreeComponentComponent
  ],
  imports: [
    CommonModule,
    StarterRoutingModule,
    FormsModule,
    NgxPrintModule,
    Ng2TelInputModule,
    Ng2SearchPipeModule,
    NgxQRCodeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
})
export class StarterModule {}
