<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>

  <body>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">Users</h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  id="div_ticketactivityperiod"
                >
                  <h6 class="mb-0 my-auto">Branch</h6>
                  <select
                    (change)="getUsersOnBranch($event)"
                    class="searchSelect my-auto"
                    #branch
                    name="branch"
                  >
                    <option
                      *ngFor="let branch of branches"
                      value="{{ branch.id }}"
                    >
                      {{ branch.branchName }}
                    </option>
                  </select>
                </div>
                <div class="p-auto">
                  <button
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#launchOfferModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    (click)="modalClicked()"
                  >
                    <i class="fas fa-plus mr-2"></i>User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <div class="card rounded shadow">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">User Name</th>
                      <th scope="col">User Email</th>
                      <th scope="col">Change Password</th>
                      <th scope="col">View roles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let user of users?.allAccountUsers; let i = index"
                      [ngClass]="{ selectedUser: selectedUserId == user.id }"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ user?.firstName }}</td>
                      <td>{{ user?.userEmail }}</td>
                      <td>
                        <input (change)="getPasswordValue($event)" />
                        <button
                          class="saveEditButton btn btn-success btn-sm"
                          (click)="changePassword(user.id)"
                        >
                          <i class="fas fa-save"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm"
                          (click)="
                            populateBranchesAssignedAndRoles(
                              user.accountUserToBranch,
                              user.id
                            )
                          "
                        >
                          <i class="fas fa-arrow-right"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-4">
              <div class="card shadow rounded">
                <ng-container
                  *ngIf="
                    selectedUserId != null
                  "
                >
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Branch</th>
                        <th scope="col">Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let branchRole of branchesAndRolesToUser;
                          let i = index
                        "
                      >
                        <td>{{ branchRole?.branch?.branchName }}</td>
                        <td>
                          <select
                            class="border border-success"
                            (change)="updateUser(branchRole?.id, $event)"
                          >
                            <option disabled>--- NoRoleAssigned ---</option>
                            <option
                              *ngFor="let role of roles"
                              [selected]="role?.roleName == branchRole?.roleToAccountUserBranch[0]?.role?.roleName"
                              [value]="role?.id"
                            >
                              --- {{ role?.roleName }} ---
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
                <ng-container
                  *ngIf="
                    selectedUserId == null
                  "
                  
                >
                  <h2 class="defaultMessage">Please select a user to view roles</h2>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
<!-- User post Modal started -->
<div
  class="modal fade"
  id="launchOfferModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div id="print-section" class="modal-content">
      <div class="sticky-top">
        <div class="modal-header">
          <h5>New user details</h5>
          <button
            type="button"
            class="close"
            id="closeUserAddModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #submitUser="ngForm" (submit)="createUser(submitUser)">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="Name">Full Name</label>
                <input
                  autocomplete="off"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Name"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="abc@gmail.com"
                  ngModel
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="password">Password</label>
                <input
                  type="text"
                  name="password"
                  class="form-control"
                  ngModel
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="roleId">Role</label>
                <select
                  class="form-control"
                  name="roleId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option selected>--- NoRoleAssigned ---</option>
                  <option *ngFor="let role of roles" [value]="role?.id">
                    --- {{ role?.roleName }} ---
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="mariStatus">Branch</label>
                <select
                  class="form-control"
                  name="branchId"
                  aria-hidden="true"
                  ngModel
                  required
                >
                  <option
                    *ngFor="let branch of branches"
                    [value]="branch?.id"
                    selected="selected"
                  >
                    --- {{ branch?.branchName }} ---
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary ml-auto mr-auto" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- User post Modal end -->
