import { Component, OnInit } from "@angular/core";
import { data } from "jquery";
import { AdminService } from "src/app/Services/adminService/admin.service";

@Component({
  selector: "app-applications-applied",
  templateUrl: "./applications-applied.component.html",
  styleUrls: ["./applications-applied.component.scss"],
})
export class ApplicationsAppliedComponent implements OnInit {
  tableStructure = [
    { key: "Id", value: "id" },
    { key: "Email", value: "lead.emailId" },
    { key: "Course", value: "courseToCourseIntake.course.courseName" },
    { key: "Start Date", value: "courseToCourseIntake.courseIntake.startDate" },
    { key: "Offer Fee", value: "courseToCourseIntake.course.offerFee" },
    { key: "Branch", value: "lead.branch.branchName" },
    { key: "Status", value: "appStatus"},
  ]; 

  tableStructureFeeDetails = [
    { key: "Id", value: "id" },
    { key: "Fees Paid", value: "feesPaid" },
    { key: "Payment Date", value: "paymentDate" },
    { key: "Payment Method", value: "paymentMethod" },
    { key: "Verification Status", value: "verificationStatus" },
    { key: "Reference Number", value: "feeReferenceNumber" }
  ]; 

  branchFilters = [];
  applications: any = [];
  fullApplication: any;
  canApproveCOE = localStorage.getItem('canApproveCOE');
  public branchesTreeData;

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.getAllApplicationsForCollege(localStorage.getItem('branchId'));
    this.getAllSubBranches();
  }

  getAllApplicationsForCollege(branchId) {
    this.adminService.getFilteredApplications(branchId).subscribe((data) => {
      console.log(data);
      this.applications = data;
    });
  }

  getValueFromNestedObject(obj: any, path: string): any {
    const keys = path.split('.');
    return keys.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : ''), obj);
  }
  
  getAllSubBranches(){
    this.adminService.getAllSubBranches().subscribe(
      data => {
        this.branchFilters.push({key: "Select Branch Level 0", data: data?.children, selectedValue: -1});
        this.branchesTreeData = data.children
        console.log(data.children);
      }
    )
  }

  addNewSubBranchToFilter(level, filter){
    this.branchFilters.splice(level);
    this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).selectedValue = filter;
      if(this.branchFilters.find(x => x.key == "Select Branch Level "+level)){
        this.branchFilters.find(x => x.key == "Select Branch Level "+level).data = this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).data.find(y=> y.id == filter).children;
      }
      else{
        let tempValue = this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).data.find(y=> y.id == filter).children;
        this.branchFilters.push({key: "Select Branch Level "+level, data: tempValue, selectedValue:-1});
        console.log(tempValue)
      }
    console.log( this.branchFilters.find(x => x.key == "Select Branch Level "+(level-1)).selectedValue)
  }

  applyFilter(){
    this.adminService.getFilteredApplications(this.branchFilters[this.branchFilters.length - (this.branchFilters[this.branchFilters.length - 1].selectedValue == -1? 2: 1)].selectedValue).subscribe(
      data =>{
        this.applications = data
        console.log(data)
      }
    )
  }

  getAllApplication(appli){
    this.fullApplication = appli.leadApplicationFeeDetail
  }

  approveRejectFeeSlip(status, id){
    let obj = {
      verificationStatus: status,
      feesId: id
    }
    this.adminService.editFeesPaidEntry(obj).subscribe(
      data =>{
        console.log("Changed")
      }
    )
  }

  approveRejectApplication(status, id){
    let obj = {
      applicationStatus: status,
      applicationId: id
    }
    this.adminService.verifyApplicationStatus(obj).subscribe(
      data =>{
        console.log("Changed")
      }
    )
  }

  
  handleNodeClicked(clickedNode: any): void {
    // Handle collapse behavior here
    this.branchesTreeData.forEach(node => {
      if (node !== clickedNode) {
        node.expanded = false;
      }
    });
  }

  getBranchToFilter(branchId){
    this.getAllApplicationsForCollege(branchId)
  }
}
