import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent implements OnInit {
  currentAccountIdNumber: string;
  branchId: string;
  roles: any;
  branches: any;
  constructor(
    private _router: Router,
    private leadApi: CRMLeadServiceService,
    private adminService: AdminService,
    private toastService: ToastrService
  ) {}

  accounts: any = {
    allAccounts: "",
  };
  First: any;
  searchedColleges = [];
  Colleges = ["Pu", "Qu", "Ru", "Su"];

  ngOnInit(): void {
    this.searchedColleges = this.Colleges;
    this.getAccount();
    this.getRole();
    this.getBranches()
  }

  Search() {
    if (this.First == "") {
      this.ngOnInit();
    } else {
      this.searchedColleges = this.Colleges.filter((res) => {
        return res.toLocaleLowerCase().match(this.First.toLocaleLowerCase());
      });
    }
  }

  createAccount(form: NgForm) {
    let obj = {
      firmName: form.value.firmName,
      address: form.value.address,
      usersAlloted: form.value.usersAlloted,
      branchesAlloted: form.value.branchesAlloted
    };

    this.adminService.createNewAccount(obj).subscribe(
      (data) => {
        this.toastService.success("Account created successfully");
        console.log(data);
      },
      (err) => console.log(err),
      () => console.log("Account creation successfull")
    );
  }

  getAccount() {
    this.leadApi.getAccounts().subscribe(
      (data) => {
        this.accounts = data;
        console.log(data);
      },
      (err) => console.log(err),
      () => console.log("get accounts executed successfully")
    );
  }

  createBranch(form: NgForm) {
    let obj = {
      branchName: form.value.branchName,
      address: form.value.address,
      gstDetail: {
        gstNumber: form.value.gstNo,
        firmName: form.value.firmName,
        billNumber: 1,
      },
      usersAlloted: form.value.usersAlloted,
      commissionPercentage: form.value.commission
    };

    this.adminService.createNewBranch(obj, this.currentAccountIdNumber).subscribe(
      (data) => {
        console.log(data);
        form.reset();
        this.toastService.success("Branch created successfully");
        document.getElementById("createBranchClose").click();
      },
      (err) => console.log(err),
      () => console.log("create branch executed successfully")
    );
  }

  currentAccountId(accountId: string){
    this.currentAccountIdNumber = accountId
  }

  currentBranchId(branchId: string){
    this.branchId = branchId
    this.getRole();
    this.getBranches()
  }

  getBranches(){
    this.adminService.getBranches(this.currentAccountIdNumber).subscribe(
      res =>{
        this.branches = res.branch
        console.log(res)
      }
    )
  }

  getRole(){
    this.adminService.getRole().subscribe(
      (data : any)=>{
        this.roles = data;
        console.log(data)
      }
    )
  }

  createUser(form : NgForm){
    let obj={
      branchId:  form.value.branchId,
      userEmail : form.value.email,
      firstName : form.value.name,
      password : form.value.password,
      lastName : "" ,
      roleToAccountUser: [
        {
          roleId : form.value.roleId
        }
      ]
    }
    this.leadApi.createUser(obj, this.currentAccountIdNumber, this.branchId).subscribe(
      data => {
        console.log(data)
        form.reset();
        this.toastService.success('User created');;
        document.getElementById("closeUserAddModal")?.click();
      },
      err => console.log(err),
      () => console.log("add user executed successfully")
    )
  }
}
