<div>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow">
      <div class="card-header border-0">
        <div class="row">
          <div class="col">
            <h5 class="d-inline-block align-middle my-0 mr-3">Applications</h5>
          </div>
          <div class="col-auto">
            <div class="form-row">
              <div class="p-auto">
                <div
                  class="btn-group btn-group-sm btn-group-sm"
                  role="group"
                ></div>
                <button
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  data-toggle="modal"
                  data-target="#filtersModal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid px-4 rounded">
  <div class="">
    <div class="row">
        <div class="col-2">
          <div class="card shadow">
            <div class="card-header">
              Branch Filter
            </div>
            <div class="card-body">
              <div class="container">
                <div id="tree">
                  <ul style="overflow-x: auto;">
                    <app-tree-component
                      *ngFor="let node of branchesTreeData"
                      [node]="node"
                      (eyeBranchClicked)="getBranchToFilter($event)"
                    ></app-tree-component>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-10">
        <div class="card shadow" style="overflow-x: auto; max-width: 100%;">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" *ngFor="let tableStruct of tableStructure">
                {{ tableStruct.key }}
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let appli of applications">
              <td scope="row" *ngFor="let tableStruct of tableStructure">
                {{ getValueFromNestedObject(appli, tableStruct.value) }}
              </td>
              <td class="actionsTd">
                <ul class="MiniHorzNav">
                  <li
                    data-toggle="modal"
                    data-target="#feesPaidSlipModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    (click)="getAllApplication(appli)"
                  >
                    <button
                      class="btn-sm btn btn-primary smallButton"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="View Fee Slip"
                    >
                      <i class="fas fa-eye fa-xl"></i>
                    </button>
                  </li>
    
                  <li *ngIf="canApproveCOE">
                    <button
                      class="btn-sm btn btn-success smallButton"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Accept"
                      (click)="approveRejectApplication('Approved', appli.id)"
                    >
                      <i class="fas fa-check fa-xl"></i>
                    </button>
                  </li>
                  <li *ngIf="canApproveCOE">
                    <button
                      class="btn-sm btn-danger btn smallButton"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Reject"
                      (click)="approveRejectApplication('Rejected', appli.id)"
                    >
                      <i class="fas fa-times fa-xl"></i>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
    
        <div class="row pageRow">
          <div class="col-5"></div>
          <div class="col-auto mx-auto">
            <i class="fas fa-chevron-circle-left mr-2 pageIcon"></i>
          </div>
          <div class="col-auto mx-auto">
            <i class="fas fa-chevron-circle-right mr-2 pageIcon"></i>
          </div>
          <div class="col-5"></div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="filtersModal"
  role="dialog"
  aria-labelledby="launchOfferModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div class="modal-content">
      <div>
        <div class="modal-header">
          <h5>Filters</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #collegeForm="ngForm" (submit)="applyFilter()">
            <div class="form-row">
              <div class="form-group col-md-4 mx-auto">
                <ng-container
                  *ngFor="let filter of branchFilters; let i = index"
                >
                  <ng-container *ngIf="filter.data.length > 0">
                    <label for="Name">{{ filter.key }}</label>
                    <select
                      autocomplete="off"
                      type="text"
                      name="country"
                      class="form-control"
                      ngModel
                      #branch
                      ngModel
                      required
                      (change)="addNewSubBranchToFilter(i + 1, branch.value)"
                    >
                      <option value="Select" default disabled>Select</option>
                      <option
                        *ngFor="let branchName of filter.data"
                        value="{{ branchName.id }}"
                        [selected]="branchName.id == filter.selectedValue"
                      >
                        {{ branchName.branchName }}
                      </option>
                    </select>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="form-row">
              <button class="btn btn-primary ml-auto mr-auto" type="submit">
                Create
              </button>
            </div>
          </form>
          <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="feesPaidSlipModal"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="invoiceModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Fee Slip</h5>
        <button
          type="button"
          id="closeButtonInvoice"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-hover feeTable">
          <thead>
            <tr>
              <th
                scope="col"
                *ngFor="let tableStruct of tableStructureFeeDetails"
              >
                {{ tableStruct.key }}
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let apply of fullApplication">
              <td
                scope="row"
                *ngFor="let tableStruct of tableStructureFeeDetails"
              >
                {{ getValueFromNestedObject(apply, tableStruct.value) }}
              </td>
              <td class="actionsTd">
                <ul class="MiniHorzNav">
                  <li>
                    <button
                      class="btn-sm btn btn-primary smallButton"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="View Fee Slip"
                    >
                      <i class="fas fa-eye fa-xl"></i>
                    </button>
                  </li>
                  <ng-container
                    *ngIf="
                      apply.verificationStatus != 'Approved' &&
                      apply.verificationStatus != 'Rejected'
                    "
                  >
                    <li>
                      <button
                        (click)="approveRejectFeeSlip('Approved', apply.id)"
                        class="btn-sm btn btn-success smallButton"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Accept"
                      >
                        <i class="fas fa-check fa-xl"></i>
                      </button>
                    </li>
                    <li>
                      <button
                        (click)="approveRejectFeeSlip('Rejected', apply.id)"
                        class="btn-sm btn-danger btn smallButton"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Reject"
                      >
                        <i class="fas fa-times fa-xl"></i>
                      </button>
                    </li>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      apply.verificationStatus != 'Approved' &&
                      apply.verificationStatus != 'Rejected'
                    "
                  >
                    <li>
                      <button
                        (click)="approveRejectFeeSlip('Approved', apply.id)"
                        class="btn-sm btn btn-success smallButton"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Accept"
                      >
                        <i class="fas fa-check fa-xl"></i>
                      </button>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="apply.verificationStatus == 'Approved'">
                    <li>
                      <button
                        class="btn-sm btn-success btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Reject"
                      >
                        Approved
                      </button>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="apply.verificationStatus == 'Rejected'">
                    <li>
                      <button
                        class="btn-sm btn-danger btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Reject"
                      >
                        Rejected
                      </button>
                    </li>
                  </ng-container>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
