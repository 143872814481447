import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/Services/adminService/admin.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  accountInfo : any;
  constructor(private _adminService: AdminService) { }

  ngOnInit(): void {
    this.getAccountInfo()
  }

  getAccountInfo(){
    this._adminService.getAccountInfo().subscribe(
      data => {
       this.accountInfo = data
       console.log(this.accountInfo)
      }
    )
  }
}
