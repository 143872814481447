import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import { SocketService } from "src/app/Services/socketService/socket.service";

@Component({
  selector: "app-task-scheduler",
  templateUrl: "./task-scheduler.component.html",
  styleUrls: ["./task-scheduler.component.scss"],
})
export class TaskSchedulerComponent implements OnInit {
  tasks: any;

  currentTask: any;
  users: any;
  assignedTasks: any;
  workInTasks: any;
  completedTasks: any;
  acceptedTasks: any;

  constructor(
    private leadApi: CRMLeadServiceService,
    private socketService: SocketService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUsers(localStorage.getItem("branchId"));
    this.getTasks();
  }

  onDragStart(item) {
    this.currentTask = item;
    console.log(item);
  }

  onDrop(event: any, status) {
    if(this.currentTask?.statusId != status){
      if((status == '4' && (localStorage.getItem('userId') == this.currentTask.reporter.id || localStorage.getItem('userId') == this.currentTask.createdBy.id) || status != '4')){
        this.tasks.find((x) => x.id == this.currentTask?.id).statusId = status; 
        let obj = {
          id: this.currentTask?.id,
          statusId : status
        }
        this.leadApi.changeTaskStatus(obj,this.currentTask?.id).subscribe(
          data =>{
            this.toastService.success('Task status changes');
            this.assignedTasks = this.tasks.filter((m) => m.statusId == "1");
            this.workInTasks = this.tasks.filter((m) => m.statusId == "2");
            this.completedTasks = this.tasks.filter((m) => m.statusId == "3");
            this.acceptedTasks = this.tasks.filter((m) => m.statusId == "4");
          },
          err =>{
            this.toastService.error('Some error occured');
          }
        )
      }
      else{
          this.toastService.error("Only reporter or task creator can accept")
      }
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
    console.log("onDragOver");
  }

  getUsers(branchId) {
    this.leadApi.getUser(branchId).subscribe((data) => {
      this.users = data;
      console.log(data);
    });
  }

  createTask(form: NgForm) {
    this.leadApi.createTask(form.value).subscribe(
      (data) => {
        console.log(data);
        form.reset();
        document.getElementById("closeTaskAddModal").click();
        this.getTasks();
        this.toastService.success("Task created");
      },
      (err) => {
        this.toastService.warning("Some error occured");
      }
    );
  }

  getTasks() {
    this.leadApi.getTask().subscribe((data) => {
      this.tasks = data;
      this.assignedTasks = this.tasks.filter((m) => m.statusId == "1");
      this.workInTasks = this.tasks.filter((m) => m.statusId == "2");
      this.completedTasks = this.tasks.filter((m) => m.statusId == "3");
      this.acceptedTasks = this.tasks.filter((m) => m.statusId == "4");
    });
  }

  disappearTask(event : MouseEvent ,taskId){
    event.stopPropagation();
    let obj = {
      id: taskId,
      disappear : true
    }
    this.leadApi.changeTaskStatus(obj,this.currentTask?.id).subscribe(
      data =>{
        this.toastService.success("Task disappeard from board")
        this.getTasks()
      }),
      err =>{
        this.toastService.error("Some error occured")

      }
  }

  dropHandler(ev: Event) {
    console.log("File(s) dropped");
  
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    console.log(ev.target)
  }
  
  onDrope(event) {
    const data = event.dataTransfer.getData("text/plain");
    event.target.textContent = data;
    event.preventDefault();
    console.log("ABC")
  }
  
}


