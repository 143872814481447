import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-invoice-service-charge',
  templateUrl: './invoice-service-charge.component.html',
  styleUrls: ['./invoice-service-charge.component.scss']
})
export class InvoiceServiceChargeComponent implements OnInit, OnChanges {

  @Input() invoiceDetails:any = null; 
  @Input() callMethod ;
  grossAmount =10000;
  discount = 0;
  netAmount= 10000;
  pendingAmount = 10000;
  installment = 10000;
  receivedOn = false;
  receivedDueDate : any;
  pendingInstallments = [{
    addedOn : new Date(),
    addedBy : "TestUser",
    amount : 1000,
    dueOn: new Date(),
  }]
  receivedInstallments = [{
    receivedBy : "TestUser",
    amount : 1000,
    receivedOn: new Date(),
    addedOn: new Date()
  }]
  pendingInstallmentsAmount: number = 0;
  receivedInstallmentsAmount: number = 0;
  viewInvoice: boolean = false;
  

  constructor(private leadApi : CRMLeadServiceService, private route : ActivatedRoute) {
  
  }


  ngOnInit(): void {
    this.pendingInstallments.forEach(element => {
      this.pendingInstallmentsAmount += element.amount
    });

    this.receivedInstallments.forEach(element => {
      this.receivedInstallmentsAmount += element.amount
    });

    this.pendingAmount = this.pendingAmount - (this.pendingInstallmentsAmount + this.receivedInstallmentsAmount)

    this.getLeadChargesDiscount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getLeadChargesDiscount();
  }
  addDiscount(event){
    this.discount = event.target.value;
    this.netAmount = this.grossAmount - (event.target.value/ 100) * this.grossAmount;
  }

  // addInstallment(form){
  //   if(form.value.received == 'due'){
  //     this.pendingInstallments.push(
  //       {
  //         addedOn: new Date(),
  //         addedBy: 'TestUser',
  //         amount: form.value.installment,
  //         dueOn: form.value.installmentDate
  //       }
  //     )
  //     this.pendingInstallmentsAmount = this.pendingInstallmentsAmount + form.value.installment
  //     this.pendingAmount = this.pendingAmount - form.value.installment
  //   }
  //   else{
  //     this.receivedInstallments.push(
  //       {
  //         receivedBy: 'TestUser',
  //         receivedOn: form.value.installmentDate,
  //         amount: form.value.installment,
  //         addedOn: new Date()
  //       }
  //     )
  //     this.receivedInstallmentsAmount = this.receivedInstallmentsAmount + form.value.installment
  //     this.pendingAmount = this.pendingAmount - form.value.installment
  //   }
  // }

  receivePendingInstallment(installment, i){
    this.pendingInstallments.pop()
    this.receivedInstallments.push(
      {
        receivedBy: 'TestUser',
        receivedOn: new Date(),
        amount: installment.amount,
        addedOn: installment.addedOn
      }
    )
    let obj = {
      receivedDate: new Date(),
      id: 8
    }

    this.leadApi.postInstallment(obj).subscribe(
      data => console.log(data)
    )
    this.receivedInstallmentsAmount = this.receivedInstallmentsAmount + installment.amount
    this.pendingInstallmentsAmount = this.pendingInstallmentsAmount - installment.amount
  }

  postDiscount(form){
    let obj = { 
      leadToServiceId : this.invoiceDetails?.leadToServiceId,
      charges: this.netAmount,
      discount: this.discount
    }
    this.leadApi.postDiscountNetPayable(obj).subscribe(
      data => console.log(data)
    )
  }

  // getLeadChargesDiscount(){
  //   this.leadApi.getLeadToServiceCharges(this.invoiceDetails.leadToServiceId).subscribe(
  //     data=> console.log(data)
  //   )
  // }

  addInstallment(form){
    if(form.value.received == 'due'){
      this.pendingInstallments.push(
        {
          addedOn: new Date(),
          addedBy: 'TestUser',
          amount: form.value.installment,
          dueOn: form.value.installmentDate
        }
      )

      let obj = {
        leadToServiceChargesId: '204',
        installment: form.value.installment,
        dueDate: form.value.installmentDate
      }

      this.leadApi.postInstallment(obj).subscribe(
        data => console.log(data)
      )
      this.pendingInstallmentsAmount = this.pendingInstallmentsAmount + form.value.installment
      this.pendingAmount = this.pendingAmount - form.value.installment
    }
    else{
      this.receivedInstallments.push(
        {
          receivedBy: 'TestUser',
          receivedOn: form.value.installmentDate,
          amount: form.value.installment,
          addedOn: new Date()
        }
      )
      let obj = {
        leadToServiceChargesId: '204',
        installment: form.value.installment,
        receivedDate: form.value.installmentDate,
        dueDate: new Date()
      }

      this.leadApi.postInstallment(obj).subscribe(
        data => console.log(data)
      )
      this.receivedInstallmentsAmount = this.receivedInstallmentsAmount + form.value.installment
      this.pendingAmount = this.pendingAmount - form.value.installment
    }
  }

  

  getLeadChargesDiscount(){
    this.leadApi.getLeadToServiceCharges(this.invoiceDetails.leadToServiceId).subscribe(
      data=> console.log(data)
    )
  }

  handleChange(obj){
    this.invoiceDetails = obj;
    this.getLeadChargesDiscount();
  }

  viewInvoices(){
    console.log(this.viewInvoice)
    this.viewInvoice = !this.viewInvoice;
  }
}
