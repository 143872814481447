import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Subject } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

 socketApi: any = environment.socketApi;
 socket : any;
  // Observable string sources
 private missionAnnouncedSource = new Subject<string>();

 // Observable string streams
 missionAnnounced$ = this.missionAnnouncedSource.asObservable();

  constructor() {
    this.socket = io(this.socketApi);
    this.socket.on('connected', (res) =>{
      console.log(this.socket.id)
    })
    this.socket.emit('setup', {
      accountUserId:localStorage.getItem('userId')
    })
    this.socket.on('msgNotification',(res)=>{
     console.log('Hello world!', 'Toastr fun!');
   })
   }


  // Service message commands
  announceMission(mission: any) {
    this.missionAnnouncedSource.next(mission);
  }

}
