<!DOCTYPE html>
<html lang="en">
    <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>CourseList</title>
  </head>

<body>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="sticky-top">
      <div class="card shadow" style="border-radius: 10px">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-auto mx-auto">
              <select name="countrySelect" class="form-control form-control-sm">
                <option value="Select" selected>Country</option>
                <option value="Canada">Canada</option>
                <option value="Australia">Australia</option>
                <option value="NewZealand">New Zealand</option>
                <option value="UK">United Kingdom</option>
              </select>
            </div>
            <div class="col-auto mx-auto">
              <select
                name="State Province or Territory"
                class="form-control form-control-sm"
              >
                <option value="default" selected disabled>
                  State Province or Territory
                </option>
                <option value="Alberta, Edmonton">Alberta, Edmonton</option>
              </select>
            </div>
            <div class="col-auto mx-auto">
              <select
                name="State Province or Territory"
                class="form-control form-control-sm"
              >
                <option value="default" selected>
                  Field of Study / Sub-Field
                </option>
                <option value="Alberta, Edmonton">Alberta, Edmonton</option>
              </select>
            </div>
            <div class="col-auto mx-auto">
              <select
                name="State Province or Territory"
                class="form-control form-control-sm"
              >
                <option value="default" selected>Level of Education</option>
                <option value="Alberta, Edmonton">Alberta, Edmonton</option>
              </select>
            </div>
            <div class="col-auto mx-auto">
              <select
                name="Field of study"
                class="form-control form-control-sm"
              >
                <option value="default">Intakes - 2023</option>
                <option value="Arts">January</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div class="row">
      <div class="card shadow mx-auto clgCard" *ngFor="let col of Colleges">
        <div class="card-header">
          <div class="row">
            <div class="col-auto mx-auto">
              <img src="../../../../../assets/img/pp.jpg" class="clgImage" />
            </div>
          </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <h5 class="colgName">{{ col?.college?.name }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <p>{{ col?.college?.collegeState?.stateName }}{{ col?.college?.collegeCountry?.countryName }}</p>
            </div>
          </div>
          <table class="table no-border">
            <tbody>
              <tr>
                <td>Offer Fees:</td>
                <td class="forAlignRight">{{ col.offerFee | currency: "USD" }}</td>
              </tr>
              <tr>
                <td>Level Of Education:</td>
                <td class="forAlignRight">{{ col.levelOfEducation.levelOfEducation }}</td>
              </tr>
              <tr>
                <td>Course Name: </td>
                <td class="forAlignRight">{{ col.courseName }}</td>
              </tr>
              <tr>
                <td>Duration:</td>
                <td class="forAlignRight">{{ col.courseDuration }}</td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <p class="mx-auto">Intakes - 2023</p> -->
            <!-- <div class="mx-auto">
              <p class="intakesP">
                <span [ngClass]="{ intakeB: college.Intakes.January }"
                  >JANUARY</span
                >
                |
                <span [ngClass]="{ intakeB: college.Intakes.May }">MAY</span> |
                <span [ngClass]="{ intakeB: college.Intakes.September }"
                  >SEPTEMBER</span
                > 
              </p>
            </div> -->
          <!-- </div>
          <div class="row">
            <div class="col-auto mx-auto">
              <button class="btn btn-primary cardBtn">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="card shadow mx-auto clgCard" *ngFor="let col of Colleges">
      <div class="card-header">
        <div class="row">
          <div class="col-2 mx-auto my-auto">
            <img src="../../../../../assets/img/pp.jpg" class="clgImage my-auto" />
          </div>
          <div class="col-8 mx-auto">
            <table class="table no-border">
              <tbody>
                <tr>
                  <td>Offer Fees:</td>
                  <td class="forAlignRight">{{ col.offerFee | currency: "USD" }}</td>
                </tr>
                <tr>
                  <td>Level Of Education:</td>
                  <td class="forAlignRight">{{ col.levelOfEducation.levelOfEducation }}</td>
                </tr>
                <tr>
                  <td>Course Name: </td>
                  <td class="forAlignRight">{{ col.courseName }}</td>
                </tr>
                <tr>
                  <td>Duration:</td>
                  <td class="forAlignRight">{{ col.courseDuration }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-2 mx-auto">
            <button class="btn btn-primary applyButton">Apply May</button><br>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="card shadow mx-auto" *ngFor="let col of Colleges">
      <div class="card-header">
        <div class="row">
          <div class="col-2 mx-auto my-auto">
            <img src="../../../../../assets/img/pp.jpg" class="clgImage my-auto" />
            
              <button class="btn btn-primary applyButton">Apply May</button><br>
            
          </div>
          <div class="col-8 mx-auto">
            <table class="table no-border">
              <tbody>
                <tr>
                  <td>Offer Fees: <br>{{ col.offerFee | currency: "USD" }}</td>
                </tr>
                <tr>
                  <td>Level Of Education: <br>{{ col.levelOfEducation.levelOfEducation }}</td>
                </tr>
                <tr>
                  <td>Course Name: <br>{{ col.courseName }}</td>
                </tr>
                <tr>
                  <td>Duration: <br>{{ col.courseDuration }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="card mx-auto my-auto courseCard" *ngFor="let col of Colleges">
      <div class="card-header colgNameHead">
        <!-- <h6 class="colgName">{{ col?.college?.name }}</h6> -->
        <h6 class="colgName"> <b>Northquest college - Edmonton</b> </h6>
        <!-- <p class="colgNameHead">{{ col?.college?.collegeState?.stateName }}, {{ col?.college?.collegeCountry?.countryName }}</p> -->
        <p class="colgNameHead">Alberta</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 cardLeft">
            <div class="row">
              <div class="col-auto mx-auto">
                <img src="../../../../../assets/img/College.svg" class="clgImage mx-auto" /><br>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <select
                    class="form-control form-control-sm applySelect mx-auto"
                    name="intakeChoosen"
                    #intakeChoosen
                    (change)="applyForIntake(intakeChoosen.value)"
                  >
                    <option selected>Apply</option>
                    <option
                      value="{{ optio.id }}"
                      *ngFor="let optio of col.courseToCourseIntake"
                    >
                      {{ optio.courseIntake.intakeMonth }}-{{
                        optio.courseIntake.intakeYear
                      }}
                    </option>
                  </select>
              </div>
            </div>
          </div>
          <div class="col-8">
          <table class="table">
            <tbody>
              <tr>
                <b>Course name</b> 
              </tr>
              <tr class="textDec">
                {{ col.courseName }}
                <hr width="100%" color="black">
              </tr>
              <tr>
                <b>Level of education</b> 
              </tr>
              <tr class="textDec" >
                {{ col?.levelOfEducation?.levelOfEducation }}
                <hr width="100%" color="black">
              </tr>
              <tr>
               <b>Duration</b> 
              </tr>
              <tr class="textDec">
                {{ col.courseDuration }}
                <hr width="100%" color="black">
              </tr>
              <tr>
                <b>Offer fee</b> 
              </tr>
              <tr class="textDec">
                {{ col?.offerFee | currency: "USD" }}
                <hr width="100%" color="black">
              </tr>
              <tr>
                <b>Intakes - 2023</b>
              </tr>
              <tr>
                <span class="notAvailable">
                  JANUARY
                </span>
                 | 
                <span class="available"> MAY </span>
                |
                <span class="notAvailable"> SEPT </span>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- <div [hidden]="viewLoader2" class="spinner-border positionSpinner"></div> -->
</div>
</body>
</html>
