<aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
  <div class="p-3">
    <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
    <div class="media" *ngFor="let notification of notifications" (click)="navigateToComments(notification.lead.id)">
      <!-- <img src="assets/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3"> -->
      <div class="media-body" *ngIf="notification?.lead">
        <h3 class="dropdown-item-title">
          {{notification?.lead?.id}}
          <span class="float-right text-sm text-warning">{{notification?.lead?.emailId}}</span>
        </h3>
        <p class="text-sm">New Notification in chat</p>
        <!-- <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p> -->
      </div>
    </div>
  </div>
</aside>
