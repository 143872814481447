import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  apiLink = environment.production;
  constructor(private _http:HttpClient) { }

  //#region role
  createRole(obj: any){
    return this._http.post(this.apiLink+'role', obj).pipe(catchError(this.errorHandler));
  }

  getRole(){
    return this._http.get(this.apiLink+'role').pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region ApisFetch
  getApis(){
    return this._http.get(this.apiLink+'apis').pipe(catchError(this.errorHandler));
  }

  postApis(obj: any){
    return this._http.post(this.apiLink+'apis',obj).pipe(catchError(this.errorHandler));
  }

  getApisWithRole(id: number){
    return this._http.get(this.apiLink+'role/'+id+'/apisAttachableToRole').pipe(catchError(this.errorHandler));
  }

  addApiToRole(obj:any){
    return this._http.post(this.apiLink+'role/attachApisPermissionToRole',obj).pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region Services
  createServiceWithMilestones(obj : any){
    return this._http.post(this.apiLink+'service',obj).pipe(catchError(this.errorHandler));
  }

  getServicesWithMilestones(){
    return this._http.get(this.apiLink+'service/userSpecific').pipe(catchError(this.errorHandler));
  }

  getAllServicesWithMilestones(){
    return this._http.get(this.apiLink+'service').pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region permission
  createPermission(obj:any){
    return this._http.post(this.apiLink+'permission', obj).pipe(catchError(this.errorHandler));
  }

  getPermission(){
    return this._http.get(this.apiLink+'permission').pipe(catchError(this.errorHandler));
  }

  getPermissionsWithRole(roleId:number){
    return this._http.get(this.apiLink+'role/'+roleId+'/permissionAttachableToRole').pipe(catchError(this.errorHandler));
  }

  addPermissionToRole(obj :any){
    return this._http.post(this.apiLink+'role/attachPermissionToRole',obj).pipe(catchError(this.errorHandler));
  }

  deletePermissionToRole(obj :any, roleId : number){
    return this._http.post(this.apiLink+`role/${roleId}/deletePermission`,obj).pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region leads
  commonUpdateLead(leadId:string, obj:any){
    return this._http.patch(this.apiLink+`lead/${leadId}`,obj).pipe(catchError(this.errorHandler));
  }
  //#endregion
  
  //#region Accounts Info
  getAccountInfo(){
    return this._http.get(this.apiLink + 'account/info').pipe(catchError(this.errorHandler))
  }
  //#endregion

  //#region users
  updateUserRole(obj : any){
      return this._http.patch(this.apiLink+`account/${localStorage.getItem('accountId')}/branch/${localStorage.getItem('branchId')}/accountUser/role`, obj).pipe(catchError(this.errorHandler));
  }

  updateUserCommon(obj : any){
    return this._http.patch(this.apiLink+`account/${localStorage.getItem('accountId')}/branch/${localStorage.getItem('branchId')}/accountUser`, obj).pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region Super admin accounts all
  createNewAccount(obj: any){
    return this._http.post(this.apiLink+"account", obj).pipe(catchError(this.errorHandler));
  }

  createNewBranch(obj: any, accountId: any){
    return this._http.post(this.apiLink+`account/${accountId}/branch`,obj);
  }
  //#endregion

  //#region getBranches for superadmin only
  getBranches(accountId : string){
    return this._http.get<any>(this.apiLink+`account/admin/${accountId}/branch`)  
  }
  //#endregion
  
  //#region Applications all for college
  getAllApplications(){
    return this._http.get(this.apiLink + "lead-application/applications/all").pipe(catchError(this.errorHandler))
  }

  getAllSubBranches():any{
    return this._http.get(this.apiLink + "account/subBranches").pipe(catchError(this.errorHandler))
  }

  getFilteredApplications(branchId){
    return this._http.get(this.apiLink + `lead-application/applications/${branchId}`).pipe(catchError(this.errorHandler))
  }

  postOfferLetterGenerated(obj){
    return this._http.post(this.apiLink + `lead-application/offerletter`,obj).pipe(catchError(this.errorHandler))
  }

  editFeesPaidEntry(obj){
    return this._http.patch(this.apiLink + `Lead-application/feeDetail/status`,obj).pipe(catchError(this.errorHandler))
  }

  verifyApplicationStatus(obj){
    return this._http.patch(this.apiLink + `Lead-application/application/status`,obj).pipe(catchError(this.errorHandler));
  }
  //#endregion
  //#region error handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server Error");
  } 
  //#endregion
}
