import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { SocketService } from 'src/app/Services/socketService/socket.service';

@Component({
  selector: 'app-starter-control-side-bar',
  templateUrl: './control-side-bar.component.html',
  styleUrls: ['./control-side-bar.component.scss']
})
export class ControlSideBarComponent implements OnInit {
  notifications: any;

  constructor(private leadApi:CRMLeadServiceService, private router : Router, private socketSerivce : SocketService) { 
    socketSerivce.missionAnnounced$.subscribe(
      mission => {
        this.notifications = mission;
    });
  }

  ngOnInit() {
    //this.getNotification();
  }

  getNotification(){
    this.leadApi.getNotification().subscribe(
      data => {
        this.notifications = data
        console.log(data)
      },
      err => console.log(err),
      () => console.log("Notification")
    )
  }

  navigateToComments(leadId : string){
    console.log(leadId);
    this.leadApi.clearNotification(leadId).subscribe(
      data =>{
        this.getNotification();
        this.router.navigate([`/user/leadOfferService/lead/${leadId}/comments/${leadId}`]);
      },
      err => {
        alert("Some error occured please try again after some time");
      }
    )
  }
}
