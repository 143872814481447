<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="sticky-top">
      <div class="card shadow" style="border-radius: 10px">
        <div class="card-header border-0">
          <div class="row">
            <div class="col">
              <h5 class="d-inline-block align-middle my-0 mr-3">
                Branch Details
              </h5>
            </div>

            <div class="col-auto">
              <div class="form-row">
                <div class="p-auto">
                  <button
                    *ngIf="canCreateBranch == 'true'"
                    class="btn btn-sm btn-outline-primary btnFilter"
                    type="button"
                    data-toggle="modal"
                    data-target="#launchBranchModal"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    <i class="fas fa-plus mr-2"></i>Branch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid px-4 pt-4 rounded">
    <div class="card shadow" style="border-radius: 10px">
      <div class="card-body border-0">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">BranchName</th>
              <!-- <th scope="col">FirmName</th> -->
              <!-- <th scope="col">GST No.</th> -->
              <th scope="col">Address</th>
              <th scope="col">Users Alloted</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let branch of searchedColleges">
              <th scope="row">{{ branch?.id }}</th>
              <td>{{ branch?.branchName }}</td>
              <!-- <td>{{ branch?.gstDetail?.firmName }}</td> -->
              <!-- <td>{{ branch?.gstDetail?.gstNumber }}</td> -->
              <td>{{ branch?.address }}</td>
              <td>{{ branch?.usersAlloted }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="launchBranchModal"
    style="padding-right: 5px"
    role="dialog"
    aria-labelledby="launchOfferModalTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-side modal-top-right"
      role="navigation"
    >
      <div id="print-section" class="modal-content">
        <div class="sticky-top">
          <div class="modal-header">
            <h5>New Branch Details</h5>
            <button
              id="createBranchClose"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form #submitBranch="ngForm" (submit)="createBranch(submitBranch)">
              <div class="card shadow">
                <div class="card-header">Branch Details</div>
                <div class="card-body">
                  <div class="form-row row">
                    <div class="form-group col-md-6">
                      <label for="branchName">Branch Name</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="branchName"
                        class="form-control"
                        ngModel
                        required
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="address">Firm Name</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="firmName"
                        class="form-control"
                        ngModel
                        required
                      />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="form-group col-md-6">
                      <label for="branchName">GST No.</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="gstNo"
                        class="form-control"
                        ngModel
                        required
                      />
                    </div>

                    <div class="form-group col-md-6">
                      <label for="address">Address</label>
                      <input
                        autocomplete="off"
                        type="text"
                        name="address"
                        class="form-control"
                        ngModel
                        required
                      />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="form-group col-md-6">
                      <label for="usersAlloted">Allot Users</label>
                      <input
                        autocomplete="off"
                        type="number"
                        name="usersAlloted"
                        class="form-control"
                        ngModel
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="card shadow">
                <div class="card-header">Commission Details</div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="Name">Comission Type</label>
                      <select name="type" ngModel class="form-control">
                        <option value="default" selected disabled>
                          Select Type
                        </option>
                        <option value="1">Percentage</option>
                        <option value="2">Lum-sum</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="password">Comission percentage/ Amount</label>
                      <div class="input-group mb-3">
                        <input type="number" class="form-control" aria-label="Amount (to the nearest dollar)" name="percentage" ngModel>
                        <div class="input-group-append">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <button class="btn btn-primary ml-auto mr-auto" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</html>
